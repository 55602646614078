import React from "react";
import { FORMER_MAX_WIDTH } from "utils/constants/ui";
import { EntityPropTypes } from "utils/propTypes";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import FormerFlexCheckoutAction from "components/FormerFlexCheckoutAction";
import classnames from "classnames";
import ScheduleField from "components/ScheduleField";
import BillingPeriodField from "components/BillingPeriodField";
import AmountField from "components/AmountField";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { SCHEDULE_TYPE } from "utils/constants/price";
import {
  deconstructFormattedPrice,
  getIntervalLabel,
  preparePriceFromValues,
  validatePrice
} from "utils/price";
import {
  formattedPriceToUnitAmount,
  formatUnitAmount,
  getConfigForCurrency
} from "components/FormerEditor/common/currency";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import { usePageFactoryContext } from "utils/context";
import { usePageElementNameRoot } from "utils/hooks/manifest";

const PAYMENT_PATH = {
  FORMATTED_PRICE: "formattedPrice",
  CURRENCY: "currency",
  RECURRING: "recurring",
  RECURRING_INTERVAL: "recurring.interval",
  RECURRING_INTERVAL_COUNT: "recurring.interval_count"
};

const FormerPayment = ({
  afterChange,
  name,
  entity,
  data: { title, subtitle, actions },
  entityRef,
  ui
}) => {
  const { values } = useFormikContext();

  const { stateKey } = usePageFactoryContext();
  const nameRoot = usePageElementNameRoot(name);

  const amountPath = getFieldPath(stateKey, `content.${name}.data.amount`);
  const amountValues = get(values, amountPath);
  const amountErrors = validatePrice(amountValues);

  const focus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    }
  ];

  const formattedPricePath = getFieldPath(
    amountPath,
    PAYMENT_PATH.FORMATTED_PRICE
  );
  const currencyPath = getFieldPath(amountPath, PAYMENT_PATH.CURRENCY);

  const recurringPath = getFieldPath(amountPath, PAYMENT_PATH.RECURRING);
  const recurringIntervalPath = getFieldPath(
    amountPath,
    PAYMENT_PATH.RECURRING_INTERVAL
  );
  const recurringIntervalCountPath = getFieldPath(
    amountPath,
    PAYMENT_PATH.RECURRING_INTERVAL_COUNT
  );

  const formattedPrice = get(values, formattedPricePath);
  const currency = get(values, currencyPath);
  const recurring = get(values, recurringPath);
  const recurringInterval = get(values, recurringIntervalPath);
  const recurringIntervalCount = get(values, recurringIntervalCountPath);

  const scheduleValue = isEmpty(recurringInterval)
    ? SCHEDULE_TYPE.ONE_TIME
    : SCHEDULE_TYPE.RECURRING;

  let fields = [
    <AmountField
      hideLabel
      error={get(amountErrors, PAYMENT_PATH.FORMATTED_PRICE)}
      key={currency}
      value={formattedPrice}
      name={formattedPricePath}
      currency={currency}
      currencyPath={currencyPath}
      recurringIntervalPath={recurringIntervalPath}
      recurringInterval={recurringInterval}
    />
  ];

  fields.push(
    <ScheduleField
      error={get(amountErrors, PAYMENT_PATH.RECURRING)}
      hideLabel
      name={recurringPath}
      intervalName={recurringPath}
      value={scheduleValue}
    />
  );

  if (!isEmpty(recurringInterval)) {
    fields.push(
      <BillingPeriodField
        label="Every"
        intervalName={recurringIntervalPath}
        intervaError={get(amountErrors, PAYMENT_PATH.RECURRING_INTERVAL)}
        intervalCountName={recurringIntervalCountPath}
        intervalCountError={get(
          amountErrors,
          PAYMENT_PATH.RECURRING_INTERVAL_COUNT
        )}
        value={recurringInterval}
      />
    );
  }

  /**
   * Get currency config
   */
  const currencyConfig = getConfigForCurrency(currency);
  const symbolValue = currencyConfig.symbol;

  /**
   * Prepare formattedValue
   * - Need fallback to show when theres nothing in the input
   * - Presentation value to only be the number
   * - Use the currency config symbol for the symbol value
   */
  const { formatted: formattedFallback } = deconstructFormattedPrice(
    formatUnitAmount({
      amount: 0,
      currency
    })
  );
  const { formatted } = deconstructFormattedPrice(formattedPrice);
  const formattedValue = formatted || formattedFallback;

  /**
   * Prepare presentation value
   */
  const unitAmount = formattedPriceToUnitAmount({
    formattedPrice: formattedValue,
    currency
  });
  const { formatted: amountToPresent } = deconstructFormattedPrice(
    formatUnitAmount({
      amount: unitAmount,
      currency
    })
  );

  const intervalLabel = getIntervalLabel(
    recurringInterval,
    recurringIntervalCount
  );

  const preparedPrice = preparePriceFromValues({
    recurring,
    formattedPrice,
    currency
  });

  const checkoutProps = actions[0]
    ? {
        action: actions[0],
        prices: [preparedPrice],
        disabled: !isEmpty(amountErrors)
      }
    : null;

  return (
    <ReflectFocus name={entity.schema.title} focus={focus} withHeader>
      {({ onClick, hover, classes } = {}) => {
        return (
          <div
            id={nameRoot}
            ref={entityRef}
            className={classnames(`w-100 ph3 ph0-ns`, {
              "bg-white": !hover
            })}
            style={{ maxWidth: FORMER_MAX_WIDTH }}
          >
            <div
              className={classnames("", {
                pv2: Boolean(title || subtitle)
              })}
            >
              <div onClick={onClick} className={`f3 tc ${classes}`}>
                {title}
              </div>
              {subtitle && (
                <div
                  onClick={onClick}
                  className={`f5 tc mt2 lh-copy w-100 w-two-thirds-ns center ${classes}`}
                >
                  {subtitle}
                </div>
              )}
              <div
                className="flex flex-column w-100 center tl"
                style={{ maxWidth: 282 }}
              >
                <div
                  onClick={onClick}
                  className={`fw4 f4 pt3 tc flex flex-row justify-center ${classes}`}
                >
                  {symbolValue && (
                    <div
                      key={symbolValue}
                      style={{ fontSize: 32 }}
                      className={`pr1 pt1 ${classes}`}
                    >
                      {symbolValue}
                    </div>
                  )}
                  <div
                    style={{ fontSize: 64 }}
                    className="fw5 f2 lh-solid truncate"
                  >
                    {amountToPresent}
                  </div>
                  {intervalLabel && (
                    <div className="f4 pl1 mt2">{intervalLabel}</div>
                  )}
                </div>
                {fields.map((node, nodeIx) => (
                  <div key={nodeIx} className={nodeIx ? "pt3" : ""}>
                    {node}
                  </div>
                ))}
                {checkoutProps && (
                  <div className="pt2 flex justify-end">
                    <FormerFlexCheckoutAction {...checkoutProps} />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </ReflectFocus>
  );
};

FormerPayment.propTypes = EntityPropTypes;

export default FormerPayment;
