import { useFormikContext } from "formik";
import { PRICE_DATA } from "utils/constants/state";
import { getFieldPath } from "utils/form";
import get from "lodash/get";
import { getPricesCurrencyOptions } from "utils/price";
import { usePageFactoryContext } from "utils/context";

export const usePricingCurrencyOptions = () => {
  const { values } = useFormikContext();
  const { stateKey } = usePageFactoryContext();
  const pricesDataPath = getFieldPath(stateKey, PRICE_DATA);

  const prices = get(values, pricesDataPath) || [];
  return getPricesCurrencyOptions(prices);
};
