import { SOCIAL_SERVICE } from "./constants/useCases";

const SERVICE_URL = {
  [SOCIAL_SERVICE.WHATSAPP]: "https://wa.me/",
  [SOCIAL_SERVICE.WECHAT]: "weixin://dl/chat?",
  [SOCIAL_SERVICE.MESSENGER]: "https://m.me/",
  [SOCIAL_SERVICE.INSTAGRAM]: "https://www.instagram.com/",
  [SOCIAL_SERVICE.SNAPCHAT]: "https://www.snapchat.com/add/",
  [SOCIAL_SERVICE.TWITTER]: "https://twitter.com/",
  [SOCIAL_SERVICE.FACEBOOK]: "https://www.facebook.com/",
  [SOCIAL_SERVICE.TIKTOK]: "https://www.tiktok.com/",
  [SOCIAL_SERVICE.TELEGRAM]: "https://t.me/",
  [SOCIAL_SERVICE.DISCORD]: "https://discord.com/channels/"
};

export const getSocialHandleExternalLink = (handle, service) => {
  return SERVICE_URL[service] ? `${SERVICE_URL[service]}${handle}` : "";
};
