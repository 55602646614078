import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import Overlay from "components/Overlay";
import {
  FORMER_MAX_WIDTH,
  getLeftSplitStyles,
  SPLIT_CONTENT_MAX_WIDTH,
  UI_THEME
} from "utils/constants/ui";
import { EntityPropTypes } from "utils/propTypes";
import ReflectFocus from "components/ReflectFocus";
import { shortLinkCheckoutPreview } from "components/FormerEditor/common/preview";
import isEmpty from "lodash/isEmpty";
import FormerFormCheckoutAction from "components/FormerFormCheckoutAction";
import { processViewportClasses } from "utils/editor";
import FieldGroupFactory from "./FieldGroupFactory";
import BrandingHeader from "components/BrandingHeader";
import classnames from "classnames";
import { FORM_INTENT } from "utils/constants/pricing";
import PageCheckoutPreview from "components/PageCheckoutPreview";
import EditableContent from "components/EditableContent";
import InitialPreview from "components/FormerInitialPreview";
import { useCommonEditorEntityParams } from "utils/hooks/editor";
import { checkoutPreviewLineCount } from "utils/checkout";
import { MAX_CHECKOUT_PREVIEW_ITEMS } from "components/PageCheckoutPreview";
import ReactVisibilitySensor from "react-visibility-sensor";
import PageBarCheckoutPreview from "components/PageBarCheckoutPreview";
import { isServer } from "utils/view";
import { ensureAppCheckout } from "utils/hooks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchAppCheckout } from "actions/app";
import { useFormCheckout } from "utils/hooks/manifest";
import {
  getFormCheckoutSettingsFocus,
  getFormPresentationSettingsFocus,
  useFormCheckoutPreviewFocus
} from "utils/hooks/focus";
import StripeRefreshNotice from "components/StripeRefreshNotice";
import FormattedNotice from "components/FormattedNotice";
import { useMergedFormSelectionsCheckoutConfig } from "utils/hooks/checkout";
import { getPageSplitContainerClasses } from "utils/ui";
import { useFormattedNotice } from "utils/hooks/notice";
import EmptyPrompt from "./EmptyPrompt";
import { STATE_KEYS } from "utils/constants/state";
import { COMMAND_CONTENT_ID } from "components/CommandPalette/constants";
import {
  CHECKOUT_PRODUCT_SETTINGS_INTENT,
  CHECKOUT_SETTINGS_INTENT
} from "utils/constants/checkout";
import { FIELD_GROUP_INTENT } from "utils/constants/fields";
import { setEditorFocus } from "utils/builder";
import { useFormikContext } from "formik";
import Button from "components/Form/fields/Button";

/**
 * Each time a field is interacted with (i.e. user types, selects option from dropdown etc.) the field value is updated
 * - e.g. manifest.data.content['some-uuid'].data.fieldGroups[0].fields[0].value
 * Each field has a uid (e.g messaging-test)
 * All current field values are then reduced and then used to lookup select combos
 *
 */
const FormerForm = ({
  afterChange,
  name,
  entity,
  data: { title, subtitle, fieldGroups, actions },
  config,
  entityRef,
  ui,
  ...props
}) => {
  const { loading } = ensureAppCheckout({
    fetchAppCheckout: props.actions.fetchAppCheckout
  });
  const formattedNotice = useFormattedNotice();
  const { values, setValues } = useFormikContext();

  const [showCheckoutBar, setShowCheckoutBar] = useState(false);
  const [showTruncatedDetails, setShowTruncatedDetails] = useState(false);
  const [showCheckoutBarOverlay, setShowCheckoutBarOverlay] = useState(false);

  const menuIntent = FORM_INTENT.FIELD_GROUP_ORDER;
  const {
    sharedFocus,
    fieldGroupIndexFocus,
    canActivateSettings,
    editorViewport,
    isLive,
    hasNavPrev,
    formIsFullScreen,
    brandAssets,
    hasBrandingAssets,
    manifestData,
    dataPath,
    contentPath,
    showInitialPreview,
    embed,
    stripeRefreshRequired
  } = useCommonEditorEntityParams({
    entity,
    name,
    menuIntent
  });
  const shortLink = useMergedFormSelectionsCheckoutConfig({
    name,
    fieldGroups
  });
  const checkoutPreviewFocus = useFormCheckoutPreviewFocus({
    name,
    fieldGroups
  });
  const {
    hasCheckout,
    disabled,
    formData,
    customer,
    hasFields,
    hasAnyPrimaries,
    comboFields,
    isSetupMode
  } = useFormCheckout({
    name,
    fieldGroups
  });
  const presentationFocus = getFormPresentationSettingsFocus({ name });

  const compClasses = getPageSplitContainerClasses({
    embed,
    formIsFullScreen,
    hasBrandingAssets,
    canActivateSettings,
    hasNavPrev,
    viewport: editorViewport
  });

  const checkoutProps = actions[0]
    ? {
        action: actions[0],
        disabled,
        isLive,
        name,
        formData,
        shortLink,
        customer
      }
    : null;

  const checkoutAction = checkoutProps ? (
    <FormerFormCheckoutAction {...checkoutProps} />
  ) : null;

  let previewContent = null;
  let checkoutBar = null;

  /**
   * Can show preview if
   * - there is a checkout selection result or
   * - or a constant checkout
   * - or payment mode is
   * - and resource data (prices, products etc.) to support the rendering of the content
   */
  const canShowPreview = hasCheckout && !isEmpty(manifestData);
  /**
   * Can show initial preview
   * - if primaries are present
   * - or checkout form mode is setup
   */
  const canShowInitialPreview = hasAnyPrimaries || isSetupMode;
  /**
   * Show prompt
   * - guide the user to add fields
   */
  const showPrompt = !hasAnyPrimaries && canActivateSettings && !isSetupMode;

  if (canShowPreview) {
    const checkoutPreview = shortLinkCheckoutPreview({
      shortLink,
      ...manifestData
    });

    checkoutBar = !embed.enabled ? (
      <PageBarCheckoutPreview
        showOverlay={showCheckoutBarOverlay}
        setShowOverlay={setShowCheckoutBarOverlay}
        collapseImages={checkoutPreview.collapseImages}
        {...checkoutPreview.checkout}
      >
        <FormerFormCheckoutAction {...checkoutProps} />
      </PageBarCheckoutPreview>
    ) : null;

    const lineCount = checkoutPreviewLineCount(checkoutPreview.checkout);
    const truncatePreview =
      lineCount > MAX_CHECKOUT_PREVIEW_ITEMS && !embed.enabled;
    const previewClasses = processViewportClasses(
      {
        checkoutAction: classnames("dn dib-ns", {
          "pl5-ns": !checkoutPreview.collapseImages
        })
      },
      editorViewport
    );
    const wrappedCheckoutAction = (
      <div
        className={classnames(compClasses.desktopCheckoutButton, {
          [previewClasses.checkoutAction]: !checkoutPreview.collapseImages
        })}
      >
        {checkoutAction}
      </div>
    );
    const portalContent = (
      <Fragment>
        <PageCheckoutPreview
          collapseImages={checkoutPreview.collapseImages}
          {...checkoutPreview.checkout}
        >
          {checkoutAction}
        </PageCheckoutPreview>
        {wrappedCheckoutAction}
      </Fragment>
    );

    const pageCheckoutPreviewProps = {
      truncatePreview,
      collapseImages: checkoutPreview.collapseImages,
      ...checkoutPreview.checkout
    };

    previewContent = (
      <Fragment>
        <div className="w-100 relative">
          <PageCheckoutPreview {...pageCheckoutPreviewProps}>
            {checkoutAction}
          </PageCheckoutPreview>
          {truncatePreview ? (
            <div className={compClasses.portalTrigger}>
              <div
                className="w-100 justify-center items-end flex h-100"
                onClick={() => {
                  setShowTruncatedDetails(true);
                }}
              >
                <div
                  className={classnames("w-100", {
                    [previewClasses.checkoutAction]: !checkoutPreview.collapseImages
                  })}
                >
                  <div
                    style={{ width: 104 }}
                    className="w4 shadow-0 ba hairline-1 bg-white pa1 br2 center pointer bg-white--hover"
                  >
                    <div className="f6 fw6 tc black-70">{`${
                      showTruncatedDetails ? "Hide" : "Show"
                    } details`}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {showTruncatedDetails &&
          ReactDOM.createPortal(
            <Overlay hideOverlay={() => setShowTruncatedDetails(false)}>
              <div className="pa3 pa4-ns">{portalContent}</div>
            </Overlay>,
            document.body
          )}
      </Fragment>
    );
  } else if (canShowInitialPreview) {
    previewContent = (
      <ReflectFocus focus={presentationFocus}>
        {({ onClick, classes: targetedClasses, edit } = {}) => (
          <div
            onClick={onClick}
            className={classnames(
              `${compClasses.initialPreview} ${targetedClasses}`,
              {
                dn: !showInitialPreview && !edit
              }
            )}
          >
            <InitialPreview name={dataPath} edit={edit} />
          </div>
        )}
      </ReflectFocus>
    );
  } else if (showPrompt) {
    const otherFocus = getFormCheckoutSettingsFocus({
      uuid: name,
      menuSubIntent: CHECKOUT_SETTINGS_INTENT.SET_CONSTANT
    });

    const subheaderParts = ["Add a default product to checkout."];
    let promptActions = [];
    if (comboFields) {
      subheaderParts.push(
        "Or set logic to include products based on form selection."
      );
      promptActions = [
        <div key="go-combo" className="pl2">
          <Button
            theme={UI_THEME.SLIM}
            copy="Logic"
            onClick={setEditorFocus({
              values,
              setValues,
              focus: getFormCheckoutSettingsFocus({
                uuid: name,
                menuSubIntent: CHECKOUT_SETTINGS_INTENT.SET_PRODUCTS,
                menuThirdIntent: CHECKOUT_PRODUCT_SETTINGS_INTENT.SET_FIELDS
              })
            })}
          />
        </div>
      ];
    }

    previewContent = (
      <div className="pb4">
        <EmptyPrompt
          header="Add a product"
          subheader={subheaderParts.join(" ")}
          commandId={COMMAND_CONTENT_ID.FUNDAMENTALS.ADD_A_PRODUCT}
          focus={otherFocus}
          actions={promptActions}
        />
      </div>
    );
  }

  const checkboutBarVisible = showCheckoutBar && hasCheckout && checkoutBar;
  /**
   * When the top section scrolls off the screen we want to show the sticky header for checkout, if we can
   */
  const stickyHeaderSensor = (
    <ReactVisibilitySensor>
      {({ isVisible }) => {
        if (!isServer() && !showCheckoutBarOverlay) {
          setShowCheckoutBar(!isVisible);
        }

        return (
          <div style={{ height: 1 }} className="w-100 bg-transparent"></div>
        );
      }}
    </ReactVisibilitySensor>
  );

  const showEmptyBuilder = canActivateSettings && !hasFields;

  return (
    <ReflectFocus name={entity.schema.title} focus={sharedFocus} withHeader>
      {({ hover, classes } = {}) => {
        return (
          <div
            ref={entityRef}
            id={contentPath}
            className={compClasses.container}
            style={canActivateSettings ? { maxWidth: FORMER_MAX_WIDTH } : {}}
          >
            {showEmptyBuilder ? (
              <div className="w-100 h-auto bg-white flex items-center justify-center">
                <div className="pa3 w-100">
                  <EmptyPrompt
                    header="Get started"
                    subheader="Add a field to this form."
                    commandId={COMMAND_CONTENT_ID.FUNDAMENTALS.ADD_A_FIELD}
                    focus={[
                      ...sharedFocus,
                      {
                        key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
                        value: FIELD_GROUP_INTENT.ADD_FIELD
                      },
                      {
                        key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
                        value: 0
                      },
                      {
                        key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
                        value: 0
                      }
                    ]}
                  />
                </div>
              </div>
            ) : (
              <Fragment>
                {checkboutBarVisible && (
                  <div id="checkoutBar" className={compClasses.checkoutBar}>
                    {checkoutBar}
                  </div>
                )}
                <div
                  style={canActivateSettings ? {} : { zIndex: -1 }}
                  className={compClasses.background}
                >
                  <div className="w-50 bg-off-white-ns"></div>
                  <div className="w-50 split__container-ns bg-white"></div>
                </div>
                <div
                  style={{ maxWidth: SPLIT_CONTENT_MAX_WIDTH }}
                  className={compClasses.content}
                >
                  <div
                    style={getLeftSplitStyles({
                      hasBrandingAssets,
                      showInitialPreview
                    })}
                    className={compClasses.left}
                  >
                    {hasBrandingAssets && (
                      <ReflectFocus focus={presentationFocus}>
                        {({ onClick, classes: targetedClasses } = {}) => (
                          <div
                            onClick={onClick}
                            className={`${compClasses.brandingWrapper} ${targetedClasses}`}
                          >
                            <BrandingHeader
                              {...brandAssets}
                              viewport={editorViewport}
                            />
                          </div>
                        )}
                      </ReflectFocus>
                    )}
                    <ReflectFocus focus={checkoutPreviewFocus}>
                      {({ onClick, classes: targetedClasses } = {}) => (
                        <div
                          className={`${compClasses.resultContainer} ${targetedClasses}`}
                          onClick={onClick}
                        >
                          {previewContent}
                        </div>
                      )}
                    </ReflectFocus>

                    {stickyHeaderSensor}
                  </div>
                  <div className={compClasses.right}>
                    {formattedNotice && (
                      <FormattedNotice content={formattedNotice} />
                    )}
                    {stripeRefreshRequired ? (
                      <StripeRefreshNotice />
                    ) : (
                      <Fragment>
                        <ReflectFocus focus={fieldGroupIndexFocus}>
                          {({
                            onClick,
                            classes: targetedClasses,
                            edit
                          } = {}) => (
                            <div
                              onClick={onClick}
                              className={classnames(
                                `${compClasses.title} ${targetedClasses}`,
                                {
                                  dn: !title && !edit,
                                  pb3: !subtitle && !edit
                                }
                              )}
                            >
                              <EditableContent
                                editable={edit}
                                sync={{
                                  name: `${dataPath}.title`
                                }}
                              >
                                {title}
                              </EditableContent>
                            </div>
                          )}
                        </ReflectFocus>
                        <ReflectFocus focus={fieldGroupIndexFocus}>
                          {({
                            onClick,
                            classes: targetedClasses,
                            edit
                          } = {}) => (
                            <div
                              onClick={onClick}
                              className={classnames(
                                `${compClasses.subtitle} ${targetedClasses}`,
                                {
                                  dn: !subtitle && !edit
                                }
                              )}
                            >
                              <EditableContent
                                editable={edit}
                                sync={{
                                  name: `${dataPath}.subtitle`
                                }}
                              >
                                {subtitle}
                              </EditableContent>
                            </div>
                          )}
                        </ReflectFocus>
                        <FieldGroupFactory
                          entity={entity}
                          name={dataPath}
                          focus={sharedFocus}
                          loading={Boolean(loading)}
                        />
                        {checkoutAction && (
                          <div className="pt2 flex justify-end">
                            {checkoutAction}
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        );
      }}
    </ReflectFocus>
  );
};

FormerForm.propTypes = EntityPropTypes;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchAppCheckout
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(FormerForm);
