import React, { useState } from "react";
import PropTypes from "prop-types";
import { usePageFactoryContext } from "utils/context";
import { useFormikContext } from "formik";
import { PB_COMPONENT_CLASS, STATUS } from "utils/constants/ui";
import { setEditorFocus } from "utils/builder";
import Analytics, { REFLECT_FOCUS } from "utils/analytics";
import startCase from "lodash/startCase";
import Pill from "components/Pill";
import classnames from "classnames";
import { getIconForUID } from "components/FormerEditor/utils";
import { COLORS } from "utils/styles";
import { useHoverProps } from "utils/hooks";
import { NO_OP } from "utils/action";
import { EDITOR_MODE } from "utils/constants/editor";
import get from "lodash/get";
import { STATE_KEYS } from "utils/constants/state";
import { useActiveEditorFocus } from "utils/hooks/editor";

const ReflectFocus = ({ children, focus, name, withHeader, classes }) => {
  const [hover, setHover] = useState(false);
  const hoverProps = useHoverProps({ hover, setHover });
  const formContext = useFormikContext();
  const values = formContext ? formContext.values : {};
  const setValues = formContext ? formContext.setValues : NO_OP;

  const pageFactoryContext = usePageFactoryContext();
  const canActivateSettings = Boolean(
    pageFactoryContext && pageFactoryContext.canActivateSettings
  );

  /**
   * If focus matches the active focus state of the editor
   * Then can add active stylings
   */
  const isActiveFocus = useActiveEditorFocus(focus);

  const reflectProps = {
    onClick: () => {},
    hover,
    classes: "",
    inspect: false,
    edit: false
  };
  const formMode = get(values, STATE_KEYS.EDITOR.MODE);
  const inspectMode = formMode === EDITOR_MODE.INSPECT;
  const editMode = formMode === EDITOR_MODE.EDIT;
  reflectProps.inspect = inspectMode;
  reflectProps.edit = editMode;
  const eligibleMode = inspectMode || editMode;
  const hasFocus = Array.isArray(focus) && focus.length;
  const canReflect = Boolean(canActivateSettings && hasFocus && eligibleMode);

  if (canReflect) {
    const setEntityActive = setEditorFocus({
      values,
      setValues,
      focus
    });

    const reflect = () => {
      Analytics.track(REFLECT_FOCUS);
      setEntityActive();
    };

    const reflectClasses = ["pointer relative", classes];
    if (isActiveFocus && inspectMode) {
      reflectClasses.push(`${PB_COMPONENT_CLASS}--focus`);
    }
    if (!editMode) {
      reflectClasses.push(PB_COMPONENT_CLASS);
    }
    reflectProps.classes = reflectClasses.join(" ");

    if (inspectMode) {
      reflectProps.onClick = reflect;
    }

    const node = children(reflectProps);

    const wrapperProps = {
      ...hoverProps,
      className: classnames("w-100 relative", {
        "b-inner--primary-fade": hover && !editMode,
        "bg-transparent": !hover
      })
    };
    const Icon = getIconForUID("settings");

    return withHeader ? (
      <div {...wrapperProps}>
        {name && hover && (
          <div className="absolute z-3 pointer" style={{ left: 8, top: 8 }}>
            <Pill
              copy={`${startCase(name)}`}
              onClick={reflect}
              customClasses={{
                container:
                  "flex flex-row w-100 items-end justify-center bg-white",
                copy: "f7 pa1 br2 fw5 flex-shrink-0 items-center flex bg-white"
              }}
              status={STATUS.NEUTRAL}
            >
              <div className="pl1 flex items-center justify center">
                <Icon size={12} color={COLORS.blue} />
              </div>
            </Pill>
          </div>
        )}
        {node}
      </div>
    ) : (
      node
    );
  } else {
    /**
     * Pass an empty class so that we dont append undefined to class names
     */
    return children({ classes: "" });
  }
};

ReflectFocus.propTypes = {
  focus: PropTypes.array
};

export default ReflectFocus;
