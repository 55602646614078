import React from "react";
import Select from "components/Form/fields/Select";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { getFieldNameRoot } from "utils/form";
import { FIELD_TEMPLATES, SET_TOUCH_TIMEOUT } from "utils/constants/form";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import classnames from "classnames";
import LabelDescription from "components/Form/fields/LabelDescription";
/**
 * We use the uuid as the value key because it wont change
 * Unlike if a user decides to change the option label name
 * We just have to map the options back to string values when needed
 */
import { UUID_VALUE_KEY } from "utils/constants/form";
import { useSelectOptions } from "utils/hooks/form";
import { INPUT_CLASSES, SELECT_DEFAULT_PLACEHOLDER } from "utils/constants/ui";

/**
 *
 * @param {String} name
 * @param {String} label
 * @param {String} props.uid
 * @param {String} props.uuid
 * @param {String} props.validation
 * @param {String} props.value
 * @param {String} props.options
 */
const FormSelect = (props) => {
  const {
    name,
    label,
    description,
    options,
    validation,
    focus = [],
    index,
    placeholder
  } = props;

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const value = get(values, name);
  const nameRoot = getFieldNameRoot(name);

  const isTouched = get(touched, name);
  const required = validation && validation.required;
  const error = get(errors, name);
  const showError = Boolean(isTouched && error);

  const selectOptions = useSelectOptions({
    template: FIELD_TEMPLATES.SELECT.OPTION({
      label: placeholder || SELECT_DEFAULT_PLACEHOLDER
    }),
    name: nameRoot,
    options
  });

  /**
   * onSelect change the value will be updated with the uuid
   * - uid is now largely ignored
   */
  const selectProps = {
    options: selectOptions,
    value,
    valueKey: UUID_VALUE_KEY,
    hideLabel: true,
    label: label,
    disabled: Boolean(props.disabled),
    stateKey: name,
    onChange: (key) => (evt) => {
      const selectedUUID = evt.currentTarget.value;

      setFieldValue(key, selectedUUID);

      if (!isTouched) {
        setTimeout(() => {
          setFieldTouched(name, true);
        }, SET_TOUCH_TIMEOUT);
      }
    },
    labelTransform: (val) => {
      return val;
    },
    customClasses: {
      container: "h-100",
      input: INPUT_CLASSES
    }
  };

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes, edit } = {}) => (
        <div
          id={nameRoot}
          className={classnames(`w-100 ${classes}`, {
            hover: hover
          })}
          onClick={onClick}
        >
          <LabelDescription
            nameRoot={nameRoot}
            edit={edit}
            label={label}
            description={description}
            showError={showError}
            required={required}
          />
          <Select {...selectProps} />
          {showError && <div className="f7 pt1 color-error">{error}</div>}
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormSelect;
