import { useEffect } from "react";
import { useFormikContext } from "formik";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import { useStateNameMap } from "utils/selectors";
import { US_COUNTRY_CODE } from "utils/constants/address";

/**
 * Handle side effects on state via country change
 * @param {Object} props
 * @param {String} props.uuid
 */
export const useHandleAddressCountryChange = ({ name }) => {
  const { setFieldValue, values } = useFormikContext();
  const countryPath = `${name}.address.country`;
  const country = get(values, countryPath);
  const statePath = `${name}.address.state`;
  const state = get(values, statePath);
  const { data: stateNameMap, inverted } = useStateNameMap();

  const isUSA = country === US_COUNTRY_CODE;

  useEffect(() => {
    if (state) {
      if (isUSA) {
        const nameHasCode = stateNameMap[state.toLowerCase()];
        if (nameHasCode) {
          setFieldValue(statePath, nameHasCode);
        }
      } else {
        const codeHasName = inverted[state];
        if (codeHasName) {
          setFieldValue(statePath, upperFirst(codeHasName));
        }
      }
    }
  }, [isUSA]);
};
