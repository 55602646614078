import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import Label from "components/Form/fields/Label";
import get from "lodash/get";
import { getCheckboxProps, getFieldNameRoot } from "utils/form";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import { DEFAULT_DEBOUNCE, SET_TOUCH_TIMEOUT } from "utils/constants/form";
import EditableContent from "components/EditableContent";
import LegalEditorField from "components/LegalEditorField";
import debounce from "lodash/debounce";
import { getUUID } from "utils/uuid";
import { usePageBuilderContext, usePageFactoryContext } from "utils/context";
import { LEGAL_CONTENT_ACCEPTANCE_TYPE } from "utils/constants/legalContent";
import classnames from "classnames";
import Checkbox from "rc-checkbox";

const DEFAULT_CLASSES = {
  container: "flex-auto tl flex-column",
  content: "flex flex-column"
};

const FormLegal = (props) => {
  const {
    name,
    label,
    description,
    validation,
    customClasses,
    focus = [],
    index
  } = props;
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const nameRoot = getFieldNameRoot(name);
  const { canActivateSettings } = usePageFactoryContext();

  const builderCtx = usePageBuilderContext();
  const componentRefreshKey = builderCtx && builderCtx.componentRefreshKey;

  const isTouched = get(touched, name);
  const required = validation && validation.required;
  const error = get(errors, STATE_KEYS.LEGAL.ACCEPTANCE_CLICKED);

  const disabled = Boolean(props.disabled);

  const showError = Boolean(isTouched && error);
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  const labelName = `${nameRoot}.label`;
  const descriptionName = `${nameRoot}.description`;
  const inlineContentName = `${nameRoot}.${STATE_KEYS.LEGAL.CONTENT_INLINE_CONTENT}`;

  const inlineContent = get(values, inlineContentName);
  const inlineContentTouched = get(touched, inlineContentName);

  /**
   * Conditionally render the Checkbox when acceptance type is clickwrap
   */
  const acceptanceTypeName = `${nameRoot}.${STATE_KEYS.LEGAL.ACCEPTANCE_TYPE}`;
  const acceptanceType = get(values, acceptanceTypeName);

  const clickRequired =
    acceptanceType === LEGAL_CONTENT_ACCEPTANCE_TYPE.CLICKWRAP;
  const clickedPath = `${nameRoot}.value.${STATE_KEYS.LEGAL.ACCEPTANCE_CLICKED}`;
  const clicked = get(values, clickedPath);

  const onUpdate = ({ editor }) => {
    setFieldValue(inlineContentName, editor.getJSON());
    if (!inlineContentTouched) {
      setTimeout(() => setFieldTouched(inlineContentName, true), 0);
    }
  };

  const debounceInlineUpdate = useMemo(
    () => debounce(onUpdate, DEFAULT_DEBOUNCE),
    []
  );

  const ctxInlineContent = inlineContent || {
    type: "doc",
    content: [
      {
        type: "paragraph"
      }
    ]
  };

  const toggleValue = (_, active) => {
    if (!disabled) {
      setFieldValue(clickedPath, active);

      if (!isTouched) {
        setTimeout(() => {
          setFieldTouched(clickedPath, true);
        }, SET_TOUCH_TIMEOUT);
      }
    }
  };

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => {
        const showLabel = Boolean(label || edit);
        const showDescription = Boolean(description || edit);

        const legalFieldKey = useMemo(() => {
          return getUUID();
        }, [edit, componentRefreshKey]);

        return (
          <div
            id={nameRoot}
            className={`w-100 ${reflectClasses}`}
            onClick={onClick}
          >
            <div className={classes.container}>
              {showLabel && (
                <Label
                  edit={edit}
                  name={labelName}
                  label={label}
                  error={showError}
                  required={required}
                />
              )}
              <div className={classes.content}>
                {showDescription && (
                  <div className="w-100 pointer">
                    <div className="f6 tl pr2 lh-copy">
                      <EditableContent
                        editable={edit}
                        sync={{
                          name: descriptionName
                        }}
                      >
                        {description}
                      </EditableContent>
                    </div>
                  </div>
                )}
                <div
                  className={classnames("flex", {
                    "flex-row": clickRequired,
                    "flex-column": !clickRequired
                  })}
                >
                  {clickRequired ? (
                    <div className="pr2">
                      <Checkbox
                        {...getCheckboxProps({
                          key: clickedPath,
                          active: clicked,
                          disabled,
                          setFieldValue: toggleValue
                        })}
                      />
                    </div>
                  ) : null}
                  <div
                    className="dib"
                    style={clickRequired ? { paddingTop: 1 } : {}}
                  >
                    <LegalEditorField
                      key={legalFieldKey}
                      editable={edit}
                      openOnClick={!canActivateSettings}
                      name={inlineContentName}
                      content={ctxInlineContent}
                      onUpdate={debounceInlineUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
            {showError ? (
              <div className="f7 pt1 color-error">{error}</div>
            ) : null}
          </div>
        );
      }}
    </ReflectFocus>
  );
};

export default FormLegal;

/**
 * 
 * Existing validation email but no pre-fill config then do the email
 * If !prefill but validation.email.pattern then
 * - set stripe customer defaults 
 * - else baseline prefill
 {
   prefill: {
     enabled: true,
     source: 'query',
     editable: true,
     query: {
       keys: {
         'email': true,
         'customer_email': true
       }
     }, 
      * Map any matched query value into fields
      * - render
      * - then use the forward param to pick out field values
     forward: {
       stripe: {
         checkout: {
           'customer_email': true
         }
       }
     }
   },
   presentation: {
     mask: true,
     hidden: false
   }
 }
 */
