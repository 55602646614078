import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import Tooltip from "components/Tooltip";
import { BLOCKS_PREFIX } from "utils/constants/ui";
import classnames from "classnames";

const FeatureEntitlementsRow = ({
  row,
  groupIndex,
  rowIndex,
  tdWidth,
  thWidth
}) => {
  const rowKeys = Object.keys(row);
  const titleKey = rowKeys[0];
  const cellKeys = rowKeys.slice(1);
  const Icon = getIconForUID("checkmark");
  const hasTooltip = row[titleKey] && row[titleKey].tooltip;
  const titleContent = hasTooltip ? (
    <Tooltip id={`${groupIndex}.${rowIndex}`} tooltip={row[titleKey].tooltip}>
      {row[titleKey].value}
    </Tooltip>
  ) : (
    row[titleKey].value
  );

  return (
    <tr>
      <th
        className="tl fw4 bb bl br hairline-1 relative bg-white"
        style={{ width: `${thWidth}%` }}
      >
        <div className="flex flex-row items-center w-100 justify-between">
          <div
            className={classnames("f5 dib pv3 pl3 user-select-n", {
              dotted__underline: hasTooltip
            })}
          >
            {titleContent}
          </div>
        </div>
      </th>
      {cellKeys.map((key, keyIx) => {
        const config = row[key];
        let content = "";
        if (config) {
          if (config.enabled) {
            const value = config.value || (
              <Icon
                size={24}
                className={`${BLOCKS_PREFIX}feature__enabled--checkmark`}
              />
            );
            const tipId = `${groupIndex}.${rowIndex}.${keyIx}`;

            content = config.tooltip ? (
              <Tooltip
                id={tipId}
                tooltip={config.tooltip}
                customClasses={{
                  tooltip: "bg-black-90 white dib"
                }}
              >
                {value}
              </Tooltip>
            ) : (
              value
            );
          }
        }

        return (
          <td
            key={keyIx}
            className="tc bb br hairline-1 relative bg-white"
            style={{ width: `${tdWidth}%` }}
          >
            <div className="f5 dib">
              <div className="pa2 flex items-center justify-center">
                {content}
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default FeatureEntitlementsRow;
