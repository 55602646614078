import React from "react";
import ReflectFocus from "components/ReflectFocus";
import { useFormikContext } from "formik";
import { usePageFactoryContext } from "utils/context";
import { getFieldPath } from "utils/form";
import { CONFIG_PRICING_DEFAULT_INTERVAL } from "utils/constants/state";
import get from "lodash/get";
import { INTERVAL_SHORTHAND_MAP } from "components/FormerEditor/common/constants";
import { ENTITY_HOVER_UNDERLINE } from "utils/constants/ui";

const DEFAULT_CLASSES = {
  container: "f3 pt3 tc flex flex-row justify-center",
  symbol: "f4 pr1",
  price: "fw5 f2 lh-solid",
  interval: "f4 pl1 mt2"
};

const FormattedPriceHeader = ({ symbol, formatted, focus, customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const { values } = useFormikContext();
  const { stateKey } = usePageFactoryContext();
  const rootPath = getFieldPath(stateKey, CONFIG_PRICING_DEFAULT_INTERVAL);

  const interval = get(values, rootPath);
  const intervalShorthand = INTERVAL_SHORTHAND_MAP[interval];

  return (
    <ReflectFocus focus={focus} classes={ENTITY_HOVER_UNDERLINE}>
      {({ onClick, classes: focusClasses } = {}) => (
        <div
          onClick={onClick}
          className={`${classes.container} ${focusClasses}`}
        >
          {symbol && <div className={classes.symbol}>{symbol}</div>}
          {formatted && <div className={classes.price}>{formatted}</div>}
          {intervalShorthand && (
            <div className={classes.interval}>{`/${intervalShorthand}`}</div>
          )}
        </div>
      )}
    </ReflectFocus>
  );
};
export default FormattedPriceHeader;
