import React from "react";

const MultiselectLoader = () => (
  <div className="ba bg-white br2 shadow-0 flex flex-column items-center hairline-1">
    {new Array(5).fill("").map((_, rowIx) => (
      <div key={rowIx} className="flex flex-row w-100">
        <div className="pl2 pv2 flex w-100">
          <div className="h2 w-100 shimmer"></div>
        </div>
        <div className="pa2 flex">
          <div className="h2 w2 shimmer"></div>
        </div>
      </div>
    ))}
  </div>
);

export default MultiselectLoader;
