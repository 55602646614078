import React from "react";
import { defaultHttps } from "utils/url";

const DEFAULT_CLASSES = {
  container: ""
};

const BrandLogo = ({ brand, customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  let content = null;
  let brandStyles = { height: 40 };
  const hasBrandImg = brand && brand.src;
  if (hasBrandImg) {
    const hasLinkWrapper = brand.url;
    const srcImage = (
      <img
        alt={`Brand logo for ${brand.src}`}
        style={{
          maxWidth: "100%",
          maxHeight: "100%"
        }}
        src={brand.src}
      />
    );
    const wrappedImage = <a href={defaultHttps(brand.url)}>{srcImage}</a>;
    content = hasLinkWrapper ? wrappedImage : srcImage;
  } else if (process.env.NODE_ENV === "development") {
    /**
     * Stripe doesnt allow development branding assets so we render placeholders instead
     */
    brandStyles.width = 100;
    content = (
      <div style={{ height: 40 }} className="w-100 h-100 bg-black-05 br2"></div>
    );
  }

  return (
    <div className={classes.container} style={brandStyles}>
      {content}
    </div>
  );
};
export default BrandLogo;
