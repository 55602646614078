import React, { Fragment } from "react";
import { useTaxIds } from "utils/selectors";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import get from "lodash/get";
import DebounceInput from "components/DebounceInput";
import { DEFAULT_DEBOUNCE, SET_TOUCH_TIMEOUT } from "utils/constants/form";
import { INPUT_COMMON_CLASS } from "utils/constants/ui";

const TinValue = ({ name, uid, label, required, disabled }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const fieldValue = get(values, name, {});

  const code = fieldValue.code;
  const value = fieldValue.value;
  const type = fieldValue.type;

  const valuePath = getFieldPath(name, "value");
  const valueError = get(errors, valuePath);
  const valueTouched = get(touched, valuePath);

  const taxIds = useTaxIds();

  const matchingTaxId = taxIds.find(
    (taxId) => type === taxId.id && code === taxId.code
  );

  const taProps = {
    name: valuePath,
    minRows: 1,
    customClasses: {
      input: `${INPUT_COMMON_CLASS} bg-white--hover pointer brbl-6 brbr-6 brtr-0 brtl-0`
    },
    uid: `${code}-${type}`,
    inputValue: value,
    disabled: Boolean(disabled),
    debounce: DEFAULT_DEBOUNCE,
    hideError: true,
    valueSet: (val) => {
      setFieldValue(valuePath, val);

      if (!valueTouched) {
        setTimeout(() => {
          setFieldTouched(valuePath, true);
        }, SET_TOUCH_TIMEOUT);
      }
    }
  };
  /**
   * Need to have a country code tax id selected first
   */
  if (!matchingTaxId) {
    taProps.disabled = true;
  } else {
    taProps.placeholder = matchingTaxId.example;
  }

  return (
    <Fragment>
      <DebounceInput {...taProps} />
      {valueError && valueTouched && (
        <div className="f7 pt1 fw5 red">{valueError}</div>
      )}
    </Fragment>
  );
};

export default TinValue;
