import React from "react";
import { VIEWPORT_SIZE } from "utils/constants/viewport";
import { useViewport } from "utils/context";

const IMAGE_STYLE = {
  maxWidth: "100%",
  maxHeight: "100%"
};

const LOGO_DIMENSIONS = {
  SMALL: 32,
  NOT_SMALL: 40
};

const ICON_DIMENSIONS = {
  SMALL: {
    height: 40,
    width: 40
  },
  NOT_SMALL: {
    height: 80,
    width: 80
  }
};

const BrandingHeader = ({ icon, logo, domain = "", viewport }) => {
  const { isSmall } = useViewport();
  const ctxViewport =
    viewport || isSmall ? VIEWPORT_SIZE.MOBILE : VIEWPORT_SIZE.DESKTOP;
  const smallDimensions = ctxViewport === VIEWPORT_SIZE.MOBILE;

  let content = null;
  let alt = `Brand logo for ${domain}`;

  if (logo) {
    const logoStyle = smallDimensions
      ? LOGO_DIMENSIONS.SMALL
      : LOGO_DIMENSIONS.NOT_SMALL;
    content = (
      <div style={{ height: logoStyle }} className="overflow-hidden">
        <img alt={alt} style={IMAGE_STYLE} src={logo} />
      </div>
    );
  } else if (icon) {
    const iconStyle = smallDimensions
      ? ICON_DIMENSIONS.SMALL
      : ICON_DIMENSIONS.NOT_SMALL;
    content = (
      <div
        style={iconStyle}
        className="br-100 hairline-1 ba pa2 overflow-hidden flex items-center justify-center"
      >
        <img alt={alt} style={IMAGE_STYLE} src={icon} />
      </div>
    );
  }
  return content;
};

export default BrandingHeader;
