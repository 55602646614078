import React from "react";
import { VIEWPORT_SIZE } from "utils/constants/viewport";
import { useEditorViewport } from "utils/hooks";
import { useEmbed } from "utils/hooks/embed";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";
import CheckoutPreview from "components/CheckoutPreview";
import { usePageFactoryContext } from "utils/context";
import classnames from "classnames";
import { getEmbedState } from "utils/manifest";

export const MAX_CHECKOUT_PREVIEW_ITEMS = 5;
const ORDERED_PREVIEW_ITEM_KEYS = [
  "lineItems",
  "discountItems",
  "taxItems",
  "shippingItems",
  "amountItems"
];
/**
 * If we dont want the checkout to be too long we can truncatePreview the item collections
 * - Any collection which exceeds the limit will be set to empty
 */
const truncatePreviewCheckout = (props) => {
  const result = {
    badge: props.badge,
    banner: props.banner,
    collapseImages: props.collapseImages,
    subscriptionScheduleItems:
      Array.isArray(props.subscriptionScheduleItems) &&
      props.subscriptionScheduleItems.length > 0
        ? props.subscriptionScheduleItems
        : null
  };
  let sum = 0;
  ORDERED_PREVIEW_ITEM_KEYS.forEach((itemKey) => {
    const itemsLength = props[itemKey].length;
    if (sum < MAX_CHECKOUT_PREVIEW_ITEMS) {
      result[itemKey] = props[itemKey].slice(0, MAX_CHECKOUT_PREVIEW_ITEMS);
      sum += itemsLength;
    } else {
      result[itemKey] = [];
    }
  });

  return result;
};

export const PAGE_CHECKOUT_PREVIEW_CLASSES = {
  image: "flex justify-center pb3",
  title: "f5 fw5 tc tl-ns f4-ns pb1 black-60",
  label: "f2 tc tl-ns f1-ns fw6",
  sublabel: "f6 f5-ns tc tl-ns black-60 pt1",
  body: "f6 f5-ns tc tl-ns black-60 pt2 lh-title"
};
/**
 * Presentation styles
 * - Page checkout
 * -- Desktop & one item - CHECKOUT_PRESENTATION.MODAL
 * -- Desktop & multiple items - CHECKOUT_PRESENTATION.LIST
 * -- Mobile & single item - CHECKOUT_PRESENTATION.MODAL
 * -- Mobile & multiple items - CHECKOUT_PRESENTATION.MODAL
 * - As itemized list
 * -- Link builder
 * -- Link details
 * -- Checkout success page
 * @returns
 */
const PageCheckoutPreview = (preview) => {
  const {
    truncatePreview,
    badge,
    banner,
    lineItems,
    discountItems,
    subscriptionScheduleItems,
    taxItems,
    shippingItems,
    children
  } = preview;
  const editorViewport = useEditorViewport();
  const embed = useEmbed();

  const pageFactoryContext = usePageFactoryContext();
  const isEditor = Boolean(
    pageFactoryContext && pageFactoryContext.canActivateSettings
  );

  const hasDiscounts = discountItems && discountItems.length > 0;
  const hasTaxes = taxItems && taxItems.length > 0;
  const hasShippings = shippingItems && shippingItems.length > 0;
  const hasSubscriptionScheduleItems =
    subscriptionScheduleItems && subscriptionScheduleItems.length > 0;
  const hasSecondaryLines = hasTaxes || hasDiscounts || hasShippings;
  const hasAdditionalInfo = Boolean(
    hasSecondaryLines || hasSubscriptionScheduleItems
  );

  const firstLineItem = lineItems[0];
  const checkoutImage = firstLineItem && firstLineItem.image;

  const isMobile = editorViewport === VIEWPORT_SIZE.MOBILE;
  const isDesktop = editorViewport === VIEWPORT_SIZE.DESKTOP;
  const singleLineItem = lineItems.length === 1;
  const multipleLines = lineItems.length > 1;
  /**
   * If the line item has a quantity
   * Then check if its a single value to factor into whether to show the hero presentation
   * TODO: line items need meta quantity
   */
  const noQuantityPresentation =
    firstLineItem && firstLineItem.hasOwnProperty("quantity")
      ? firstLineItem.quantity === 1
      : true;

  const heroPresentation =
    singleLineItem && noQuantityPresentation && !hasAdditionalInfo;
  const showLargeImageOnly = isDesktop && heroPresentation;

  let content;
  const commonProps = {
    preview,
    banner,
    badge,
    image: checkoutImage,
    firstLineItem,
    subscriptionScheduleItems
  };
  /**
   * When isEditor
   * - Then allow for presentation which takes into account the dynamic editor viewport
   */
  const desktopProps = truncatePreview
    ? truncatePreviewCheckout(preview)
    : preview;
  if (isEditor) {
    if (isMobile) {
      content = (
        <MobileHeader
          {...commonProps}
          multipleLines={multipleLines}
          hasAdditionalInfo={hasAdditionalInfo}
        >
          {children}
        </MobileHeader>
      );
    } else if (showLargeImageOnly) {
      content = <DesktopHeader {...commonProps} />;
    } else {
      content = <CheckoutPreview {...desktopProps} />;
    }
  } else {
    // Else: present normally standard with media queries
    const { disabledOrSplitLayout, enabledSingleLayout } = getEmbedState(embed);

    content = (
      <div id="pageCheckoutPreview--desktop" className="w-100">
        <div
          className={classnames("flex flex-column justify-center w-100", {
            "dib dn-l justify-start-m": disabledOrSplitLayout,
            "dib": enabledSingleLayout
          })}
        >
          <MobileHeader
            {...commonProps}
            multipleLines={multipleLines}
            hasAdditionalInfo={hasAdditionalInfo}
          >
            {children}
          </MobileHeader>
        </div>
        <div
          className={classnames("w-100", {
            "dn dib-l": disabledOrSplitLayout,
            "dn": enabledSingleLayout
          })}
        >
          {heroPresentation ? (
            <DesktopHeader {...commonProps} />
          ) : (
            <CheckoutPreview {...desktopProps} />
          )}
        </div>
      </div>
    );
  }

  return content;
};

export default PageCheckoutPreview;
