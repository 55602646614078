import React from "react";

const EmptyProductCollection = ({ count }) => {
  return (
    <div className="grid-container-ns center ph3">
      {new Array(count).fill("").map((_, index) => (
        <div className="w-100 flex flex-column" key={index}>
          <div className="br--top br3 hairline-1 ba pa3 flex flex-column w-100 items-start">
            <div className="w3 h2 bg-black-05"></div>
            <div className="w-70 h1 bg-black-05 mt2"></div>
          </div>
          <div className="w-100 h4 bg-black-05"></div>
          <div className="br--bottom br3 hairline-1 ba pa3 flex flex-row w-100 items-start justify-between">
            <div className="w-20 h2 bg-black-05"></div>
            <div className="w-30 h2 bg-black-05"></div>
          </div>
        </div>
      ))}
      <style jsx>{`
        @media (max-width: 30em) {
          .grid-container {
            display: flex;
            flex-direction: column;
          }
        }
        @media (min-width: 30em) {
          .grid-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(1, 1fr);
            gap: 16px 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default EmptyProductCollection;
