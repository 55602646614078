import React from "react";
import {
  FORMER_MAX_WIDTH,
  getLeftSplitStyles,
  SPLIT_CONTENT_MAX_WIDTH
} from "utils/constants/ui";
import { PRODUCT_COLLECTION_INTENT } from "utils/constants/pricing";
import { useCommonEditorEntityParams } from "utils/hooks/editor";
import AddProductCTA from "./AddProductCTA";
import { getPageSplitContainerClasses } from "utils/ui";

const EmptySplitLayout = ({ name, entity, count }) => {
  const menuIntent = PRODUCT_COLLECTION_INTENT.PRODUCT_ORDER;

  const {
    canActivateSettings,
    editorViewport,
    hasNavPrev,
    formIsFullScreen,
    hasBrandingAssets,
    showInitialPreview,
    embed
  } = useCommonEditorEntityParams({
    entity,
    name,
    menuIntent
  });

  const compClasses = getPageSplitContainerClasses({
    embed,
    formIsFullScreen,
    hasBrandingAssets,
    canActivateSettings,
    hasNavPrev,
    viewport: editorViewport
  });

  return (
    <div
      className={compClasses.container}
      style={canActivateSettings ? { maxWidth: FORMER_MAX_WIDTH } : {}}
    >
      <div
        style={canActivateSettings ? {} : { zIndex: -1 }}
        className={compClasses.background}
      >
        <div className="w-50 bg-off-white-ns"></div>
        <div className="w-50 split__container-ns bg-white"></div>
      </div>
      <div
        style={{ maxWidth: SPLIT_CONTENT_MAX_WIDTH }}
        className={compClasses.content}
      >
        <div
          style={getLeftSplitStyles({
            hasBrandingAssets,
            showInitialPreview
          })}
          className={compClasses.left}
        >
          <div className="w-100 pb5">
            <div className="w3 h2 bg-black-05"></div>
            <div className="w-70 h1 bg-black-05 mt2"></div>
            <div className="w-70 h1 bg-black-05 mt2"></div>
            <div className="w-70 h1 bg-black-05 mt2"></div>
          </div>
        </div>
        <div className={compClasses.right}>
          <AddProductCTA name={name} entity={entity} />
          {new Array(count).fill("").map((_, index) => (
            <div className="w-100 flex flex-column pt3" key={index}>
              <div className="br--top br3 hairline-1 ba pa3 flex flex-column w-100 items-start">
                <div className="w3 h2 bg-black-05"></div>
                <div className="w-70 h1 bg-black-05 mt2"></div>
              </div>
              <div className="w-100 h4 bg-black-05"></div>
              <div className="br--bottom br3 hairline-1 ba pa3 flex flex-row w-100 items-start justify-between">
                <div className="w-20 h2 bg-black-05"></div>
                <div className="w-30 h2 bg-black-05"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmptySplitLayout;
