import StandardImage from "components/StandardImage";
import React from "react";
import { defaultHttps } from "utils/url";

const ProfileAvatar = ({ src, url, height, width, imageClass }) => {
  const ctxImageClass = imageClass || "br-100";
  const srcImage = (
    <div className="center" style={{ width }}>
      <StandardImage src={src} className={ctxImageClass} style={{ height }} />
    </div>
  );

  const hasLinkWrapper = Boolean(url);

  return hasLinkWrapper ? <a href={defaultHttps(url)}>{srcImage}</a> : srcImage;
};

export default ProfileAvatar;
