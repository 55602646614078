import React, { useState, useEffect } from "react";
import TinSelect from "components/TinSelect";
import TinValue from "components/TinValue";
import { useFormikContext } from "formik";
import { validateIdentityTin } from "utils/identity";
import LoadingSpinner from "components/LoadingSpinner";
import get from "lodash/get";
import { getFieldNameRoot, getFieldPath } from "utils/form";
import { getIconForUID } from "components/FormerEditor/utils";
import { COLORS } from "utils/styles";
import InfoNotice from "components/InfoNotice";
import Label from "components/Form/fields/Label";
import Description from "components/Form/fields/Description";
import classnames from "classnames";

const CheckmarkIcon = getIconForUID("checkmarkCircle");

const TinFields = (props) => {
  const [validating, setValidating] = useState(false);
  const [guidance, setGuidance] = useState("");
  const name = props.name;
  const label = props.label;
  const description = props.description;
  const edit = props.edit;
  const noValidation = Boolean(props.noValidation);

  const nameRoot = getFieldNameRoot(name);

  const {
    values,
    errors,
    touched,
    setFieldError,
    setFieldValue
  } = useFormikContext();
  const isTouched = get(touched, name);

  const fieldValue = get(values, name, {});
  const valuePath = getFieldPath(name, "value");
  const codePath = getFieldPath(name, "code");

  const code = fieldValue.code;
  const value = fieldValue.value;
  const type = fieldValue.type;

  const hasValues = code && value && type;
  const ctxError =
    props.error ||
    get(errors, name) ||
    get(errors, codePath) ||
    get(errors, valuePath);
  const hasErrors = Boolean(ctxError);
  const showError = Boolean(isTouched && hasErrors && !validating);

  useEffect(() => {
    async function validate() {
      if (code && value && type && !validating) {
        const data = {
          code,
          value,
          type
        };
        if (values.shortId) {
          /**
           * Optional: pass subdomain / alias as a reference for ownership
           * - if full validation is a paid feature
           */
          data.shortId = values.shortId;
        }
        const validation = await validateIdentityTin({
          data,
          setLoading: setValidating,
          onError: (err) => {
            if (err) {
              setFieldError(props.name, err);
            }
          }
        });

        if (!validation.isValid) {
          setFieldError(valuePath, validation.message);
        } else {
          setFieldValue(valuePath, validation.value);
        }
        if (validation.guidance) {
          setGuidance(validation.guidance);
        } else {
          setGuidance(null);
        }
      }
    }

    /**
     * Simulate validation request on change when validation is suppressed
     */
    if (noValidation) {
      setValidating(true);
      setTimeout(() => {
        setValidating(false);
      }, 250);
    } else {
      validate();
    }
  }, [code, value, type]);

  let statusNode;
  if (validating) {
    statusNode = (
      <LoadingSpinner
        small={16}
        customClasses={{ ring: "blue", size: "small" }}
      />
    );
  } else {
    const statusColor =
      hasValues && !hasErrors ? COLORS.blue : COLORS.secondary;

    statusNode = <CheckmarkIcon color={statusColor} size={16} />;
  }
  const errorLabel = typeof ctxError === "string" ? ctxError : null;

  const showLabel = Boolean(label || edit);
  const showDescription = Boolean(description || edit);

  return (
    <div className="w-100">
      <div
        className={classnames("flex flex-row justify-between", {
          mb1: !showDescription
        })}
        style={{
          fontSize: 13,
          marginBottom: 4
        }}
      >
        {showLabel && (
          <Label
            edit={edit}
            customClasses="fw5 db"
            name={`${nameRoot}.label`}
            label={label}
            error={showError}
            required={props.required}
          />
        )}
        <div className="oveflow-hidden" style={{ height: 16 }}>
          {statusNode}
        </div>
      </div>
      {showDescription && (
        <Description
          edit={edit}
          name={`${nameRoot}.description`}
          copy={description}
        />
      )}
      <TinSelect {...props} />
      <TinValue {...props} />
      {guidance && (
        <div className="pt2">
          <InfoNotice key="guidance" copy={guidance} />
        </div>
      )}
      {showError && errorLabel && (
        <div className="f7 pt1 color-error">{errorLabel}</div>
      )}
    </div>
  );
};

export default TinFields;
