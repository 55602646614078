import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { SCHEMA_CATEGORY, SCHEMA_COMPONENT } from "utils/constants/schema";
import { getFieldPath } from "./form";
import {
  schemaPropertiesToValues,
  ensureUUID
} from "components/FormerEditor/common";
import { getUUID } from "./uuid";

export const getSchemaForField = (schema, key) => {
  const props = get(schema, key);
  return props && props.type === "array" ? props.items.properties : props;
};

export const getSchemaDependenciesForField = (schema, key) => {
  const props = get(schema, key);
  return props && props.type === "array" ? props.items.dependencies : props;
};

export const isActionSchema = (schema) => Boolean(schema.action_uid);

export const isDefaultCategory = (schema) =>
  schema.category === SCHEMA_CATEGORY.DEFAULT;

export const isVirtualCategory = (schema) =>
  schema.category === SCHEMA_CATEGORY.VIRTUAL;

export const isHiddenCategory = (schema) =>
  schema.category === SCHEMA_CATEGORY.HIDDEN;

export const isTemplateComponent = (schema) =>
  schema.component === SCHEMA_COMPONENT.TEMPLATE_FIELD;

export const isPickerComponent = (schema) =>
  schema.component === SCHEMA_COMPONENT.PICKER;

export const isSelectComponent = (schema) =>
  schema.component === SCHEMA_COMPONENT.SELECT;

export const prepareSchemaImageFromAttachment = (image, attachment) => {
  const updatedImage = cloneDeep(image);

  updatedImage.src = attachment.data.url;
  updatedImage.attachmentUuid = attachment.uuid;

  return updatedImage;
};

export const getSchemaValuesForEditor = ({ name, propertyKey, schema }) => {
  const rootPath = `${name}.data`;
  const fieldPath = getFieldPath(rootPath, propertyKey);
  const propSchema =
    schema && schema.properties && schema.properties[propertyKey];
  const properties =
    propSchema && propSchema.items && propSchema.items.properties;
  const dependencies =
    propSchema && propSchema.items && propSchema.items.dependencies;

  const result = {
    rootPath,
    fieldPath,
    propSchema,
    properties
  };
  if (dependencies) {
    result.dependencies = dependencies;
  }
  if (properties) {
    const template = ensureUUID(schemaPropertiesToValues(properties));
    result.template = template;
    if (!result.template.uuid) {
      result.template.uuid = getUUID();
    }
  }

  return result;
};
