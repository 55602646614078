import React, { Fragment } from "react";
import { getGridClass } from "../utils";

const EmptyPricingTablePrompt = ({ count }) => {
  return new Array(count).fill("").map((_, index) => (
    <Fragment key={index}>
      <div className={`pv3 ph2 ${getGridClass({ suffix: `title--${index}` })}`}>
        <div className="h3 w-40 bg-black-05 center"></div>
      </div>
      <div className={`pv2 ph2 ${getGridClass({ suffix: `desc--${index}` })}`}>
        <div className="h2 w3 w-50 bg-black-05 pt2 center"></div>
      </div>
      <div
        className={`pt3 pb2 ph2 ${getGridClass({ suffix: `price--${index}` })}`}
      >
        <div className="h2 w3 w-80 bg-black-05 center"></div>
      </div>
      <div
        className={`pv3 ph2 ${getGridClass({
          suffix: `price_details--${index}`
        })}`}
      >
        <div className="h2 w3 w-90 bg-black-05 center"></div>
        <div className="h2 w3 w-80 mv2 bg-black-05 center"></div>
      </div>
      <div
        className={`ph2 ${getGridClass({ suffix: `price_actions--${index}` })}`}
      >
        <div style={{ height: 40 }} className="w3 br2 center bg-black-05"></div>
      </div>
      <div
        className={`pb3 pt4 ph2 f5 fw5 lh-title center w-100 ${getGridClass({
          suffix: `topFeatures_title--${index}`
        })}`}
      >
        <div className="h2 w-80 bg-black-05 ph2"></div>
      </div>
      <div
        className={`pb3 ph2 center w-100 ${getGridClass({
          suffix: `topFeatures--${index}`
        })}`}
      >
        <div className="w-100 relative">
          <ul className="list ma0 pa0">
            {new Array(5).fill("").map((_, listItem) => (
              <li
                className={"flex flex-row items-center justify-between mb3"}
                key={listItem}
              >
                <div className="h1 w2 bg-black-05"></div>
                <div className="h1 w-90 ml2 bg-black-05" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Fragment>
  ));
};

export default EmptyPricingTablePrompt;
