import React from "react";
import ProductSection from "./ProductSection";
import { FORMER_MAX_WIDTH } from "utils/constants/ui";
import { CONFIG_PRICING_EMPHASIS_INDEX } from "utils/constants/state";
import get from "lodash/get";
import { getFieldPath } from "utils/form";
import { useFormikContext } from "formik";
import classnames from "classnames";
import { usePageFactoryContext } from "utils/context";
import EmptyPricingTablePrompt from "./EmptyPricingTablePrompt";
import ReflectFocus from "components/ReflectFocus";
import PricingTableHeader from "./PricingTableHeader";
import {
  resetFocusBlockRoot,
  setAddProductOrderFocus
} from "utils/hooks/focus";
import Button from "components/Form/fields/Button";
import { getPricingTableStyle } from "constants/styles/pricingTable";
import { usePageElementNameRoot } from "utils/hooks/manifest";
const EMPTY_SECTION_COUNT = 3;

const FormerPricingTable = ({
  afterChange,
  entity,
  data,
  ui,
  name,
  entityRef
}) => {
  const productCount = data.products.length;
  const nameRoot = usePageElementNameRoot(name);

  const hasProducts = productCount > 0;
  const areaCount = hasProducts ? productCount : EMPTY_SECTION_COUNT;
  const { styles: pricingTableStyles } = getPricingTableStyle(areaCount);
  const { canActivateSettings, stateKey } = usePageFactoryContext();

  const { values } = useFormikContext();
  const rootPath = getFieldPath(stateKey, CONFIG_PRICING_EMPHASIS_INDEX);
  const emphasisIndex = get(values, rootPath);

  let content = null;
  let header;
  if (hasProducts) {
    header = (
      <PricingTableHeader
        name={name}
        entity={entity}
        afterChange={afterChange}
      />
    );

    content = data.products.map((product, productIx) => {
      const sectionProps = {
        key: productIx,
        name: name,
        productIndex: productIx,
        productCount,
        isFirst: !productIx,
        isLast: productIx === areaCount - 1,
        ...product
      };
      if (Number.isInteger(emphasisIndex)) {
        sectionProps.isEmphasized = emphasisIndex === productIx;
      }

      return <ProductSection {...sectionProps} />;
    });
  } else if (canActivateSettings) {
    header = (
      <div className="absolute left-0 right-0 z-1">
        <div className="pt5 tc">
          <ReflectFocus
            focus={setAddProductOrderFocus(name)}
            classes="dib pa3 bg-white br2 ba hairline-1"
          >
            {({ onClick, classes } = {}) => (
              <div onClick={onClick} className={classes}>
                <div className="f3 fw5">No products added</div>
                <div className="pt3 tc w-100 flex justify-center">
                  <Button theme="slim" copy="Add products" onClick={() => {}} />
                </div>
              </div>
            )}
          </ReflectFocus>
        </div>
      </div>
    );

    content = <EmptyPricingTablePrompt count={EMPTY_SECTION_COUNT} />;
  }

  return (
    <ReflectFocus
      name={entity.schema.title}
      focus={resetFocusBlockRoot(name)}
      withHeader
    >
      {({ hover } = {}) => (
        <div
          id={nameRoot}
          className={classnames("w-100 center ph0 ph3-ns", {
            "bg-white": !hover
          })}
          style={{ maxWidth: FORMER_MAX_WIDTH }}
          ref={entityRef}
        >
          <div className="pb3 pb4-ns">{header}</div>
          <div
            className={classnames("w-100 relative center", {
              "bg-white": !hover
            })}
          >
            <div
              className={classnames("pricingTable-s pricingTable-ns center", {
                "bt hairline-1": hasProducts,
                ["w-third-l w-100"]: areaCount === 1,
                ["w-two-thirds-l w-100"]: areaCount === 2,
                ["w-100"]: areaCount === 0 || areaCount >= 3
              })}
            >
              {content}
            </div>
            {pricingTableStyles}
          </div>
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormerPricingTable;
