import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import classnames from "classnames";
import merge from "lodash/merge";

const DEFAULT_CLASSES = {
  container: "ma0 pa0 list w-100",
  item: "flex flex-row items-center w-100",
  icon: "flex flex-shrink-0 self-start",
  copy: "f6 pl1 lh-copy",
  spacer: "pb2 mb1"
};

const DEFAULT_STYLES = {
  icon: { paddingTop: 3 },
  copy: { paddingTop: 2 }
};

const DEFAULT_ICON_SIZE = 18;

const IconList = ({
  list,
  customClasses,
  customStyles,
  size = DEFAULT_ICON_SIZE
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const styles = merge(DEFAULT_STYLES, customStyles);

  return (
    <ul className={classes.container}>
      {list.map(({ icon, copy }, itemIx) => {
        const Icon = getIconForUID(icon);
        const isLast = list.length - 1 === itemIx;
        return (
          <li
            key={itemIx}
            className={classnames(classes.item, {
              [classes.spacer]: !isLast
            })}
          >
            <div
              className={classes.icon}
              style={{ ...styles.copy, width: size }}
            >
              <Icon size={size} />
            </div>
            <div style={styles.copy} className={classes.copy}>
              {copy}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
export default IconList;
