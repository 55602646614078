import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import { COLORS } from "utils/styles";

const ProductFeatureGroupRow = ({ productUID, rows, header }) => {
  const Icon = getIconForUID("checkmark");

  return (
    <div className="w-100 flex-column">
      <div className="bl bb hairline-1 w-100">
        <div className="pa3 fw5 f3">{header.header}</div>
      </div>
      <div className="w-100">
        {rows.map((row, rowIx) => {
          const config = row[productUID];
          const content = config
            ? config.enabled
              ? config.value || <Icon size={24} color={COLORS.blue} />
              : ""
            : "";
          return (
            <div className="w-100 flex flex-row bb hairline-1" key={rowIx}>
              <div className="w-70 br pa3 hairline-1">
                <div className="f5 dib user-select-n word-break-all lh-copy">
                  {row.title.value}
                </div>
              </div>
              <div className="w-30 fl flex items-center justify-center flex-shrink-0">
                <div className="f5 dib lh-title">{content}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductFeatureGroupRow;
