import React, { useState, useEffect, Fragment } from "react";
import { useCurrentManifestLiveVariation } from "utils/hooks";
import { getFieldNameRoot, getFieldPath } from "utils/form";
import get from "lodash/get";
import { useFormikContext } from "formik";
import { ADMIN, STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import FormerIdentitySessionAction from "components/FormerIdentitySessionAction";
import { usePageFactoryContext } from "utils/context";
import { PAGE_ACTION_UIDS } from "components/FormerEditor/common/constants";
import { getErrorMessage } from "utils/error";
import {
  fetchIdentityVerificationStatus,
  cancelIdentityVerificationStatus,
  getStoreIdentityField,
  clearStoreIdentityField
} from "utils/identity";
import ActionRowLoader from "components/ActionRowLoader";
import Pill from "components/Pill";
import { formatUTCTime } from "utils/date";
import {
  DEFAULT_CHECKOUT_ACTION_CLASSES,
  STATUS,
  UI_THEME,
  ERROR_PILL,
  BUTTON_CLASS_CONTEXT
} from "utils/constants/ui";
import isError from "lodash/isError";
import startCase from "lodash/startCase";
import isEmpty from "lodash/isEmpty";
import {
  IDENTITY_LABEL_MAP,
  REDACTION_STATUS,
  VERIFICATION_STATUS,
  VERIFY_IDENTITY_ERROR_CODE,
  VERIFY_IDENTITY_ERROR_TYPE,
  IDENTITY_DOCUMENT_TYPE,
  IDENTITY_VERIFICATION_TYPE
} from "utils/constants/identity";
import { getUUID } from "utils/uuid";
import useInterval from "utils/useInterval";
import IconList from "components/IconList";
import { useToasts } from "react-toast-notifications";
import FormattedToast from "components/FormattedToast";
import InfoNotice from "components/InfoNotice";
import IdentityCard from "./IdentityCard";
import classnames from "classnames";
import Button from "components/Form/fields/Button";
import ConfirmButton from "components/ConfirmButton";
import { useIsScratch } from "utils/hooks/route";
import { X_API_KEY } from "utils/constants/header";

const THIRTY_SECONDS = 30000 / 2;

const getStatusCreatedTime = (status) =>
  status && status.created && formatUTCTime(status.created * 1000);

const getUserActionError = (error) =>
  Boolean(
    error &&
      error.type &&
      error.type === VERIFY_IDENTITY_ERROR_TYPE.USER_ACTION &&
      error.code
  );

const NOTIFICATION_MESSAGES = {
  VERIFICATION_CANCELED: "Identity verification session canceled.",
  CONSENT_REQUIRED: "Consent is required for identity verification."
};

const INPUT_PILLS = {
  ID_NUMBER: {
    icon: "checkmark",
    copy: "Collect ID number"
  },
  LIVE_IMAGE: {
    icon: "checkmark",
    copy: "Live image capture"
  },
  SELFIE: {
    icon: "checkmark",
    copy: "Matching selfie"
  }
};

const CONFIRM_SLIDES = {
  RESET: [
    {
      title: "Reset verification",
      header: "Are you sure you want to reset this verification?"
    }
  ],
  REMOVE: [
    {
      title: "Remove verification",
      header: "Are you sure you want to remove this verification?"
    }
  ]
};

const IDENTITY_CARD_KEYS = [
  "children",
  "footer",
  "icon",
  "title",
  "description",
  "pill"
];

const getInitialContent = ({ checkoutProps, ...props }) => {
  let initialState = true;
  const fieldType = get(props, STATE_KEYS.IDENTITY.TYPE);
  const isDocument = fieldType === IDENTITY_VERIFICATION_TYPE.DOCUMENT;
  const icon = fieldType;
  const title = get(props, STATE_KEYS.IDENTITY.TITLE);
  const description = get(props, STATE_KEYS.IDENTITY.PROMPT);

  let additionalRequirements;
  let allowedDocuments;
  let allowedTypes;
  if (isDocument) {
    let list = [];
    allowedTypes = get(props, STATE_KEYS.IDENTITY.ALLOWED_TYPES) || [];
    /**
     * When none are specified then all are allowed
     */
    if (allowedTypes.length === 0) {
      allowedTypes = Object.values(IDENTITY_DOCUMENT_TYPE).map((uid) => ({
        uid
      }));
    }
    const requireIdNumber = get(
      props,
      STATE_KEYS.IDENTITY.REQUIRE_ID_NUMBER,
      false
    );
    const requireLiveCapture = get(
      props,
      STATE_KEYS.IDENTITY.REQUIRE_LIVE_CAPTURE,
      false
    );
    const requireMatchingSelfie = get(
      props,
      STATE_KEYS.IDENTITY.REQUIRE_MATCHING_SELFIE,
      false
    );
    if (requireIdNumber) {
      list.push(INPUT_PILLS.ID_NUMBER);
    }
    if (requireLiveCapture) {
      list.push(INPUT_PILLS.LIVE_IMAGE);
    }
    if (requireMatchingSelfie) {
      list.push(INPUT_PILLS.SELFIE);
    }
    if (list.length > 0) {
      additionalRequirements = (
        <div className="pt2 mt3 hairline-1 bt pb1">
          <div className="f7 dib fw6 pb1">Additional requirements</div>
          <IconList
            size={16}
            list={list}
            customClasses={{ spacer: "pb2", copy: "f7 pl1 lh-title" }}
          />
        </div>
      );
    }
  } else {
    allowedTypes = [
      {
        uid: IDENTITY_DOCUMENT_TYPE.ID_CARD
      }
    ];
  }

  if (allowedTypes.length > 0) {
    allowedDocuments = (
      <div className="flex flex-wrap">
        {allowedTypes.map(({ uid }, typeIx) => (
          <Pill
            key={uid}
            theme={UI_THEME.SLIM}
            copy={IDENTITY_LABEL_MAP[uid]}
            customClasses={{
              container: classnames("db pt2", {
                pl1: typeIx
              })
            }}
          />
        ))}
      </div>
    );
  }

  const children = (
    <div className="flex flex-column">
      {allowedDocuments}
      {additionalRequirements}
    </div>
  );
  const footer = (
    <div className="dib">
      <FormerIdentitySessionAction {...checkoutProps} />
    </div>
  );

  return {
    initialState,
    children,
    footer,
    icon,
    title,
    description
  };
};

const getStatusContent = ({ status, resetField, cancelVerification }) => {
  const ctxStatusError =
    get(status, "document.error") ||
    get(status, "id_number.error") ||
    get(status, "last_error");
  const ctxType = get(status, "document.type") || get(status, "type");
  const ctxStatus = get(status, "document.status") || get(status, "status");
  const doesRequireInput = ctxStatus === VERIFICATION_STATUS.REQUIRES_INPUT;
  const isVerified = ctxStatus === VERIFICATION_STATUS.VERIFIED;
  const isProcessing = ctxStatus === VERIFICATION_STATUS.PROCESSING;

  let title = startCase(ctxType);
  let description = null;

  let pill = { theme: UI_THEME.SLIM };

  if (ctxStatusError) {
    description = ctxStatusError.reason;
    pill = {
      status: STATUS.DANGER,
      copy: startCase("Error")
    };
  } else if (status.redaction) {
    const redactionStatus = status.redaction.status;
    pill = {
      status:
        redactionStatus === REDACTION_STATUS.PROCESSING
          ? STATUS.WARNING
          : STATUS.DANGER,
      copy: startCase(redactionStatus)
    };
  } else if (doesRequireInput) {
    pill = {
      status: STATUS.WARNING,
      copy: startCase(ctxStatus)
    };
  } else if (isVerified) {
    pill = {
      status: STATUS.SUCCESS,
      copy: startCase(ctxStatus)
    };
  } else if (isProcessing) {
    pill = {
      status: STATUS.DEFAULT,
      copy: startCase(ctxStatus)
    };
  }

  let confirmAction;
  if (doesRequireInput) {
    confirmAction = {
      copy: "Cancel",
      onConfirm: cancelVerification,
      slides: [
        {
          title: "Cancel verification",
          header: "Are you sure you want to cancel this verification?"
        }
      ]
    };
  } else {
    confirmAction = {
      copy: "Remove",
      onConfirm: resetField,
      slides: CONFIRM_SLIDES.REMOVE
    };
  }

  const hasConfirmation = !isEmpty(confirmAction);
  const icon = ctxType;
  const footer = (
    <div className="w-100 flex flex-row justify-between items-center">
      <div className="f7">{getStatusCreatedTime(status)}</div>
      {hasConfirmation ? (
        <div className="dib">
          <ConfirmButton
            {...confirmAction}
            classes={{
              contextClass: BUTTON_CLASS_CONTEXT.SECONDARY,
              button: DEFAULT_CHECKOUT_ACTION_CLASSES.button
            }}
          />
        </div>
      ) : null}
    </div>
  );

  return {
    icon,
    footer,
    title,
    description,
    pill
  };
};

const getUserActionErrorContent = ({ resetField, error }) => {
  const pill = ERROR_PILL;
  let title = "Error";
  let description = "There was an error with verification. Please try again.";
  let footer;

  if (error.code === VERIFY_IDENTITY_ERROR_CODE.SESSION_CANCELLED) {
    title = "Canceled";
    description =
      "The previous session was canceled. Retry when you are ready to verify your documents.";
    footer = (
      <div className="dib">
        <Button
          theme={UI_THEME.SLIM}
          copy="Retry"
          classes={{
            contextClass: BUTTON_CLASS_CONTEXT.SECONDARY,
            button: DEFAULT_CHECKOUT_ACTION_CLASSES.button
          }}
          onClick={() => resetField()}
        />
      </div>
    );
  } else if (error.code === VERIFY_IDENTITY_ERROR_CODE.CONSENT_DECLINED) {
    title = "Consent declined";
    description =
      "Consent is required to complete verification. Reset to try again.";
    footer = (
      <div className="dib">
        <Button
          theme={UI_THEME.SLIM}
          copy="Reset"
          classes={{
            contextClass: BUTTON_CLASS_CONTEXT.SECONDARY,
            button: DEFAULT_CHECKOUT_ACTION_CLASSES.button
          }}
          onClick={() => resetField()}
        />
      </div>
    );
  }

  return {
    pill,
    title,
    description,
    footer
  };
};

const getRequestErrorContent = ({
  status,
  encryptedSession,
  error,
  resetField,
  fetchStatus
}) => {
  const pill = ERROR_PILL;
  const title = "Error";
  const description = getErrorMessage(error);

  const footer = (
    <div className="w-100 flex flex-row justify-between items-center">
      <div className="f7">{getStatusCreatedTime(status)}</div>
      <div className="flex flex-row">
        {encryptedSession && (
          <div className="pr2">
            <Button
              theme={UI_THEME.SLIM}
              copy="Refresh"
              onClick={() => fetchStatus()}
            />
          </div>
        )}
        <div className="dib">
          <ConfirmButton
            copy="Reset"
            onConfirm={resetField}
            slides={CONFIRM_SLIDES.RESET}
            classes={{
              contextClass: BUTTON_CLASS_CONTEXT.SECONDARY,
              button: DEFAULT_CHECKOUT_ACTION_CLASSES.button
            }}
          />
        </div>
      </div>
    </div>
  );

  return {
    title,
    pill,
    description,
    footer
  };
};

const Identity = (props) => {
  const { name, validation, value, focus = [], index, uuid } = props;
  const encryptedSession = value;
  const nameRoot = getFieldNameRoot(name);

  const required = validation && validation.required;
  const { values, setFieldValue } = useFormikContext();
  const {
    canActivateSettings,
    stateKey,
    isUnauthedBuilder,
    isAuthedBuilder
  } = usePageFactoryContext();
  const liveVariation = useCurrentManifestLiveVariation();

  const isLive = canActivateSettings ? Boolean(liveVariation) : true;

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  const adminRootPath = getFieldPath(stateKey, ADMIN);
  const subdomain = get(values, `${adminRootPath}.subdomain`);
  const alias = get(values, `${adminRootPath}.alias`);

  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(false);
  const [error, setError] = useState(false);

  const initialSession = getStoreIdentityField(uuid);
  const [idempotencyKey, setIdempotencyKey] = useState(getUUID());

  const [status, setStatus] = useState(null);

  const headers = {
    [X_API_KEY]: encryptedSession
  };

  const fetchStatus = async (loadingHandler) => {
    setError(null);
    const verificationStatus = await fetchIdentityVerificationStatus({
      data: {
        subdomain,
        alias
      },
      headers,
      setLoading: loadingHandler || setLoading,
      onError: (err) => {
        if (err) {
          setError(err);
        }
      }
    });

    if (isError(verificationStatus)) {
      setError(verificationStatus);
    } else if (verificationStatus) {
      setStatus(verificationStatus);
    }
  };

  const setSession = (sessionVal) => {
    setFieldValue(name, sessionVal);
  };

  /**
   * If an initial session exists then sync it to form value when
   * - no value exists
   * - not in editor mode
   */
  const isScratch = useIsScratch();
  const shouldSync =
    initialSession &&
    !encryptedSession &&
    (isScratch || isUnauthedBuilder || !canActivateSettings);
  useEffect(() => {
    if (shouldSync) {
      setFieldValue(name, initialSession);
    }
  }, [shouldSync]);

  useEffect(() => {
    if (encryptedSession) {
      fetchStatus();
    }
  }, [encryptedSession]);

  const cancelVerification = async () => {
    setError(null);
    const canceledVerification = await cancelIdentityVerificationStatus({
      data: {
        subdomain,
        alias
      },
      headers,
      setLoading,
      onError: (err) => {
        if (err) {
          setError(err);
        }
      }
    });

    /**
     * TODO:
     * - status is now canceled - how does this look in the UI?
     * - does it need to present a resetField button?
     */
    if (isError(canceledVerification)) {
      setError(canceledVerification);
    } else if (canceledVerification) {
      setStatus(canceledVerification);
    }
  };

  const resetField = () => {
    clearStoreIdentityField(uuid);
    setIdempotencyKey(getUUID());
    setError(null);
    setStatus(null);
    setSession(null);
  };

  const checkoutProps = {
    action: {
      action_uid: PAGE_ACTION_UIDS.STRIPE_CHECKOUT,
      loading,
      theme: UI_THEME.SLIM,
      copy: "Start"
    },
    customClasses: {
      container: "relative center lh-copy w-100",
      nodes: "flex justify-center dib tc"
    },
    disabled: Boolean(props.disabled || isAuthedBuilder),
    isLive,
    name,
    onError: setError,
    onSuccess: setSession,
    /**
     * Pass through the necessary fields to perform the document verification
     */
    formData: {
      field: uuid,
      idempotencyKey
    }
  };

  const ctxStatus = get(status, "document.status") || get(status, "status");
  const isProcessing = ctxStatus === VERIFICATION_STATUS.PROCESSING;

  /**
   * Verification is likely async so when status is processing
   * we poll the status endpoint to check if its been verified
   */
  const shouldPoll = Boolean(isProcessing && encryptedSession);
  useInterval(
    () => {
      fetchStatus(setPolling);
    },
    shouldPoll ? THIRTY_SECONDS : null
  );

  const userActionError = getUserActionError(error);

  const sessionCancelled =
    userActionError &&
    error.code === VERIFY_IDENTITY_ERROR_CODE.SESSION_CANCELLED;
  const consentDeclined =
    userActionError &&
    error.code === VERIFY_IDENTITY_ERROR_CODE.CONSENT_DECLINED;
  const requestError = isError(error);

  useEffect(() => {
    if (userActionError && !error.notified) {
      let toastMessage;
      /**
       * session_canceled
       * - User has decided to close the verification session modal
       * - OTT client_secret has been consumed
       */
      if (sessionCancelled) {
        toastMessage = NOTIFICATION_MESSAGES.VERIFICATION_CANCELED;
        /**
         * consent_declined
         * - User has rejected consent to identity capture
         * - OTT client_secret has been consumed
         */
      } else if (consentDeclined) {
        toastMessage = NOTIFICATION_MESSAGES.CONSENT_REQUIRED;
      }

      if (toastMessage) {
        addToast(<FormattedToast type="error" copy={toastMessage} />, {
          appearance: "error",
          autoDismiss: true
        });
        setError({
          ...error,
          notified: true
        });
      }
    }
  }, [error, userActionError, sessionCancelled, consentDeclined]);

  /**
   * ========================
   * Errors
   * ========================
   * UserAction
   * - User cancels session - consequence: clear error
   * - User declines consent - consequence: reset field
   * Request
   * - Request fail - session status
   * - Request fail - session cancel
   * Status
   * - Verification status has an error
   */
  const header = get(props, STATE_KEYS.IDENTITY.HEADER);
  let initialState = false;
  let content = null;
  let identityCardProps = null;

  if (loading) {
    return <ActionRowLoader />;
  }

  if (userActionError) {
    content = getUserActionErrorContent({
      resetField,
      error
    });
  } else if (requestError) {
    content = getRequestErrorContent({
      status,
      error,
      encryptedSession,
      resetField,
      fetchStatus
    });
  } else if (status) {
    content = getStatusContent({
      status,
      resetField,
      cancelVerification
    });
  } else {
    content = getInitialContent({
      checkoutProps,
      ...props
    });
  }

  initialState = content.initialState;

  identityCardProps = {
    name,
    required,
    header,
    polling
  };

  IDENTITY_CARD_KEYS.forEach((contentKey) => {
    if (content[contentKey]) {
      identityCardProps[contentKey] = content[contentKey];
    }
  });

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => (
        <div
          id={nameRoot}
          onClick={onClick}
          className={`w-100 ${reflectClasses}`}
        >
          <Fragment>
            <IdentityCard
              edit={Boolean(edit && initialState)}
              {...identityCardProps}
            />
            {isAuthedBuilder ? (
              <div className="pv1">
                <InfoNotice
                  customClasses={{
                    copy: "pl2 f7 black-60"
                  }}
                  copy="Identity verification only enabled on live pages."
                />
              </div>
            ) : null}
          </Fragment>
        </div>
      )}
    </ReflectFocus>
  );
};

export default Identity;
