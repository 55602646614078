import React from "react";
import Button from "components/Form/fields/Button";
import { OPERATOR_ICON, THEMES } from "utils/constants/ui";
import { checkoutCart } from "utils/checkout/actions";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { usePageFactoryContext } from "utils/context";
import ReflectFocus from "components/ReflectFocus";
import HoverIconButton from "components/HoverIconButton";
import { useFormikContext } from "formik";
import { STATE_KEYS } from "utils/constants/state";
import { getFieldPath } from "utils/form";
import InfoNotice from "components/InfoNotice";
import InfoList from "components/InfoList";
import { DEFAULT_ACTIONS_CLASSES } from "utils/constants/ui";
import { useEntityCartValidationErrors } from "utils/hooks/cart";
import { useCheckoutActionProps } from "utils/hooks/checkout";

/**
 *
 * @param {Array} actions
 * @param {String} actions.uid
 * @param {String} actions.copy
 * @param {String} actions.uid
 * @param {String} actions.href
 * @param {String} actions.tag
 * @param {String} productIndex
 */
const FormerCartCheckoutAction = ({
  uuid,
  hideControls,
  action,
  disabled,
  focus,
  theme,
  name,
  cart,
  isLive,
  customClasses
}) => {
  const classes = { ...DEFAULT_ACTIONS_CLASSES, ...customClasses };
  const { values, setFieldValue } = useFormikContext();
  const { stateKey } = usePageFactoryContext();

  const contentRoot = getFieldPath(stateKey, `content.${name}`);

  const productsPath = `${contentRoot}.${STATE_KEYS.PRODUCT_COLLECTION.PRODUCTS}`;
  const products = get(values, productsPath) || [];
  const multipleProducts = products.length > 1;

  const cartProductsPath = `${contentRoot}.${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_CART_PRODUCTS}`;
  const cartProducts = get(values, cartProductsPath) || [];

  const productInCartIndex = cartProducts.indexOf(uuid);
  const productInCart = productInCartIndex > -1;
  const hasProductsInCart = cartProducts.length > 0;

  const validationErrors = useEntityCartValidationErrors({ name, cart });
  const hasValidationErrors = !isEmpty(validationErrors);
  const ctxDisabled = disabled || hasValidationErrors || !hasProductsInCart;

  const contextProps = {
    checkoutAction: checkoutCart,
    checkoutParams: { cart }
  };

  const {
    canActivateSettings,
    isSubmitting,
    notice,
    props
  } = useCheckoutActionProps(
    {
      name,
      theme,
      action,
      disabled: ctxDisabled,
      isLive,
      customClasses: {
        button:
          "fw6 br-6 ph3 pv2 border-box input-reset ba bg-transparent pointer f5 dib link items-center flex"
      }
    },
    contextProps
  );

  const controlsButtons = [];
  const buttonsBar = [];

  if (multipleProducts && uuid && !hideControls) {
    controlsButtons.push(
      <HoverIconButton
        key="operator"
        size={22}
        type={productInCart ? OPERATOR_ICON.SUBTRACT : OPERATOR_ICON.ADD}
        onClick={() => {
          if (productInCart) {
            cartProducts.splice(productInCartIndex, 1);
          } else {
            cartProducts.push(uuid);
          }
          setFieldValue(cartProductsPath, cartProducts);
        }}
      />
    );
  }
  if (controlsButtons.length > 0) {
    buttonsBar.push(
      <div
        key="control-buttons"
        className="flex flex-row br hairline-1 pr2 mr2"
      >
        {controlsButtons}
      </div>
    );
  }

  buttonsBar.push(<Button key="checkout" {...props} />);

  const canShowValidations =
    hasValidationErrors && canActivateSettings && !isSubmitting;

  return (
    <ReflectFocus focus={focus}>
      {({ onClick, classes: focusClasses } = {}) => (
        <div
          onClick={onClick}
          className={`${classes.container} ${focusClasses}`}
        >
          <div className={classes.nodes}>{buttonsBar}</div>
          {canShowValidations ? (
            <div className="pt3">
              <InfoList
                header="Error"
                items={validationErrors}
                theme={THEMES.ERROR}
              />
            </div>
          ) : null}
          {notice && (
            <div className="pt3">
              <InfoNotice
                customClasses={{ copy: "f7 lh-copy pl2" }}
                copy={notice}
              />
            </div>
          )}
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormerCartCheckoutAction;
