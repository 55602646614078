import React, { Fragment } from "react";
import classnames from "classnames";
import FeatureEntitlementsRow from "./FeatureEntitlementsRow";

const TableGroupRow = ({ index, columns, rows, thWidth, tdWidth }) => {
  const headProps = {
    className: classnames("tl", {
      "bl bb hairline-1": !index
    })
  };
  const headContentProps = {
    style: index
      ? {
          boxShadow: "0 0 0 1px rgb(233, 234, 236)"
        }
      : {},
    className: classnames("pa3 fw5 f3", {
      mt5: index
    })
  };

  return (
    <Fragment>
      <tr className="bn">
        <th {...headProps} colSpan={columns.length}>
          <div {...headContentProps}>{columns[0].header}</div>
        </th>
      </tr>
      {rows.map((row, rowIx) => (
        <FeatureEntitlementsRow
          key={rowIx}
          groupIndex={index}
          rowIndex={rowIx}
          row={row}
          thWidth={thWidth}
          tdWidth={tdWidth}
        />
      ))}
    </Fragment>
  );
};

export default TableGroupRow;
