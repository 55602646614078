import React from "react";
import { IoIosCheckmark } from "@hacknug/react-icons/io";
import classnames from "classnames";
import { BLOCKS_PREFIX } from "utils/constants/ui";
import Tooltip from "components/Tooltip";

const FeatureListItem = ({ active, title, tooltip, hideIcon, tooltipId }) => (
  <li className={"flex flex-row items-center mb3"}>
    {/* TODO: Icons need better bounding box without need for margins */}
    {!hideIcon && (
      <div style={{ marginLeft: -7, marginTop: 4 }}>
        <IoIosCheckmark
          size={28}
          className={
            active
              ? `${BLOCKS_PREFIX}feature__enabled--checkmark`
              : "BLOCKS__color-text--secondary o-50"
          }
        />
      </div>
    )}
    {/* User tooltip  */}
    <Tooltip
      tooltip={tooltip}
      id={tooltipId}
      customClasses={{
        tooltip: "bg-black-90 white dib"
      }}
    >
      <div
        className={classnames("f5 dib lh-title", {
          ["BLOCKS__color-text--primary"]: active,
          pl2: !hideIcon,
          ["BLOCKS__color-text--secondary o-50"]: !active
        })}
      >
        {title}
      </div>
    </Tooltip>
  </li>
);

export default FeatureListItem;
