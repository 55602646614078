import React from "react";
import { useFormikContext } from "formik";
import get from "lodash/get";
import InfoNotice from "components/InfoNotice";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import { getFieldNameRoot } from "utils/form";
import LabelDescription from "components/Form/fields/LabelDescription";

const FormDisclaimer = (props) => {
  const { name, label, description, focus = [], index } = props;
  const { values } = useFormikContext();
  const nameRoot = getFieldNameRoot(name);

  const value = get(values, name);
  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes, edit } = {}) => (
        <div
          id={nameRoot}
          className={`w-100 flex flex-column ${classes}`}
          onClick={onClick}
        >
          <LabelDescription
            nameRoot={nameRoot}
            edit={edit}
            label={label}
            description={description}
          />
          <InfoNotice
            edit={edit}
            name={name}
            copy={value}
            hideIcon
            customClasses={{
              copy: "f7 lh-copy word-break"
            }}
          />
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormDisclaimer;
