import React from "react";
import classnames from "classnames";
import { COLORS } from "utils/styles";

const StateOption = ({ innerProps, isDisabled, data, getStyles, ...props }) => {
  const optionStyles = getStyles("option", props);

  return (
    <div
      {...innerProps}
      style={{
        ...innerProps.style,
        backgroundColor: optionStyles.backgroundColor
      }}
      className={classnames(
        "standard--option flex flex-column flex-row-ns items-center-ns pa2 bg-white--hover bb hairline-1",
        {
          "cursor-not-allowed": isDisabled
        }
      )}
    >
      <div className="flex flex-row w-100">
        <div className="flex flex-row items-center justify-between w-100">
          <div
            className={classnames("f5 f6-ns pl2 w-100", {
              "color-text--secondary": isDisabled
            })}
          >
            {data.label}
          </div>
        </div>
      </div>
      <style jsx>{`
        .standard--option {
          height: 40px;
        }
        .standard--option:hover {
          cursor: pointer;
          background-color: ${COLORS.whitesmoke};
        }
        .standard--option:focus {
          cursor: pointer;
          background-color: ${COLORS.whitesmoke};
        }
      `}</style>
    </div>
  );
};

export default StateOption;
