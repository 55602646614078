import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ProductHead from "../ProductHead";
import FeaturesList from "../FeaturesList";
import { STATE_KEYS } from "utils/constants/state";
import { ProductFeatureType, ProductActionType } from "utils/propTypes";
import { PRICING_TABLE_INTENT } from "utils/constants/pricing";

const ProductSection = ({
  uid,
  name,
  title,
  description,
  formattedPrice,
  subtext,
  features,
  actions,
  productIndex,
  isEmphasized,
  isFirst,
  isLast,
  productCount
}) => {
  const productFocus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    },
    {
      key: STATE_KEYS.EDITOR.PRICING_ACTIVE_PRODUCT,
      value: uid
    },
    {
      key: STATE_KEYS.EDITOR.PRICING_INTENT,
      value: PRICING_TABLE_INTENT.PRODUCT_ORDER
    }
  ];

  const featuresFocus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    },
    {
      key: STATE_KEYS.EDITOR.PRICING_ACTIVE_PRODUCT,
      value: null
    },
    {
      key: STATE_KEYS.EDITOR.PRICING_INTENT,
      value: PRICING_TABLE_INTENT.FEATURE_LIST
    }
  ];

  const commonProps = {
    uid: uid,
    name: name,
    productIndex: productIndex,
    isFirst,
    isLast,
    isEmphasized,
    productCount
  };

  return (
    <Fragment>
      <ProductHead
        {...commonProps}
        focus={productFocus}
        title={title}
        description={description}
        formattedPrice={formattedPrice}
        actions={actions}
        subtext={subtext}
      />
      {!!features && (
        <FeaturesList {...commonProps} {...features} focus={featuresFocus} />
      )}
    </Fragment>
  );
};

ProductSection.propTypes = {
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  formattedPrice: PropTypes.string.isRequired,
  subtext: PropTypes.array,
  actions: PropTypes.arrayOf(PropTypes.shape(ProductActionType)).isRequired,
  productIndex: PropTypes.number.isRequired,
  features: PropTypes.shape(ProductFeatureType),
  isLast: PropTypes.bool.isRequired
};

export default ProductSection;
