import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import DebounceInput from "components/DebounceInput";
import { useFieldNameRoot } from "utils/hooks/form";
import { useFormikContext } from "formik";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import upperFirst from "lodash/upperFirst";
import { DEFAULT_DEBOUNCE, SET_TOUCH_TIMEOUT } from "utils/constants/form";
import { useIsMounted } from "utils/hooks";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import MultiSelect from "components/Form/fields/MultiSelect";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import { getConfig } from "utils/env";
import {
  useCountrySelections,
  useStateNameMap,
  useStateSelections
} from "utils/selectors";
import {
  addressToSelectOption,
  getAddressFieldProps,
  getAddressRequiredStatus,
  googlePlaceToAddress
} from "utils/address";
import { captureException } from "@sentry/minimal";
import { ADDRESS_INPUT_TYPE, ADDRESS_KEYS } from "utils/constants/address";
import { INPUT_COMMON_CLASS } from "utils/constants/ui";
import { usePageFactoryContext } from "utils/context";
import InfoNotice from "components/InfoNotice";
import LabelDescription from "components/Form/fields/LabelDescription";
import { getMultiselectProps } from "utils/constants/multiselect";
import CountryOption from "./CountryOption";
import StandardOption from "./StandardOption";
import PlaceOption from "./PlaceOption";
import SelectMenu from "./SelectMenu";
import { useHandleAddressCountryChange } from "utils/hooks/address";

// Dimensions 36:288

const RECEIVER_NAME = "receiver.name";
const US_COUNTRY_CODE = "US";

const Address = (props) => {
  const { name, label, description, validation, focus = [], index } = props;
  const required = validation && validation.required;
  const nameRoot = useFieldNameRoot(name);
  const isMounted = useIsMounted();
  const { canActivateSettings } = usePageFactoryContext();

  const autocompleteRef = useRef(null);
  const selectRef = useRef(null);
  const [autocompleteFocus, setAutocompleteFocus] = useState(false);
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    setTouched
  } = useFormikContext();

  const disabled = Boolean(props.disabled);

  const placeholder = get(values, `${nameRoot}.placeholder`, "");

  const addressValue = get(values, name);
  const value = addressValue.servicePlaceId ? addressValue : null;

  const { error: showError } = getAddressRequiredStatus({
    name,
    errors,
    touched
  });

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];
  /**
   * When autocompleteFocus is true
   * - Use the select value which includes the full formatted address as a better prompt value
   */
  const selectValue =
    value && value.address
      ? addressToSelectOption(value, autocompleteFocus)
      : null;

  const receiverEnabled = get(
    values,
    `${nameRoot}.${STATE_KEYS.ADDRESS.RECEIVER_ENABLED}`
  );
  const receiverPlaceholder = get(
    values,
    `${nameRoot}.${STATE_KEYS.ADDRESS.RECEIVER_PLACEHOLDER}`
  );
  const addressType = get(values, `${nameRoot}.${STATE_KEYS.ADDRESS.TYPE}`);

  const countryPath = `${name}.address.country`;
  const country = get(values, countryPath);
  const countryTouched = get(touched, countryPath);
  const statePath = `${name}.address.state`;
  const stateTouched = get(touched, statePath);
  const state = get(values, statePath);
  const isUSA = country === US_COUNTRY_CODE;

  const {
    options: countryOptions,
    selections: countrySelections
  } = useCountrySelections(country ? [country] : null);
  const {
    options: stateOptions,
    selections: stateSelections
  } = useStateSelections(state ? [state] : null);
  useHandleAddressCountryChange({ name });

  const countrySelectProps = {
    value: countrySelections,
    placeholder: "Country",
    options: countryOptions,
    isMulti: false,
    disabled,
    customStylesClasses: {
      control: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }
    },
    onChange: () => ({ value }) => {
      setFieldValue(countryPath, value);
      if (!countryTouched) {
        setFieldTouched(countryPath, true);
      }
    },
    labelTransform: (value) => value,
    components: {
      Option: CountryOption
    }
  };

  const stateSelectProps = {
    value: stateSelections,
    placeholder: "State",
    options: stateOptions,
    isMulti: false,
    disabled,
    customStylesClasses: {
      control: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }
    },
    onChange: () => ({ value }) => {
      setFieldValue(statePath, value);
      if (!stateTouched) {
        setFieldTouched(statePath, true);
      }
    },
    labelTransform: (value) => value,
    components: {
      Option: StandardOption
    }
  };

  const refreshSessionToken = () => {
    if (autocompleteRef && autocompleteRef.current) {
      autocompleteRef.current.refreshSessionToken();
    }
  };

  /**
   *
   * @param {Object} prediction
   * @param {String} prediction.label
   * @param {Object} prediction.value
   * @param {Object} prediction.value.description
   * @param {Object} prediction.value.place_id
   * @param {Object} prediction.value.reference
   */
  const onChange = async (prediction) => {
    try {
      /**
       * We end up making two separate calls
       * - getPredictions
       * - geocodeByPlaceId
       * Instead of one compound, because we dont have a map component
       * - getPredictions & getPlaceDetails
       */
      const results = await geocodeByPlaceId(prediction.value.place_id);
      const convertedAddress = googlePlaceToAddress(results[0]);
      const updatedValues = cloneDeep(addressValue);

      const updatedAddress = {
        ...updatedValues,
        ...convertedAddress
      };

      setFieldValue(name, updatedAddress);

      /**
       * Prepare the touch values
       */
      const updatedTouched = cloneDeep(touched);
      for (const key in ADDRESS_KEYS) {
        const addressKey = ADDRESS_KEYS[key];
        if (addressKey && updatedAddress.address[addressKey]) {
          set(updatedTouched, `${name}.address.${addressKey}`, true);
        }
      }

      setTimeout(() => {
        setTouched(updatedTouched);
      }, SET_TOUCH_TIMEOUT);
    } catch (error) {
      console.error(error.message);
      captureException(error);
    }

    refreshSessionToken();
  };

  const autocompleteSelectStyles = {
    customStylesClasses: {
      control: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    }
  };
  if (receiverEnabled) {
    autocompleteSelectStyles.customStylesClasses.control.borderTopRightRadius = 0;
    autocompleteSelectStyles.customStylesClasses.control.borderTopLeftRadius = 0;
  }

  const autocompleteSelectProps = {
    value: selectValue,
    onChange,
    ...getMultiselectProps(autocompleteSelectStyles),
    /**
     * Disable autocomplete when disabled or in the context of the editor
     */
    isDisabled: disabled || canActivateSettings,
    closeMenuOnSelect: true,
    onFocus: () => {
      setAutocompleteFocus(true);
    },
    components: {
      Menu: SelectMenu,
      Option: PlaceOption
    },
    blurInputOnSelect: true,
    onBlur: () => {
      setAutocompleteFocus(false);
    },
    placeholder: placeholder || "Address line 1",
    ref: selectRef
  };

  /**
   * Log session token in development
   */
  if (process.env.NODE_ENV === "development") {
    autocompleteSelectProps.onInputChange = () => {
      if (autocompleteRef && autocompleteRef.current) {
        const currentToken = autocompleteRef.current.getSessionToken();
        console.log(`---- currentToken`, JSON.stringify(currentToken, null, 2));
      }
    };
  }
  const autocompleteProps = {
    ref: autocompleteRef,
    minLengthAutocomplete: 3,
    selectProps: autocompleteSelectProps,
    /**
     * Let the component manage creation of internal session token
     * - represents a session token used for tracking an autocomplete session, which can be a series of-
     * -- AutocompleteService.getPlacePredictions calls (https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteService.getPlacePredictions)
     * -- followed by a single PlacesService.getDetails call. (https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesService.getDetails)
     * - ^^ We can't do the getDetails call all in one go though so we have to use the independent geocodeByPlaceId call
     */
    withSessionToken: true,
    apiKey: getConfig("GOOGLE_MAPS_API_KEY")
  };

  const fieldFactory = (fieldsConfig) =>
    fieldsConfig.map((fieldConfig) => {
      const fieldName = `${name}.${fieldConfig.name}`;
      let fieldClasses = `${INPUT_COMMON_CLASS} bg-white--hover pointer`;
      if (fieldConfig.customClasses) {
        fieldClasses += ` ${fieldConfig.customClasses}`;
      }
      return (
        <DebounceInput
          key={fieldName}
          placeholder={fieldConfig.placeholder}
          debounce={DEFAULT_DEBOUNCE}
          required={required}
          minRows={1}
          customClasses={{ input: fieldClasses }}
          disabled={disabled}
          name={fieldName}
          valueSet={(val) => {
            if (isMounted.current) {
              setFieldValue(fieldName, val);
              const fieldTouched = get(touched, fieldName);
              if (!fieldTouched) {
                setTimeout(() => {
                  setFieldTouched(fieldName, true);
                }, SET_TOUCH_TIMEOUT);
              }
            }
          }}
        />
      );
    });

  let fields = [];
  if (receiverEnabled) {
    fields.push(
      fieldFactory([
        {
          name: RECEIVER_NAME,
          placeholder: receiverPlaceholder,
          customClasses: "brtr-6 brtl-6 brbl-0 brbr-0"
        }
      ])
    );
  }
  if (addressType === ADDRESS_INPUT_TYPE.AUTOCOMPLETE) {
    fields.push(
      <Fragment>
        <GooglePlacesAutocomplete {...autocompleteProps} />
        {canActivateSettings ? (
          <div className="pv1">
            <InfoNotice
              customClasses={{
                copy: "pl2 f7 black-60"
              }}
              copy="Autocomplete only enabled on live pages."
            />
          </div>
        ) : null}
      </Fragment>
    );
  } else {
    fields.push(
      fieldFactory([
        getAddressFieldProps({
          key: ADDRESS_KEYS.LINE_ONE,
          /**
           * If there are no fields added yet, then we need to cap this field as the "top"
           * Cap by rounding top but not bottom corners
           */
          customClasses:
            fields.length === 0 ? "brtr-6 brtl-6 brbl-0 brbr-0" : "br0"
        })
      ])
    );
  }

  fields.push(
    fieldFactory([
      getAddressFieldProps({
        key: ADDRESS_KEYS.LINE_TWO,
        customClasses: "br0"
      })
    ])
  );
  fields.push(
    fieldFactory([
      getAddressFieldProps({
        key: ADDRESS_KEYS.CITY,
        customClasses: "br0"
      })
    ])
  );

  /**
   * If USA - there is a specific state option picker component
   * Else manual entry of state
   */
  if (isUSA) {
    fields.push(<MultiSelect {...stateSelectProps} />);
  } else {
    fields.push(
      fieldFactory([
        getAddressFieldProps({ key: ADDRESS_KEYS.STATE, customClasses: "br0" })
      ])
    );
  }
  fields.push(
    fieldFactory([
      getAddressFieldProps({
        key: ADDRESS_KEYS.POSTAL_CODE,
        customClasses: "br0"
      })
    ])
  );
  fields.push(<MultiSelect {...countrySelectProps} />);

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => (
        <div
          id={nameRoot}
          className={`w-100 ${reflectClasses}`}
          onClick={onClick}
        >
          <LabelDescription
            nameRoot={nameRoot}
            edit={edit}
            label={label}
            description={description}
            showError={showError}
            required={required}
          />
          <div className="w-100">
            {fields.map((field, fieldIx) => (
              <div key={fieldIx}>{field}</div>
            ))}
          </div>
        </div>
      )}
    </ReflectFocus>
  );
};

export default Address;
