import React from "react";
import classnames from "classnames";
import {
  RECURRING_INTERVALS,
  INTERVAL_LABELS_MAP
} from "components/FormerEditor/common/constants";
import { TOGGLE_STATE } from "utils/constants/form";
import Toggle from "react-toggle";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { getFieldPath } from "utils/form";
import set from "lodash/set";
import startCase from "lodash/startCase";
import { CONFIG_PRICING_DEFAULT_INTERVAL } from "utils/constants/state";
import { usePageFactoryContext } from "utils/context";

const DEFAULT_CLASSES = {
  container: "flex flex-column center items-center",
  toggleWrapper: "flex flex-row items-center",
  copy: "f5 fw5",
  toggle: "flex-shrink-0 ph2",
  subtext: "f7 center pt1"
};

const IntervalToggle = ({ afterChange, customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const { values, setValues, setFieldTouched } = useFormikContext();
  const { stateKey } = usePageFactoryContext();
  const rootPath = getFieldPath(stateKey, CONFIG_PRICING_DEFAULT_INTERVAL);

  const interval = get(values, rootPath);
  const active = interval === RECURRING_INTERVALS.YEAR;
  const toggleProps = {
    icons: false,
    checked: active,
    value: active ? TOGGLE_STATE.YES : TOGGLE_STATE.NO,
    onChange: (evt) => {
      const { value } = evt.currentTarget;
      const active = value !== TOGGLE_STATE.YES;
      const updatedInterval = active
        ? RECURRING_INTERVALS.YEAR
        : RECURRING_INTERVALS.MONTH;
      const updatedValues = { ...values };
      set(updatedValues, rootPath, updatedInterval);

      if (afterChange) {
        afterChange({
          values: updatedValues,
          setValues
        });
      } else {
        setValues(updatedValues);
        setFieldTouched(rootPath);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.toggleWrapper}>
        <div
          className={classnames(classes.copy, {
            "o-100": !active,
            "o-70": active
          })}
        >
          {`Billed ${startCase(
            INTERVAL_LABELS_MAP[RECURRING_INTERVALS.MONTH]
          )}`}
        </div>
        <div className={classes.toggle}>
          <Toggle {...toggleProps} />
        </div>
        <div
          className={classnames(classes.copy, {
            "o-100": active,
            "o-70": !active
          })}
        >
          {`Billed ${startCase(INTERVAL_LABELS_MAP[RECURRING_INTERVALS.YEAR])}`}
        </div>
      </div>
      {/* <div className={classes.subtext}>Pricing does not include taxes.</div> */}
    </div>
  );
};

export default IntervalToggle;
