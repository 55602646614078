import React from "react";
import Image from "components/Image";
import {
  BUTTON_CLASS_CONTEXT,
  BUTTON_TAGS,
  DEFAULT_WAVE_BG,
  PRIORITY_FIT_COVER,
  TRANSPARENT_CHAR
} from "utils/constants/ui";
import { getIconForUID } from "components/FormerEditor/utils";
import classnames from "classnames";
import { useResizeDetector } from "react-resize-detector";
import dynamic from "next/dynamic";
import { urlWithCurrentLocationCancel } from "utils/route";
import { getAppliedDimensions } from "utils/view";
import Loader from "components/QRCodeStyled/Loader";

/**
 * Load the button on the client so that the window.location.href can be used in the cancel params
 */
const ButtonLoader = () => <div className="h2 shimmer w-100"></div>;
const CheckoutButton = dynamic(() => import("./CheckoutButton"), {
  ssr: false,
  loading: ButtonLoader
});

const QRCodeStyled = dynamic(() => import("components/QRCodeStyled"), {
  ssr: false,
  loading: Loader
});

const ContactlessPaymentLink = ({
  title,
  description,
  liveUrl,
  discount,
  images,
  label,
  labelNote,
  sublabel,
  children,
  contactless = false,
  codeSizeFactor = 0.7,
  widthFactor = 0.5,
  heightFactor = 0.75,
  code,
  test = false
}) => {
  const { width, height, ref } = useResizeDetector();

  const {
    height: ctxHeight,
    width: ctxWidth,
    appliedWidth,
    appliedHeight
  } = getAppliedDimensions({
    width,
    height,
    widthFactor,
    heightFactor
  });
  const DiscountIcon = getIconForUID("discount");

  const imageDimensions = {
    height: appliedHeight,
    width: ctxWidth
  };

  const image = images && images[0] ? images[0] : DEFAULT_WAVE_BG;
  let imageProps = {
    alt: title,
    ...imageDimensions,
    src: image,
    ...PRIORITY_FIT_COVER
  };

  const shouldRenderImage = image && ctxWidth && ctxHeight;

  const buttonProps = {
    tag: BUTTON_TAGS.ANCHOR,
    href: urlWithCurrentLocationCancel(liveUrl),
    target: "_blank",
    copy: "Checkout",
    classes: {
      contextClass: BUTTON_CLASS_CONTEXT.BLOCKS_PRIMARY
    }
  };

  const size = appliedWidth * codeSizeFactor;
  const qrCodeProps = {
    key: size,
    url: liveUrl,
    size,
    test,
    ...code
  };

  return (
    <div className="w-100 flex flex-column center" ref={ref}>
      <div className="br--top br3 hairline-1 ba pa3 flex flex-column w-100 items-start contactless-header">
        <div className="f4 f3-ns fw6 w-100">{title}</div>
        {description && <div className="f5 pt2 lh-copy">{description}</div>}
      </div>
      <div
        style={{ height: appliedHeight }}
        className={`flex flex-row w-100 relative`}
      >
        <div
          className={classnames(
            "bg-white br2 flex-row absolute left-1 bottom-1 z-1",
            {
              flex: contactless,
              dn: !contactless
            }
          )}
        >
          {liveUrl && size && <QRCodeStyled {...qrCodeProps} />}
        </div>
        <div className="relative w-100 bl br hairline-1">
          {shouldRenderImage ? (
            <div className="relative">
              <Image {...imageProps} />
            </div>
          ) : (
            <div className="w-100 bg-white" style={imageDimensions}></div>
          )}
          {discount && (
            <div className="pa2 bg-white br2 flex flex-row absolute right-1 bottom-1 ba hairline-1">
              <DiscountIcon size={14} />
              <div className="pl1 f6 fw5 black-80">{discount}</div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{ marginTop: -1 }}
        className="br--bottom br3 hairline-1 ba flex flex-column w-100 items-center justify-between"
      >
        <div className="ph3 pv2 flex w-100 items-center justify-between hairline-1 bb">
          <div
            className={classnames("f6", {
              "black-60": sublabel,
              "color-transparent": !sublabel
            })}
          >
            {sublabel || TRANSPARENT_CHAR}
          </div>
          <div
            className={classnames("f3 fw6 flex flex-column items-end", {
              "color-transparent": !label
            })}
          >
            {label || TRANSPARENT_CHAR}
            {labelNote && <span className="f7 fw4">{labelNote}</span>}
          </div>
        </div>
        {!contactless && (
          <div className="w-100 pa3">
            <CheckoutButton {...buttonProps} />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default ContactlessPaymentLink;
