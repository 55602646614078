import React from "react";
import ReflectFocus from "components/ReflectFocus";
import { ENTITY_HOVER_UNDERLINE } from "utils/constants/ui";
import { STATE_KEYS } from "utils/constants/state";
import { PRICING_INTENT_BILLING_FOCUS } from "utils/constants/focus";
import IntervalToggle from "../IntervalToggle";
import CurrencyToggle from "../CurrencyToggle";
import { usePricingCurrencyOptions } from "utils/hooks/pricing";

const PricingTableHeader = ({ name, entity, afterChange }) => {
  const options = usePricingCurrencyOptions();
  const hasMultiple = options.length > 1;

  const controls = hasMultiple ? (
    <div className="flex flex-column flex-row-ns">
      <div className="w-20"></div>
      <div className="w-100">
        <IntervalToggle entity={entity} afterChange={afterChange} />
      </div>
      <div className="w-20 pt3 pv0-ns center">
        <CurrencyToggle entity={entity} afterChange={afterChange} />
      </div>
    </div>
  ) : (
    <IntervalToggle entity={entity} afterChange={afterChange} />
  );

  return (
    <ReflectFocus
      focus={[
        {
          key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
          value: name
        },
        PRICING_INTENT_BILLING_FOCUS
      ]}
      classes={ENTITY_HOVER_UNDERLINE}
    >
      {({ onClick, classes } = {}) => (
        <div onClick={onClick} className={`w-100 ${classes}`}>
          {controls}
        </div>
      )}
    </ReflectFocus>
  );
};

export default PricingTableHeader;
