import React from "react";
import { FORMER_MAX_WIDTH } from "utils/constants/ui";
import { EntityPropTypes } from "utils/propTypes";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import classnames from "classnames";
import { usePageElementNameRoot } from "utils/hooks/manifest";

const FormerBanner = ({
  name,
  entity,
  data: { title, subtitle, tertiary },
  entityRef,
  ui
}) => {
  const focus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    }
  ];
  const nameRoot = usePageElementNameRoot(name);

  return (
    <ReflectFocus name={entity.schema.title} focus={focus} withHeader>
      {({ onClick, hover, classes } = {}) => {
        return (
          <div
            id={nameRoot}
            ref={entityRef}
            onClick={onClick}
            className={classnames(`w-100 ph3 ph0-ns ${classes}`, {
              "bg-white": !hover
            })}
            style={{ maxWidth: FORMER_MAX_WIDTH }}
          >
            <div className="tc pb4 pt4 pt5-ns">
              <div className="f1 fw6">{title}</div>
              {subtitle && (
                <div className="f3 mt3 lh-copy w-100 w-two-thirds-ns center">
                  {subtitle}
                </div>
              )}
              {tertiary &&
                tertiary.map((text) => (
                  <div className="f4 lh-copy w-100 w-two-thirds-ns center">
                    {text}
                  </div>
                ))}
            </div>
          </div>
        );
      }}
    </ReflectFocus>
  );
};

FormerBanner.propTypes = EntityPropTypes;

export default FormerBanner;
