import { SECTIONS } from "components/FormerEditor/utils";
import Link from "next/link";
import React from "react";
import { useAppManifestSectionDestination } from "utils/hooks";

const EmptyFeaturesListPrompt = () => {
  const featuresDestination = useAppManifestSectionDestination(
    SECTIONS.FEATURES
  );
  return (
    <div className="w-100 relative">
      <ul className="list ma0 pa0">
        {new Array(5).fill("").map((_, listItem) => (
          <li
            className={"flex flex-row items-center justify-between mb3"}
            key={listItem}
          >
            <div className="h1 w2 bg-black-05"></div>
            <div className="h1 w-100 ml2 bg-black-05" />
          </li>
        ))}
      </ul>
      <div className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center">
        <Link href={featuresDestination}>
          <div className="tc hairline-1 ba pa2 br2 bg-white bg-white--hover pointer">
            <div className="f6 lh-copy">Add features</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default EmptyFeaturesListPrompt;
