import axios from "axios";
import { devLogger } from "./debug";
import { getConfig } from "utils/env";

export const trackEvent = async (event) => {
  try {
    const response = await axios({
      method: "post",
      url: `${getConfig("API_ROOT")}/v1/events`,
      data: event
    });
    devLogger("event.tracked", response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};
