import React from "react";
import ProfileAvatar from "components/ProfileAvatar";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import { useEditorViewport } from "utils/hooks";
import get from "lodash/get";
import classnames from "classnames";
import { processViewportClasses } from "utils/editor";
import { BLOCKS_PREFIX } from "utils/constants/ui";
import EditableContent from "components/EditableContent";

const InitialPreview = ({ name, edit }) => {
  const { values } = useFormikContext();
  const editorViewport = useEditorViewport();
  const fieldName = getFieldPath(name, "initialPreview");

  const content = get(values, fieldName);
  if (!content) {
    return null;
  }

  const { title, subtitle, image } = content;
  const ctxImage = image && image.src ? image : null;

  const compClasses = processViewportClasses(
    {
      container: "w-100 pb5",
      title: classnames(`fw6 f2 f1-ns ${BLOCKS_PREFIX}color-text--primary`, {
        pt2: ctxImage
      }),
      subtitle: `f4 f3-ns pt2 lh-title w-100 ${BLOCKS_PREFIX}color-text--primary`,
      desktopImage: "dib dn-l",
      mobileImage: "dn dib-l"
    },
    editorViewport
  );

  const showImage = Boolean(ctxImage);
  const showTitle = title || edit;
  const showSubtitle = title || edit;
  const hasContent = showImage || showTitle || showSubtitle;
  if (!hasContent) {
    return null;
  }

  return (
    <div className={compClasses.container}>
      {showImage && (
        <div className="w-100">
          <div className={compClasses.desktopImage}>
            <ProfileAvatar
              {...ctxImage}
              height={120}
              width={120}
              imageClass="br2"
            />
          </div>
          <div className={compClasses.mobileImage}>
            <ProfileAvatar
              {...ctxImage}
              height={140}
              width={140}
              imageClass="br2"
            />
          </div>
        </div>
      )}
      {showTitle && (
        <div className={compClasses.title}>
          <EditableContent
            editable={edit}
            sync={{ name: `${fieldName}.title` }}
          >
            {title}
          </EditableContent>
        </div>
      )}
      {showSubtitle && (
        <div className={compClasses.subtitle}>
          <EditableContent
            editable={edit}
            sync={{ name: `${fieldName}.subtitle` }}
          >
            {subtitle}
          </EditableContent>
        </div>
      )}
    </div>
  );
};

export default InitialPreview;
