import React, { useState } from "react";
import LegalEditorField from "components/LegalEditorField";
import HoverIconButton from "components/HoverIconButton";

const FormattedContent = ({ content }) => {
  const [visible, setVisible] = useState(true);

  return visible ? (
    <div className="w-100 pb4">
      <div className="br2 pa2 bg-warning-05 w-100">
        <div className="flex flex-row justify-between pb1">
          <div className="fw6">Note</div>
          <HoverIconButton
            customClasses={{
              button:
                "ma0 items-center flex pa0 dib items-center justify-center bn br1 panel--action relative bg-transparent"
            }}
            icon="close"
            onClick={() => setVisible(false)}
          />
        </div>
        <LegalEditorField content={content} editable={false} />
      </div>
    </div>
  ) : null;
};

export default FormattedContent;
