import get from "lodash/get";

/**
 * Props passed from react-select
 * @param {Object} params
 * @param {Object} params.innerProps - react-select props
 * @param {Object} params.data - Matches the shape of the AutocompletePredictions response https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletePrediction
 * @returns
 */
const PlaceOption = ({ innerProps, data, getStyles, ...props }) => {
  const optionStyles = getStyles("option", props);
  const mainText = get(data, "value.structured_formatting.main_text");
  const secondaryText = get(data, "value.structured_formatting.secondary_text");
  const boldCharsLength = get(
    data,
    "value.structured_formatting.main_text_matched_substrings[0].length",
    0
  );

  getStyles;

  const bolded = mainText.slice(0, boldCharsLength);
  const unbolded = mainText.slice(boldCharsLength);

  return (
    <div
      {...innerProps}
      style={{
        ...innerProps.style,
        backgroundColor: optionStyles.backgroundColor
      }}
      className="flex flex-column items-center-ns pa2 bg-white--hover bb hairline-1"
    >
      <div className="f6 w-100 pr1 lh-title truncate">
        {bolded ? <span className="fw6">{bolded}</span> : null}
        {unbolded ? <span className="">{unbolded}</span> : null}
        {secondaryText ? (
          <span style={{ fontSize: 12 }} className="pl2 black-50">
            {secondaryText}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default PlaceOption;
