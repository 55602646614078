export const AREAS = {
  "title": "title",
  "desc": "desc",
  "price": "price",
  "price-details": "price-details",
  "price-actions": "price-actions",
  "top-features-title": "top-features-title",
  "top-features": "top-features"
};

export const getTemplateAreas = (count) => {
  return Object.values(AREAS)
    .map((area) => {
      const areaConfig = new Array(count)
        .fill(area)
        .map((content, contentIx) => `${content}-${contentIx}`, [])
        .join(" ");
      return `"${areaConfig}"`;
    })
    .join(" ");
};
