import React, { Fragment, useEffect } from "react";
import ENTITIES from "components/FormerEditor/common/constants/entity";
import { Elements } from "@stripe/react-stripe-js";
import Padder, { PADDER_SMALL } from "components/Padder";
// Entity components
import FormerNavigation from "components/FormerNavigation";
import FormerBanner from "components/FormerBanner";
import FormerSocialProfile from "components/FormerSocialProfile";
import FormerPayment from "components/FormerPayment";
import FormerPricingTable from "components/FormerPricingTable";
import FormerFeatureComparison from "components/FormerFeatureComparison";
import FormerProductCollection from "components/FormerProductCollection";
import FormerFooter from "components/FormerFooter";
import FormerForm from "components/FormerForm";
import RegisterBlock from "components/RegisterBlock";
import { PAGE_VIEW } from "utils/analytics";
import { trackEvent } from "utils/event";
import { usePageFactoryContext } from "utils/context";
import { ENCRYPTED_QUERY_PARAM_KEYS } from "utils/constants/server";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import { useEmbedStyles, useEmbedEnabled } from "utils/hooks/embed";
const CelebrateLoader = () => <span></span>;
const Celebrate = dynamic(() => import("components/Celebrate"), {
  ssr: false,
  loading: CelebrateLoader
});

const ENTITY_COMPONENTS = {
  [ENTITIES.NAVIGATION]: FormerNavigation,
  [ENTITIES.BANNER]: FormerBanner,
  [ENTITIES.PRICING_TABLE]: FormerPricingTable,
  [ENTITIES.FEATURE_COMPARISON]: FormerFeatureComparison,
  [ENTITIES.SOCIAL_PROFILE]: FormerSocialProfile,
  [ENTITIES.PRODUCT_COLLECTION]: FormerProductCollection,
  [ENTITIES.PAYMENT]: FormerPayment,
  [ENTITIES.FOOTER]: FormerFooter,
  [ENTITIES.FORM]: FormerForm
};

/**
 * Set the initial data in Former
 */
export const PageFactory = ({
  data,
  stateKey,
  entityAfterChanges,
  /**
   * Note:
   * - the page response with manifest && config is used to generate the page initial tracking props via getPageFactoryFormTracking
   */
  tracking: pageTracking
}) => {
  const { query } = useRouter();
  const { entities, content, layout, admin } = data;

  const embedStyles = useEmbedStyles();
  const isEmbed = useEmbedEnabled();

  const {
    canActivateSettings,
    tracking,
    stripePromise,
    setTracking,
    setClientCheckout
  } = usePageFactoryContext();

  useEffect(() => {
    /**
     * When not in editor mode:
     * - we set context tracking props
     * - they will be sent when creating a checkout session
     * - they are set in session metadata so that we can track a full funnel
     */
    if (!canActivateSettings) {
      if (pageTracking && pageTracking.manifest_id) {
        const trackingToSet = {
          ...tracking,
          manifest_id: pageTracking.manifest_id,
          subdomain: pageTracking.subdomain,
          route: pageTracking.route
        };
        if (pageTracking.alias) {
          trackingToSet.alias = pageTracking.alias;
        }

        setTracking(trackingToSet);
      }

      const pageViewData = {
        ...tracking,
        ...pageTracking
      };
      /**
       * Note: these are not the THIRD_PARTY_QUERY_PARAMS, easily mistaken
       * - these params are encrypted checkout params like
       * -- session
       * -- recovery
       */
      const encrParams = pick(query, ENCRYPTED_QUERY_PARAM_KEYS);

      if (!isEmpty(encrParams)) {
        pageViewData.checkout = encrParams;
      }

      trackEvent({
        name: PAGE_VIEW,
        data: pageViewData
      });

      /**
       * If Rewardful is ready and its defined
       * Then set its uuid value within the dynamic checkout params
       * - Extend to ExchangeShortLink if suitable
       */
      if (typeof window !== "undefined" && window.rewardful) {
        window.rewardful("ready", () => {
          /**
           * The clientCheckout payload will be sent along in the checkout action (e.g checkoutForm)
           * - The uuid generated by rewardful will be used as the client_reference_id
           * - The clientCheckout object is then passed in along in the checkout session creation and results in Rewardful "stamping" that new customer with referal data
           * Note:
           * - The uuid is generated based on the via parameter in the url like:
           * - https://getdevplan.priceblocs.com/affiliates?via=shane
           * - The RewardfulTag needs to have been initialized with the sellers API token
           *
           */
          if (window.Rewardful.referral) {
            console.log("Rewardful enabled. Setting client reference id");
            setClientCheckout({
              client_reference_id: window.Rewardful.referral
            });
          } else {
            console.log("Rewardful ready but no referral uuid present");
          }
        });
      }
    }
  }, [canActivateSettings]);

  const nodes = layout.reduce((memo, { uuid }) => {
    const entityMatch = entities.find((entity) => entity.uuid === uuid);
    if (entityMatch) {
      const EntityComponent = ENTITY_COMPONENTS[entityMatch.component];
      const entityContent = content[entityMatch.uuid];
      if (EntityComponent) {
        const entityProps = {
          entity: entityMatch,
          afterChange:
            entityAfterChanges && entityAfterChanges[entityMatch.component],
          stateKey,
          name: entityMatch.uuid,
          ...entityContent
        };

        memo.push(
          <RegisterBlock key={entityMatch.uuid} uuid={entityMatch.uuid}>
            {({ entityRef }) => {
              return <EntityComponent entityRef={entityRef} {...entityProps} />;
            }}
          </RegisterBlock>
        );
      }
    }

    return memo;
  }, []);

  if (!canActivateSettings && !isEmbed) {
    nodes.push(<Padder key="padder" theme={PADDER_SMALL} />);
  }

  const innerContent = (
    <Fragment>
      <Celebrate />
      <div className="w-100 flex flex-column items-center center">
        {nodes}
        {/* Embed specific content */}
        {embedStyles}
        <div data-iframe-height />
      </div>
    </Fragment>
  );

  /**
   * TODO:
   * - should there be a scaffolded render
   */
  return stripePromise ? (
    <Elements stripe={stripePromise}>{innerContent}</Elements>
  ) : null;
};

export default PageFactory;
