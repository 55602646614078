import React, { useState } from "react";
import ProductFeatureGroupRow from "./ProductFeatureGroupRow";
import ProductFeaturesTableHeader from "./ProductFeaturesTableHeader";

const ProductFeaturesTable = ({
  entity,
  afterChange,
  header,
  groups,
  emphasisIndex
}) => {
  // Index should exist within collection of header products else use 0
  const iniitalIndex = header[emphasisIndex] ? emphasisIndex : 0;
  const [activeIndex, setActiveIndex] = useState(iniitalIndex);
  const product = header[activeIndex];

  const productOptions = header.map((product, productIx) => ({
    label: product.title,
    uid: productIx
  }));

  return (
    <div className="w-100 relative">
      {product && (
        <ProductFeaturesTableHeader
          product={product}
          emphasisIndex={emphasisIndex}
          options={productOptions}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          entity={entity}
          afterChange={afterChange}
        />
      )}
      <div className="w-100">
        {product &&
          groups.reduce((memo, { columns, rows }, groupIx) => {
            /**
             * Use a 1 based index because the first column is the feature title
             */
            const productUID = columns[activeIndex + 1].accessor;

            memo.push(
              <ProductFeatureGroupRow
                key={groupIx}
                header={columns[0]}
                productUID={productUID}
                rows={rows}
              />
            );
            return memo;
          }, [])}
      </div>
    </div>
  );
};

export default ProductFeaturesTable;
