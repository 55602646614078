import React from "react";
import { useFormikContext } from "formik";
import Label from "components/Form/fields/Label";
import Checkbox from "rc-checkbox";
import get from "lodash/get";
import classnames from "classnames";
import { getCheckboxProps, getFieldNameRoot } from "utils/form";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import { SET_TOUCH_TIMEOUT } from "utils/constants/form";
import EditableContent from "components/EditableContent";

const DEFAULT_CLASSES = {
  container: "flex-auto tl",
  toggleWrapper: ""
};

const FormCheckbox = (props) => {
  const {
    name,
    label,
    description,
    validation,
    customClasses,
    focus = [],
    index
  } = props;
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const nameRoot = getFieldNameRoot(name);

  const value = get(values, name);
  const isTouched = get(touched, name);
  const required = validation && validation.required;
  const error = get(errors, name);
  const disabled = Boolean(props.disabled);

  const showError = Boolean(isTouched && error);
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  const toggleValue = (_, active) => {
    if (!disabled) {
      setFieldValue(name, active);

      if (!isTouched) {
        setTimeout(() => {
          setFieldTouched(name, true);
        }, SET_TOUCH_TIMEOUT);
      }
    }
  };

  const labelName = `${nameRoot}.label`;
  const descriptionName = `${nameRoot}.description`;

  /* Note: Checkbox currently breaks the description label pattern as its used inline with the checkbox
   * - Inline value should be meta.prompt and description aligned to standard presentation
   */
  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => {
        const showLabel = Boolean(label || edit);
        const showDescription = Boolean(description || edit);

        return (
          <div
            id={nameRoot}
            className={`w-100 ${reflectClasses}`}
            onClick={onClick}
          >
            <div
              className={classnames(classes.container, {
                "flex flex-row justify-between items-center": !description
              })}
            >
              {showLabel && (
                <Label
                  edit={edit}
                  name={labelName}
                  label={label}
                  error={showError}
                  required={required}
                />
              )}
              <div
                className={classnames(classes.toggleWrapper, {
                  "flex flex-row justify-between items-center": description
                })}
              >
                <div
                  className="w-100 pointer"
                  onClick={() => toggleValue(null, !Boolean(value))}
                >
                  {showDescription && (
                    <div className="f6 tl pr2 lh-copy">
                      <EditableContent
                        editable={edit}
                        sync={{
                          name: descriptionName
                        }}
                      >
                        {description}
                      </EditableContent>
                    </div>
                  )}
                </div>
                <Checkbox
                  {...getCheckboxProps({
                    key: name,
                    active: value,
                    disabled,
                    setFieldValue: toggleValue
                  })}
                />
              </div>
            </div>
            {showError && <div className="f7 pt1 color-error">{error}</div>}
          </div>
        );
      }}
    </ReflectFocus>
  );
};

export default FormCheckbox;
