import React from "react";
import PropTypes from "prop-types";
import { ENTITY_HOVER_UNDERLINE, FORMER_MAX_WIDTH } from "utils/constants/ui";
import { getFieldPath } from "utils/form";
import get from "lodash/get";
import {
  CONFIG_PRICING_EMPHASIS_INDEX,
  CONFIG_FEATURES_SHOW
} from "utils/constants/state";
import { useFormikContext } from "formik";
import { usePageFactoryContext } from "utils/context";
import { STATE_KEYS } from "utils/constants/state";
import EmptyFeatureComparisonPrompt from "./EmptyFeatureComparisonPrompt";
import ReflectFocus from "components/ReflectFocus";
import ProductFeaturesTable from "./ProductFeaturesTable";
import ProductsFeatureComparisonTable from "./ProductsFeatureComparisonTable";
import { useFeatureGroups } from "utils/selectors";
import classnames from "classnames";
import { FEATURES_ACTIVE_SECTION_FOCUS } from "utils/constants/focus";
import { usePageElementNameRoot } from "utils/hooks/manifest";

const FormerFeatureComparison = ({
  name,
  entity,
  afterChange,
  data,
  ui,
  ref,
  entityRef
}) => {
  const { values } = useFormikContext();
  const { canActivateSettings, stateKey } = usePageFactoryContext();
  const emphasisIndexPath = getFieldPath(
    stateKey,
    CONFIG_PRICING_EMPHASIS_INDEX
  );
  const nameRoot = usePageElementNameRoot(name);
  const emphasisIndex = get(values, emphasisIndexPath);

  const featuresShowPath = getFieldPath(stateKey, CONFIG_FEATURES_SHOW);
  const featuresShow = get(values, featuresShowPath);

  const featureGroups = useFeatureGroups();

  const groupsPresent = data && data.groups && data.groups.length;
  const containerProps = {
    style: { maxWidth: FORMER_MAX_WIDTH },
    id: nameRoot,
    ref: entityRef
  };

  const focus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    }
  ];

  if (groupsPresent) {
    const thWidth = 30;
    const tdWidth = (100 - thWidth) / data.header.length;

    const tableInput = {
      entity,
      focus,
      emphasisIndex,
      afterChange,
      thWidth,
      tdWidth,
      header: data.header,
      groups: data.groups
    };

    return (
      <ReflectFocus focus={focus} name={entity.schema.title} withHeader>
        {({ hover } = {}) => (
          <div
            {...containerProps}
            className={classnames("w-100 pb5", {
              "bg-white": !hover,
              "mt4-ns": !featuresShow
            })}
          >
            <div className="f2 pt5 pb4 pb5-ns fw5 ph3 ph0-ns lh-title">
              {data.title}
            </div>
            {/* Desktop */}
            <div className="dn dt-ns w-100 ph3">
              <ProductsFeatureComparisonTable {...tableInput} />
            </div>
            {/* Mobile */}
            <div className="db dn-ns">
              <ProductFeaturesTable {...tableInput} />
            </div>
          </div>
        )}
      </ReflectFocus>
    );
  } else if (canActivateSettings) {
    const addFeaturesFocus = [
      {
        key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
        value: name
      }
    ];
    if (!featureGroups.length) {
      addFeaturesFocus.push(FEATURES_ACTIVE_SECTION_FOCUS);
    }

    return (
      <ReflectFocus focus={focus} classes={ENTITY_HOVER_UNDERLINE} withHeader>
        {({ onClick, classes, hover } = {}) => (
          <div
            onClick={onClick}
            {...containerProps}
            className={classnames(`w-100 pb5 pt5 ${classes}`, {
              "bg-white": !hover
            })}
          >
            <EmptyFeatureComparisonPrompt groupCount={featureGroups.length} />
          </div>
        )}
      </ReflectFocus>
    );
  } else {
    return <span />;
  }
};

FormerFeatureComparison.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    header: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      })
    ),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        columns: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            accessor: PropTypes.string
          })
        ),
        rows: PropTypes.array
      })
    )
  })
};

export default FormerFeatureComparison;
