import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import Overlay from "components/Overlay";
import classnames from "classnames";
import Pill from "components/Pill";
import { PILL_THEME } from "components/Pill";
import { useEditorViewport } from "utils/hooks";
import { processViewportClasses } from "utils/editor";
import { PRIORITY_FIT_CONTAIN } from "utils/constants/ui";
import Image from "components/Image";
import CheckoutPreview from "components/CheckoutPreview";
import { cellGrow } from "constants/styles";
import { PAGE_CHECKOUT_PREVIEW_CLASSES } from ".";
import { getCheckoutPreviewSublabel } from "components/FormerEditor/common/preview";
import { useEmbedEnabled } from "utils/hooks/embed";

export const IMAGE_DIMENSIONS = { height: 120, width: 120 };

const MobileHeader = ({
  preview,
  banner,
  badge,
  multipleLines,
  hasAdditionalInfo,
  children,
  firstLineItem: { image, label } = {}
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const hasBannerLabel = banner && banner.label;
  const hasBadgeLabel = badge && badge.label;
  const editorViewport = useEditorViewport();
  const hasMoreInfo = multipleLines || hasAdditionalInfo;
  const hasOverlay = hasMoreInfo && preview;
  const embedEnabled = useEmbedEnabled();

  let modalTrigger;
  if (hasOverlay) {
    modalTrigger = (
      <div className="absolute bottom-0 left-0 right-0 justify-center items-center pb1">
        <div
          style={{ width: 104 }}
          className="w4 shadow-0 ba hairline-1 bg-white pa1 br2 center pointer bg-white--hover"
        >
          <div className="f7 fw6 tc black-70">{`${
            showOverlay ? "Hide" : "Show"
          } details`}</div>
        </div>
      </div>
    );
  }

  const compClasses = processViewportClasses(
    PAGE_CHECKOUT_PREVIEW_CLASSES,
    editorViewport
  );

  const pillBadge = hasBadgeLabel && (
    <Pill
      theme={PILL_THEME.SLIM}
      status={badge.status}
      copy={badge.label}
      customClasses={{
        container: "flex-shrink-0",
        copy: "f7 pa2 br2 fw6 flex-shrink-0 items-center"
      }}
    />
  );

  const containerProps = {
    className: classnames("w-full relative flex flex-column", {
      "cell-grow": modalTrigger,
      "pb3": !hasBannerLabel
    })
  };
  if (hasOverlay) {
    containerProps.onClick = () => setShowOverlay(!showOverlay);
  }

  const sublabel = getCheckoutPreviewSublabel({ banner, preview });

  let showContent = null;
  if (showOverlay && hasOverlay) {
    /**
     * If embed
     * - present inline because modal doesn't work well
     * Else
     * - present in an Overlay
     */
    showContent = embedEnabled ? (
      <div className="pb3 pt1">
        <div className="shadow-0 ba hairline-1 ph3 pt3 pb2 br-6 bg-white">
          <CheckoutPreview hideHeader {...preview} />
        </div>
      </div>
    ) : (
      ReactDOM.createPortal(
        <Overlay hideOverlay={() => setShowOverlay(false)}>
          <div className="pa3">
            <CheckoutPreview {...preview} />
            <div className="pt3">{children}</div>
          </div>
        </Overlay>,
        document.body
      )
    );
  }

  return (
    <Fragment>
      <div {...containerProps}>
        <div className="dib dn-ns">{pillBadge}</div>
        {image ? (
          <div className={`${compClasses.image} relative`}>
            <div
              style={IMAGE_DIMENSIONS}
              className="bg-transparent br2 overflow-hidden"
            >
              <Image
                src={image}
                {...IMAGE_DIMENSIONS}
                alt={label}
                {...PRIORITY_FIT_CONTAIN}
              />
            </div>
            {modalTrigger}
          </div>
        ) : null}
        {!image && modalTrigger}
        <div
          className={classnames("w-100 flex flex-row", {
            "justify-end pb3": !hasBannerLabel && hasBadgeLabel
          })}
        >
          {hasBannerLabel && (
            <div className="w-100 pb4">
              {banner.title && (
                <div className={compClasses.title}>{banner.title}</div>
              )}
              {banner.label && (
                <div className={compClasses.label}>{banner.label}</div>
              )}
              {sublabel && (
                <div className={compClasses.sublabel}>{sublabel}</div>
              )}
              {banner.body && (
                <div className={compClasses.body}>{banner.body}</div>
              )}
            </div>
          )}
          <div className="dn dib-ns flex-shrink-0">{pillBadge}</div>
        </div>
        <style jsx>{cellGrow}</style>
      </div>
      {showContent}
    </Fragment>
  );
};

export default MobileHeader;
