import React, { Fragment } from "react";
import PricingInfoSection from "../PricingInfoSection";
import { getGridClass } from "../utils";
import ReflectFocus from "components/ReflectFocus";
import { ProductActionType, EditorFocusType } from "utils/propTypes";
import PropTypes from "prop-types";
import { ENTITY_HOVER_UNDERLINE } from "utils/constants/ui";
import classnames from "classnames";
import Image from "components/Image";
import { PRIORITY_FIT_COVER } from "utils/constants/ui";
import { DIMENSIONS } from "utils/constants/image";
import { usePageFactoryContext } from "utils/context";
import { useFormikContext } from "formik";
import {
  CONFIG_PRICING_SHOW_IMAGERY,
  PRODUCT_DATA
} from "utils/constants/state";
import { getFieldPath } from "utils/form";
import get from "lodash/get";

const ProductHead = ({
  uid,
  name,
  productIndex,
  productCount,
  isFirst,
  actions,
  title,
  description,
  formattedPrice,
  subtext,
  focus,
  isEmphasized
}) => {
  const gridClass = getGridClass({
    suffix: `title--${productIndex}`,
    isEmphasized
  });
  const collapseImages = false;

  const { stateKey } = usePageFactoryContext();
  const { values } = useFormikContext();
  const productsDataPath = getFieldPath(stateKey, PRODUCT_DATA);

  const showImageryRootPath = getFieldPath(
    stateKey,
    CONFIG_PRICING_SHOW_IMAGERY
  );
  const showImagery = get(values, showImageryRootPath);
  const products = get(values, productsDataPath, []);
  const productMatch = products.find(({ id }) => id === uid);
  const hasImages =
    productMatch && productMatch.images && productMatch.images.length > 0;

  /**
   * Controlling whether to show imagery or not
   * [collapseImages] - if none of the products on the table have imagery - then dont show
   * [hasImages] - if the product itself has imagery, then show
   * [showImagery] - the configuration setting for imagery within the manifest
   */
  const showImageNode = !collapseImages && hasImages && showImagery;

  return (
    <Fragment>
      <div
        className={classnames(gridClass, {
          "bt-hairline-1-s": !isFirst && !isEmphasized
        })}
      >
        {showImageNode && (
          <div className="w-100 tc pt4">
            <div
              style={DIMENSIONS.SHOW_LARGE}
              className="br2 overflow-hidden flex-shrink-0 center"
            >
              {hasImages ? (
                <Image
                  src={productMatch.images[0]}
                  {...DIMENSIONS.SHOW_LARGE}
                  alt={title}
                  {...PRIORITY_FIT_COVER}
                />
              ) : null}
            </div>
          </div>
        )}
        {title && (
          <ReflectFocus focus={focus} classes={ENTITY_HOVER_UNDERLINE}>
            {({ onClick, classes } = {}) => (
              <div
                onClick={onClick}
                className={classnames(
                  `fw6 pb1 ph1 pt4 tc word-break lh-title ${classes}`,
                  {
                    "f2": productCount <= 2,
                    "f2-5": productCount > 2
                  }
                )}
              >
                {title}
              </div>
            )}
          </ReflectFocus>
        )}
      </div>
      <div
        className={getGridClass({
          suffix: `desc--${productIndex}`,
          isEmphasized
        })}
      >
        {description && (
          <ReflectFocus focus={focus} classes={ENTITY_HOVER_UNDERLINE}>
            {({ onClick, classes } = {}) => (
              <div
                onClick={onClick}
                className={classnames(
                  `pt2 ph2 lh-copy tc word-break ${classes}`,
                  {
                    "f4": productCount <= 2,
                    "f4-5": productCount > 2
                  }
                )}
              >
                {description}
              </div>
            )}
          </ReflectFocus>
        )}
      </div>
      <PricingInfoSection
        uid={uid}
        name={name}
        focus={focus}
        actions={actions}
        productIndex={productIndex}
        formattedPrice={formattedPrice}
        subtext={subtext}
        isEmphasized={isEmphasized}
      />
    </Fragment>
  );
};

ProductHead.propTypes = {
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  formattedPrice: PropTypes.string.isRequired,
  subtext: PropTypes.array,
  actions: PropTypes.arrayOf(PropTypes.shape(ProductActionType)).isRequired,
  productIndex: PropTypes.number.isRequired,
  focus: PropTypes.arrayOf(PropTypes.shape(EditorFocusType))
};

export default ProductHead;
