import React from "react";
import Button from "components/Form/fields/Button";
import { checkoutIdentity } from "utils/checkout/actions";
import ReflectFocus from "components/ReflectFocus";
import { DEFAULT_ACTIONS_CLASSES } from "utils/constants/ui";
import { useCheckoutActionProps } from "utils/hooks/checkout";

const FormerIdentitySessionAction = ({
  onSuccess,
  onError,
  action,
  disabled,
  focus,
  name,
  formData,
  isLive,
  customClasses
}) => {
  const classes = { ...DEFAULT_ACTIONS_CLASSES, ...customClasses };
  const contextProps = {
    checkoutAction: checkoutIdentity,
    checkoutParams: {
      formData,
      onSuccess,
      onError
    }
  };
  // TODO: make unauthed identity request without manifest params

  const { props } = useCheckoutActionProps(
    {
      name,
      action,
      disabled,
      isLive
    },
    contextProps
  );

  return (
    <ReflectFocus focus={focus}>
      {({ onClick, classes: focusClasses } = {}) => (
        <div
          onClick={onClick}
          className={`${classes.container} ${focusClasses}`}
        >
          <div className={classes.nodes}>
            <Button key="checkout" {...props} />
          </div>
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormerIdentitySessionAction;
