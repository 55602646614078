import React from "react";
import { usePageFactoryContext } from "utils/context";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import { PAYMENT_ACTION } from "utils/constants/payment";
import { getEditorFlowLayout, processViewportClasses } from "utils/editor";
import get from "lodash/get";
import Padder from "components/Padder";
import ProductCollectionItem from "components/ProductCollectionItem";
import {
  useCurrentManifestLiveVariation,
  useEditorViewport
} from "utils/hooks";
import { STATE_KEYS } from "utils/constants/state";
import classnames from "classnames";
import { EDITOR_PRESENTATION } from "utils/constants/editor";

/**
 *
 * @param {Object} params
 * @param {Object} params.name - keyed by entity uuid - manifest.data.content.143abb60-da42-4c02-8b2b-1c6ad0a655b8
 * @param {Array} params.focus - array of items to use in focus
 * @returns
 */
const ListLayout = ({ name, focus }) => {
  const { stateKey, canActivateSettings } = usePageFactoryContext();
  const { values } = useFormikContext();

  const contentRoot = getFieldPath(stateKey, `content.${name}`);
  const paymentActionPath = `${contentRoot}.config.paymentAction`;
  const paymentAction = get(values, paymentActionPath);

  const flowPath = `${contentRoot}.config.flow`;
  const flowValue = get(values, flowPath);

  const productsPath = `${contentRoot}.${STATE_KEYS.PRODUCT_COLLECTION.PRODUCTS}`;
  const products = get(values, productsPath, []);

  const actionsPath = `${contentRoot}.${STATE_KEYS.PRODUCT_COLLECTION.ACTIONS}`;
  const actions = get(values, actionsPath, []);

  const liveVariation = useCurrentManifestLiveVariation();
  const isLive = canActivateSettings ? Boolean(liveVariation) : true;

  const contactless = paymentAction === PAYMENT_ACTION.SCAN;
  const editorViewport = useEditorViewport();
  const compClasses = processViewportClasses(
    {
      container: "list-container center list ma0 pa0 w-100",
      item: ""
    },
    editorViewport
  );

  return (
    <ul className={compClasses.container}>
      {products.map((product, productIndex) => {
        const layout = getEditorFlowLayout(flowValue, productIndex);

        return (
          <li
            key={product.uuid}
            className={classnames(compClasses.item, {
              pt3: productIndex
            })}
          >
            <ProductCollectionItem
              focus={focus}
              rootName={name}
              presentation={EDITOR_PRESENTATION.LIST}
              name={`${productsPath}[${productIndex}]`}
              index={productIndex}
              isLive={isLive}
              layout={layout}
              contactless={contactless}
              {...product}
              actions={actions}
            />
          </li>
        );
      })}
      <Padder theme="visible" />
    </ul>
  );
};

export default ListLayout;
