import { FIELD_TYPES } from "utils/constants/form";
// Primitives
import Select from "./Select";
import Multiselect from "./Multiselect";
import Input from "./Input";
import Textarea from "./Textarea";
import Checkbox from "./Checkbox";
import Disclaimer from "./Disclaimer";
import Toggle from "./Toggle";
import Datepicker from "./Datepicker";
import Phone from "./Phone";
// Specialized
import TinFields from "./TinFields";
import FileFields from "./File";
import Identity from "./Identity";
import Address from "./Address";
import Legal from "./Legal";

/**
 * Alias:
 * - FIELD_COMPONENTS_MAP
 * - FIELDS_COMPONENT_MAP
 */
export const FORM_FIELD_COMPONENT_MAP = {
  // Primitives
  [FIELD_TYPES.SELECT]: Select,
  // TODO: repeat this const usage
  [FIELD_TYPES.MULTISELECT]: Multiselect,
  [FIELD_TYPES.INPUT]: Input,
  [FIELD_TYPES.TEXTAREA]: Textarea,
  [FIELD_TYPES.DISCLAIMER]: Disclaimer,
  [FIELD_TYPES.CHECKBOX]: Checkbox,
  [FIELD_TYPES.TOGGLE]: Toggle,
  [FIELD_TYPES.DISCLAIMER]: Disclaimer,
  [FIELD_TYPES.DATEPICKER]: Datepicker,
  [FIELD_TYPES.PHONE]: Phone,
  // Specialized
  [FIELD_TYPES.TIN]: TinFields,
  [FIELD_TYPES.FILE]: FileFields,
  [FIELD_TYPES.IDENTITY]: Identity,
  [FIELD_TYPES.ADDRESS]: Address,
  [FIELD_TYPES.LEGAL]: Legal
};
