import React from "react";
import Image from "components/Image";
import { PRIORITY_FIT_COVER, TRANSPARENT_CHAR } from "utils/constants/ui";
import { getIconForUID } from "components/FormerEditor/utils";
import classnames from "classnames";
import { useResizeDetector } from "react-resize-detector";
import dynamic from "next/dynamic";
import {
  EDITOR_FLOW_DIRECTION,
  EDITOR_PRESENTATION
} from "utils/constants/editor";
import { getAppliedDimensions } from "utils/view";
import Loader from "components/QRCodeStyled/Loader";
import { useEditorViewport } from "utils/hooks";
import { processViewportClasses } from "utils/editor";
import ReflectFocus from "components/ReflectFocus";
import { STATE_KEYS } from "utils/constants/state";
import EditableContent from "components/EditableContent";
import { reduceImages } from "utils/image";

/**
 * Load the button on the client so that the window.location.href can be used in the cancel params
 */
const ButtonLoader = () => <div className="h2 shimmer w-100"></div>;
const FormerCartCheckoutAction = dynamic(
  () => import("components/FormerCartCheckoutAction"),
  {
    ssr: false,
    loading: ButtonLoader
  }
);

const QRCodeStyled = dynamic(() => import("components/QRCodeStyled"), {
  ssr: false,
  loading: Loader
});

const ProductCollectionItem = ({
  /**
   * Product uuid
   * e.g. b428bcd8-ef4e-4a70-bb87-9d3c427228fd
   */
  uuid,
  /**
   * Entity uuid
   * e.g. ae775211-ca44-4dfa-a8a8-c1756f95203d
   */
  rootName,
  /**
   * Node name
   * - incorporates entity uuid
   * - index maps to uuid
   * e.g. content.ae775211-ca44-4dfa-a8a8-c1756f95203d.data.products[0]
   */
  name,
  index,
  layout = EDITOR_FLOW_DIRECTION.LEFT,
  title,
  focus,
  description,
  presentation,
  previewUrl,
  liveUrl,
  discount,
  images,
  label,
  labelNote,
  sublabel,
  children,
  code,
  actions,
  isLive,
  contactless = false,
  codeSizeFactor = 0.45
}) => {
  const isList = presentation === EDITOR_PRESENTATION.LIST;
  const { width, height, ref } = useResizeDetector();
  const editorViewport = useEditorViewport();

  const {
    height: ctxHeight,
    width: ctxWidth,
    appliedWidth,
    appliedHeight
  } = getAppliedDimensions({
    width,
    height,
    widthFactor: 0.5,
    heightFactor: 0.5
  });

  const DiscountIcon = getIconForUID("discount");

  const imageDimensions = {
    height: appliedHeight,
    width: ctxWidth
  };

  const image = reduceImages(images)[0];

  let imageProps = {
    alt: title,
    ...imageDimensions,
    src: image,
    ...PRIORITY_FIT_COVER
  };
  const shouldRenderImage = image && ctxWidth && ctxHeight;

  const size = appliedWidth * codeSizeFactor;
  const qrCodeProps = {
    key: size,
    url: liveUrl,
    size,
    ...code
  };

  const compClasses = processViewportClasses(
    {
      container: "w-100 flex flex-column",
      wrapper: classnames("w-100 flex flex-row items-center order-1", {
        // "order-1-ns pr3-ns": isRight,
        // "order-0-ns pl3-ns": isLeft
      }),
      content: classnames(
        "-mt2-s br2 b--black-10 bg-white pv3 pv4-ns ph3 w-100 flex flex-row",
        {
          "bl br bb br--bottom": shouldRenderImage,
          "ba": !shouldRenderImage
        }
      ),
      title: "f3 fw6",
      description: "f5 pt1 lh-copy",
      labelWrapper: classnames(
        "flex-column flex-row-ns justify-between-ns items-center-ns pt3",
        {
          dn: !label,
          flex: label
        }
      ),
      label: classnames("f3 fw6", {
        "color-transparent": !label
      }),
      labelNote: classnames("f6 fw3 black-60 pt1 pt0-ns", {
        "black-60": sublabel,
        "color-transparent": !sublabel
      }),
      sublabel: classnames("f6 black-60 pt1 pt0-ns", {
        "black-60": sublabel,
        "color-transparent": !sublabel
      }),
      media: classnames("w-100 relative order-0", {
        // "order-1-ns": isLeft,
        // "order-0-ns": isRight
      }),
      checkoutButton: "w-100 dib w4",
      contactless: classnames(
        "pa2 bg-white br2 flex-row absolute left-1 bottom-1 z-1",
        {
          flex: contactless,
          dn: !contactless
        }
      ),
      image: shouldRenderImage
        ? "relative overflow-hidden bl br bt hairline-1 br2"
        : "w-100 bg-white",
      discount: "pa2 bg-white br2 flex flex-row absolute right-1 bottom-1",
      discountValue: "pl1 f6 fw5 black-80"
    },
    editorViewport
  );

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
      value: index
    }
  ];

  const hideContactless = Boolean(!liveUrl && !size);
  const hideImage = isList && !shouldRenderImage;
  const hideDiscount = !discount;
  const hideMedia = hideContactless && hideImage && hideDiscount;

  const checkoutProps = actions[0]
    ? {
        uuid,
        action: actions[0],
        cart: {
          products: [uuid]
        },
        name: rootName,
        isLive,
        customClasses: {
          container: "relative center lh-copy w-100 pt3"
        }
      }
    : null;

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes, edit } = {}) => (
        <div
          key={uuid}
          className={`${compClasses.container} ${classes}`}
          ref={ref}
          onClick={onClick}
        >
          <div className={compClasses.wrapper}>
            <div className={compClasses.content}>
              <div className="flex flex-column w-100">
                <div
                  className={classnames(compClasses.title, {
                    dn: !title && !edit
                  })}
                >
                  <EditableContent
                    editable={edit}
                    sync={{
                      name: `${name}.title`
                    }}
                  >
                    {title}
                  </EditableContent>
                </div>
                <div
                  className={classnames(compClasses.description, {
                    dn: !title && !edit
                  })}
                >
                  <EditableContent
                    editable={edit}
                    sync={{
                      name: `${name}.description`
                    }}
                  >
                    {description}
                  </EditableContent>
                </div>
                <div className={compClasses.labelWrapper}>
                  <div className={compClasses.label}>
                    {label || TRANSPARENT_CHAR}
                    {labelNote && (
                      <div className={compClasses.labelNote}>{labelNote}</div>
                    )}
                  </div>
                  <div className={compClasses.sublabel}>
                    {sublabel || TRANSPARENT_CHAR}
                  </div>
                </div>
                {!contactless && checkoutProps && (
                  <div className={compClasses.checkoutButton}>
                    <FormerCartCheckoutAction {...checkoutProps} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {!hideMedia ? (
            <div className={compClasses.media}>
              {!hideContactless ? (
                <div className={compClasses.contactless}>
                  {liveUrl && size && <QRCodeStyled {...qrCodeProps} />}
                </div>
              ) : null}
              {!hideImage ? (
                shouldRenderImage ? (
                  <div
                    className={compClasses.image}
                    style={{ marginBottom: -4 }}
                  >
                    <Image {...imageProps} />
                  </div>
                ) : (
                  <div
                    className={compClasses.image}
                    style={imageDimensions}
                  ></div>
                )
              ) : null}
              {!hideDiscount ? (
                <div className={compClasses.discount}>
                  <DiscountIcon size={14} />
                  <div className={compClasses.discountValue}>{discount}</div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </ReflectFocus>
  );
};

export default ProductCollectionItem;
