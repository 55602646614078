import React, { useEffect } from "react";
import { shortLinkToSessionInput } from "utils/checkout";
import { getConfig } from "utils/env";
import Button from "components/Form/fields/Button";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import { PriceBlocs } from "@priceblocs/react-priceblocs-js";
import FormattedToast from "components/FormattedToast";
import { useToasts } from "react-toast-notifications";
import { TOAST_ERROR_CONFIG } from "utils/constants/ui";
import { useFormikContext } from "formik";
import { setStorePageBuilder } from "utils/builder";

const DEFAULT_CHECKOUT_CONFIG = {
  fetch: {
    on_mount: true
  }
};

const UnauthCheckoutButton = ({
  props,
  shortLink,
  apiKey = getConfig("PRICE_BLOCS_PUB_KEY_TEST"),
  config = DEFAULT_CHECKOUT_CONFIG,
  customer
}) => {
  const { addToast } = useToasts();
  const { values } = useFormikContext();

  return (
    <PriceBlocs
      /**
       * VIP: Needs to be PriceBlocs client key and not a Stripe publishable key
       */
      api_key={apiKey}
      /**
       * Note: override config here to prevent remount issue for page
       * - instead we manually call refetch
       */
      config={config}
    >
      {({
        checkout: pbCheckout,
        loading: pbLoading,
        isSubmitting,
        error: pbError,
        ready,
        stripe
      }) => {
        const ctxProps = cloneDeep(props);
        const sessionInput = shortLinkToSessionInput(shortLink);
        if (customer) {
          for (const key in customer) {
            if (customer[key]) {
              sessionInput[key] = customer[key];
            }
          }
        }

        /**
         * Trigger an error notification when error is:
         * - present &&
         * - uuid changes
         */
        const errorId = pbError && pbError.uuid;
        useEffect(() => {
          errorId &&
            pbError.message &&
            addToast(
              <FormattedToast type="error" copy={pbError.message} />,
              TOAST_ERROR_CONFIG
            );
        }, [errorId]);

        ctxProps.onClick = () => {
          /**
           * Sync current manifest values to local storage
           * We retrieve this when we load the page so that state is not lost
           */
          const templateUUID = values.uuid;
          if (templateUUID) {
            setStorePageBuilder({ key: templateUUID, value: values });
          }
          pbCheckout(sessionInput);
        };
        ctxProps.loading = isSubmitting;
        const hasError = !isEmpty(pbError);
        if (hasError) {
          console.error("Unauth checkout error", pbError.message);
          process.env.NODE_ENV === "development" &&
            console.error(
              "Ensure react-priceblocs-js is linked locally `npm run relink`"
            );
        }
        /**
         * Check PB functional component internal ready state is in good standing
         */
        const enabled = Boolean(ready && stripe);

        if (!ctxProps.disabled) {
          ctxProps.disabled = pbLoading || isSubmitting || !enabled;
        }

        return <Button {...ctxProps} />;
      }}
    </PriceBlocs>
  );
};

export default UnauthCheckoutButton;
