import React from "react";
import classnames from "classnames";
import Pill from "components/Pill";
import { FORMER_MAX_WIDTH, BLOCKS_PREFIX } from "utils/constants/ui";
import { setHrefWithType } from "utils/url";
import { STATE_KEYS } from "utils/constants/state";
import BrandLogo from "components/BrandLogo";
import ReflectFocus from "components/ReflectFocus";
import EditableContent from "components/EditableContent";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { usePageFactoryContext } from "utils/context";
import { getFieldNameRoot, getFieldPath } from "utils/form";

const FooterSections = ({ sections, edit, name }) => {
  return sections.map((section, sectionIx) => {
    const headerPath = `${name}.sections[${sectionIx}].header`;
    return (
      <ul
        key={sectionIx}
        className="list w-100 w-auto-ns ph4-ns ma0 pa0 dib flex flex-column"
      >
        <li
          className={classnames("fl v-top flex flex-column w-100", {
            "pt3 pt0-ns mt2 mt0-ns": sectionIx > 0
          })}
        >
          <div className="f5 fw5 pb2">
            <EditableContent editable={edit} sync={{ name: headerPath }}>
              {section.header}
            </EditableContent>
          </div>
          {section.links.map(
            ({ href, copy, onClick, callout, hrefType }, sectionLinkIx) => {
              let content = null;

              const copyPath = `${name}.sections[${sectionIx}].links[${sectionLinkIx}].copy`;

              if (href) {
                const hrefWithType = setHrefWithType({ href, hrefType });
                const commonProps = {
                  className: `link f6 dib pointer pv2 mv1 fw5 ${BLOCKS_PREFIX}link__decoration--hover`
                };

                /**
                 * When edit we wrap in a div instead of an anchor
                 * - Why? - We don't want an edit click to trigger a nav event
                 */
                content = edit ? (
                  <div {...commonProps}>
                    <EditableContent editable={edit} sync={{ name: copyPath }}>
                      {copy}
                    </EditableContent>
                  </div>
                ) : (
                  <a href={hrefWithType} {...commonProps}>
                    {copy}
                  </a>
                );
              }

              return (
                <div
                  key={sectionLinkIx}
                  className="w-100 flex flex-row items-center"
                >
                  {content}
                  {callout && (
                    <Pill
                      copy={callout}
                      customClasses={{
                        container: "pl2",
                        copy: "f7 pa1 br2 fw5 flex-shrink-0 ph2 fw6"
                      }}
                    />
                  )}
                </div>
              );
            }
          )}
        </li>
      </ul>
    );
  });
};

const FormerFooter = ({
  uid,
  name,
  entity,
  data: { brand, sections },
  ui,
  entityRef
}) => {
  const focus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    }
  ];

  const { stateKey } = usePageFactoryContext();
  const contentName = `content.${name}`;
  const nameRoot = `${stateKey}.${contentName}`;
  const { values } = useFormikContext();
  const configPath = getFieldPath(stateKey, `${contentName}.config`);
  const sectionsName = getFieldPath(stateKey, `${contentName}.data`);

  const showBrandPath = `${configPath}.${STATE_KEYS.ENTITY_CONFIG.BRAND_SHOW}`;
  const showBrand = Boolean(get(values, showBrandPath));
  const hasContent = showBrand || (sections && sections.length);
  if (!hasContent) {
    return null;
  }

  return (
    <ReflectFocus focus={focus} name={entity.schema.title} withHeader>
      {({ hover, edit } = {}) => (
        <div
          id={nameRoot}
          ref={entityRef}
          className={classnames(
            "w-100 flex flex-row justify-between items-center relative pv3 bt hairline-1",
            {
              "bg-white": !hover
            }
          )}
        >
          <div
            className="w-100 center w-100 center flex flex-row justify-between ph3-ns"
            style={{ maxWidth: FORMER_MAX_WIDTH }}
          >
            <footer className="pa3 ph0-ns pv4-m pv4-l flex flex-column flex-row-ns justify-between w-100">
              {showBrand && (
                <ul className="list flex flex-column flex-row-l justify-between items-start pa0 ma0">
                  <li className="di flex w-100 items-center">
                    <BrandLogo brand={brand} />
                  </li>
                </ul>
              )}
              <ul className="list ma0 pa0 flex flex-column flex-row-l w-100 pb3 pt4 pt0-ns pb4-l justify-end">
                <FooterSections
                  edit={edit}
                  sections={sections}
                  name={sectionsName}
                />
              </ul>
            </footer>
          </div>
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormerFooter;
