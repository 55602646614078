import React from "react";
import Image from "components/Image";
import { PRIORITY_FIT_CONTAIN } from "utils/constants/ui";

const IMAGE_DIMENSIONS = { height: 16, width: 116 };
const POWERED_BY_GOOGLE_PATH = "/assets/powered_by_google_on_white_hdpi.png";

const GoogleBrand = () => (
  <div style={IMAGE_DIMENSIONS} className="bg-transparent br2 overflow-hidden">
    <Image
      src={POWERED_BY_GOOGLE_PATH}
      alt="powered-by-google"
      {...IMAGE_DIMENSIONS}
      {...PRIORITY_FIT_CONTAIN}
    />
  </div>
);

export default GoogleBrand;
