import { reduceShortLinkValidations, validateShortLink } from "utils/shortLink";
import { getCheckoutCartShortLinkProduct } from "utils/checkout";
import { usePageFactoryContext } from "utils/context";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import get from "lodash/get";

export const useEntityCartValidationErrors = ({ name, cart }) => {
  const { values } = useFormikContext();
  const { stateKey } = usePageFactoryContext();

  const manifestDataPath = getFieldPath(stateKey, "data");
  const manifestData = get(values, manifestDataPath);

  const contentRoot = getFieldPath(stateKey, `content.${name}`);
  const entityContent = get(values, contentRoot);

  const shortLink = {
    data: {
      product: getCheckoutCartShortLinkProduct({
        content: entityContent,
        cart
      })
    }
  };

  const linkValidations = validateShortLink({ shortLink, ...manifestData });

  return reduceShortLinkValidations(linkValidations);
};
