import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { useEditorViewport } from "utils/hooks";
import Overlay from "components/Overlay";
import classnames from "classnames";
import Pill from "components/Pill";
import { PILL_THEME } from "components/Pill";
import { processViewportClasses } from "utils/editor";
import { PRIORITY_FIT_COVER } from "utils/constants/ui";
import Image from "components/Image";
import CheckoutPreview from "components/CheckoutPreview";
import { cellGrow } from "constants/styles";
import { getCheckoutPreviewSublabel } from "components/FormerEditor/common/preview";
import { getIconForUID } from "components/FormerEditor/utils";

export const IMAGE_DIMENSIONS = { height: 54, width: 54 };

const PageBarCheckoutPreview = (props) => {
  const {
    badge,
    banner,
    lineItems,
    children,
    showOverlay,
    setShowOverlay
  } = props;
  const editorViewport = useEditorViewport();
  const firstLineItem = lineItems[0];
  const { image, label } = firstLineItem || {};
  const hasBannerLabel = banner && banner.label;
  const hasBannerTitle = banner && banner.title;
  const hasBadgeLabel = badge && badge.label;

  const compClasses = processViewportClasses(
    {
      image: "flex items-center",
      title: "f6 fw5 tl pb1 black-60 truncate w-90",
      label: `${hasBannerTitle ? "f6" : "f5"} tl fw6 truncate`,
      sublabel: "f7 tl black-60 pt1 truncate"
    },
    editorViewport
  );

  const pillBadge = hasBadgeLabel && (
    <Pill
      theme={PILL_THEME.SLIM}
      status={badge.status}
      copy={badge.label}
      customClasses={{
        container: "flex-shrink-0",
        copy: "f7 pa2 br2 fw6 flex-shrink-0 items-center"
      }}
    />
  );

  const containerProps = {
    className: "flex flex-row w-full relative cell-grow pa2 items-start",
    onClick: () => setShowOverlay(true)
  };

  const sublabel = getCheckoutPreviewSublabel({ banner, preview: props });
  const Icon = getIconForUID("down");

  return (
    <Fragment>
      <div {...containerProps}>
        {image ? (
          <div className={`${compClasses.image} relative`}>
            <div
              style={IMAGE_DIMENSIONS}
              className="bg-transparent br2 overflow-hidden"
            >
              <Image
                src={image}
                {...IMAGE_DIMENSIONS}
                alt={label}
                {...PRIORITY_FIT_COVER}
              />
            </div>
          </div>
        ) : null}
        <div className="w-100 flex flex-row items-end">
          <div
            className={classnames("w-100 flex flex-row relative", {
              "justify-end pb3": !hasBannerLabel && hasBadgeLabel
            })}
          >
            {hasBannerLabel && (
              <div
                className={classnames("w-100", {
                  pl2: image,
                  pl1: !image
                })}
              >
                {banner.title && (
                  <div className={compClasses.title}>{banner.title}</div>
                )}
                {banner.label && (
                  <div className={compClasses.label}>{banner.label}</div>
                )}
                {sublabel && (
                  <div className={compClasses.sublabel}>{sublabel}</div>
                )}
              </div>
            )}
            <div className="flex-shrink-0">{pillBadge}</div>
          </div>
          <div className="absolute top-0 right-0">
            <div className="pt1 pr2">
              <Icon size={24} />
            </div>
          </div>
        </div>
        <style jsx>{cellGrow}</style>
      </div>
      {showOverlay &&
        ReactDOM.createPortal(
          <Overlay hideOverlay={() => setShowOverlay(false)}>
            <div className="pa3">
              <CheckoutPreview {...props} />
              <div className="pt3">{children}</div>
            </div>
          </Overlay>,
          document.body
        )}
    </Fragment>
  );
};

export default PageBarCheckoutPreview;
