import React, { Fragment } from "react";
import ReflectFocus from "components/ReflectFocus";
import { getGridClass } from "../utils";
import { usePageFactoryContext } from "utils/context";
import EmptyFeaturesListPrompt from "./EmptyFeaturesListPrompt";
import FeatureListItem from "./FeatureListItem";
import classnames from "classnames";
import { FEATURES_ACTIVE_SECTION_FOCUS } from "utils/constants/focus";
import { ENTITY_HOVER_UNDERLINE } from "utils/constants/ui";

const FeaturesList = ({ productIndex, title, items, focus, isEmphasized }) => {
  const { canActivateSettings } = usePageFactoryContext();

  const hasItems = items && items.length > 0;
  const showDevPrompt = canActivateSettings && !hasItems;
  const titleClasses = `bt hairline-1 f5 fw5 lh-title center w-100 ${getGridClass(
    {
      suffix: `topFeatures_title--${productIndex}`,
      isEmphasized
    }
  )}`;
  const blockClasses = `${getGridClass({
    suffix: `topFeatures--${productIndex}`,
    isEmphasized
  })} ph3 center w-100`;

  let blockFocus;
  let titleContent;
  let blockContent;
  if (showDevPrompt) {
    blockFocus = [FEATURES_ACTIVE_SECTION_FOCUS];
    titleContent = (
      <div className="pt4 pb3 w-100 pl3">
        <div className="h2 w-70 bg-black-05" />
      </div>
    );
    blockContent = <EmptyFeaturesListPrompt />;
  } else {
    blockFocus = focus;
    if (hasItems) {
      titleContent = <div className="pb3 ph3">{title}</div>;
      blockContent = (
        <ul className="list ma0 pa0">
          {items.map(
            ({ active, title, uuid, hideIcon, tooltip }, featureIx) => (
              <FeatureListItem
                key={featureIx}
                hideIcon={hideIcon}
                active={active}
                title={title}
                tooltipId={`${productIndex}.${featureIx}.${uuid}`}
                tooltip={tooltip}
              />
            )
          )}
        </ul>
      );
    }
  }

  return (
    <Fragment>
      {title && (
        <ReflectFocus focus={blockFocus} classes={ENTITY_HOVER_UNDERLINE}>
          {({ onClick, classes } = {}) => (
            <div
              onClick={onClick}
              className={classnames(`${titleClasses} ${classes}`, {
                pt4: hasItems
              })}
            >
              {titleContent}
            </div>
          )}
        </ReflectFocus>
      )}
      {items && (
        <ReflectFocus focus={blockFocus} classes={ENTITY_HOVER_UNDERLINE}>
          {({ onClick, classes } = {}) => (
            <div
              onClick={onClick}
              className={classnames(`${blockClasses} ${classes}`, {
                pb3: hasItems
              })}
            >
              {blockContent}
            </div>
          )}
        </ReflectFocus>
      )}
    </Fragment>
  );
};
export default FeaturesList;
