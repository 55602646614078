import React from "react";
import { STATE_KEYS } from "utils/constants/state";
import SlimCTA from "components/SlimCTA";
import { setEditorFocus } from "utils/builder";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import { addModel } from "utils/drag";
import { getSchemaValuesForEditor } from "utils/schema";
import { FIELD_GROUP_INTENT } from "utils/constants/fields";
import { BUTTON_CLASS_CONTEXT } from "utils/constants/ui";

const AddFieldGroupCTA = ({ entity, focus }) => {
  const { values, setValues, setFieldTouched } = useFormikContext();
  const name = `${STATE_KEYS.MANIFEST.CONTENT}.${entity.uuid}`;

  const propertyKey = "fieldGroups";
  const { rootPath, template } = getSchemaValuesForEditor({
    name,
    propertyKey,
    schema: entity.schema
  });
  const fieldGroupsPath = getFieldPath(rootPath, propertyKey);

  const addFieldGroup = addModel({
    values,
    setValues,
    setFieldTouched,
    template,
    modelsPath: fieldGroupsPath,
    stateKey: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX
  });

  return (
    <SlimCTA
      header="No fields"
      customClasses={{
        container:
          "w-100 flex flex-column flex-row-ns justify-between ba br2 hairline-1-dashed pa3 items-start items-center-ns bg-white"
      }}
      subheader="Add a field to your form."
      actions={[
        {
          copy: "Add",
          classes: {
            contextClass: BUTTON_CLASS_CONTEXT.SECONDARY
          },
          onClick: () => {
            addFieldGroup();

            setEditorFocus({
              values,
              setValues,
              focus: [
                ...focus,
                {
                  key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
                  value: 0
                },
                {
                  key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
                  value: FIELD_GROUP_INTENT.ADD_FIELD
                },
                {
                  key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
                  value: 0
                }
              ]
            })();
          }
        }
      ]}
    />
  );
};

export default AddFieldGroupCTA;
