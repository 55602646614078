import React from "react";
import { getFeatureComparisonHeader, UI_THEME } from "utils/constants/ui";
import IntervalToggle from "components/FormerPricingTable/IntervalToggle";
import FormattedPriceHeader from "components/FormerPricingTable/FormattedPriceHeader";
import { deconstructFormattedPrice } from "utils/price";
import ProductActions from "components/FormerPricingTable/ProductActions";
import { BLOCKS_IS_HIGHLIGHTED } from "utils/constants/ui";
import classnames from "classnames";
import { usePageFactoryContext } from "utils/context";

const TableHeader = ({ header, entity, afterChange, emphasisIndex }) => {
  const pageFactoryContext = usePageFactoryContext();
  const canActivateSettings = pageFactoryContext
    ? pageFactoryContext.canActivateSettings
    : false;
  const tracking = pageFactoryContext ? pageFactoryContext.tracking : {};

  const topStyles = canActivateSettings
    ? { top: 0 }
    : getFeatureComparisonHeader(tracking.subdomain);

  return (
    <thead>
      <tr className="ph3 bg-white">
        <td
          className="fw6 ph3 pv3 hairline-1 br bb z-1 sticky bg-white"
          style={topStyles}
        >
          <IntervalToggle
            entity={entity}
            afterChange={afterChange}
            customClasses={{
              copy: "f6 fw5",
              subtext: "dn"
            }}
          />
        </td>
        {header &&
          header.map(({ title, formattedPrice, actions }, headerIx) => {
            const { symbol, formatted } = deconstructFormattedPrice(
              formattedPrice
            );
            const isEmphasized = emphasisIndex === headerIx;
            return (
              <th
                key={headerIx}
                className={classnames(
                  "tableHeader hairline-1 z-1 sticky bb bt br",
                  {
                    [BLOCKS_IS_HIGHLIGHTED]: isEmphasized,
                    "bg-white": !isEmphasized
                  }
                )}
                style={topStyles}
              >
                <div className="f4 f3-ns pa3 fw6">{title}</div>
                {formatted && (
                  <FormattedPriceHeader
                    formatted={formatted}
                    symbol={symbol}
                    customClasses={{
                      container: "fw4 f5 pb3 tc flex flex-row justify-center",
                      symbol: "f5 pr1",
                      price: "fw5 f3 lh-solid",
                      interval: "f5 pl1 mt1"
                    }}
                  />
                )}
                <ProductActions
                  actions={actions}
                  theme={UI_THEME.SLIM}
                  customClasses={{
                    container: "relative center lh-copy w-100 pt0 pb3"
                  }}
                  productIndex={headerIx}
                  isEmphasized={isEmphasized}
                />
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

export default TableHeader;
