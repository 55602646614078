import isEmpty from "lodash/isEmpty";
import camelCase from "lodash/camelCase";
import pick from "lodash/pick";
import { useRouter } from "next/router";
import PATHS from "utils/paths";
import { REDIRECT_KEYS } from "utils/url";

export const useIsScratch = () => {
  const { pathname } = useRouter();

  return PATHS.SCRATCH === pathname;
};

export const useRouteConfigParams = () => {
  const { query } = useRouter();
  let result = null;
  const params = pick(query, REDIRECT_KEYS);
  if (!isEmpty(params)) {
    result = {};
    for (const key in params) {
      // Note: API currently expects camelCased cancelUrl
      result[camelCase(key)] = params[key];
    }
  }

  return result;
};
