import React, { useState, useEffect, useRef, useMemo } from "react";
import PhoneInput, {
  parsePhoneNumber,
  isPossiblePhoneNumber
} from "react-phone-number-input";
import { useDebounce } from "utils/hooks";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { DEFAULT_DEBOUNCE } from "utils/constants/form";
import { AUTO_ATTRS } from "components/Form/fields/constants";
import { getFieldNameRoot } from "utils/form";
import LabelDescription from "components/Form/fields/LabelDescription";

const DEFAULT_COUNTRY = "US";

/**
 * Docs
 * https://catamphetamine.gitlab.io/react-phone-number-input/docs/#phoneinputwithcountry
 */
const DebouncePhone = ({
  uid,
  edit,
  inputValue,
  valueSet,
  debounce,
  onDebounce,
  placeholder,
  label,
  description,
  name,
  hideError,
  innerRef,
  error,
  required,
  disabled,
  ...props
}) => {
  const [firstMount, setFirstMount] = useState(false);
  const formRef = useRef(null);
  const ctxRef = innerRef || formRef;
  const { touched, errors, values } = useFormikContext();
  const ctxError = error || get(errors, name);
  const formVal = get(values, name);
  const isTouched = Boolean(get(touched, name));
  const showError = Boolean(isTouched && ctxError && !hideError);
  const nameRoot = getFieldNameRoot(name);
  const [value, setValue] = useState(inputValue || "");
  const defaultCountry = useMemo(() => {
    let result = DEFAULT_COUNTRY;
    try {
      if (inputValue) {
        const parsedNumber = parsePhoneNumber(inputValue);
        if (parsedNumber && parsedNumber.country) {
          result = parsedNumber.country;
        }
      }
    } catch (error) {
      result = DEFAULT_COUNTRY;
    }
    return result;
  }, [inputValue]);

  const ctxDebounce = debounce || DEFAULT_DEBOUNCE;
  useEffect(() => {
    if (firstMount) {
      valueSet(value);
      onDebounce && onDebounce(value);
    } else {
      setFirstMount(true);
    }
  }, [useDebounce(value, ctxDebounce)]);

  useEffect(() => {
    if (!name) {
      console.warn("Need a name to correctly unset phone field");
    }
    if (!formVal && ctxRef.current && ctxRef.current.value) {
      ctxRef.current.value = "";
    }
  }, [formVal]);

  /**
   * If the uid has changed (e.g. updated in form state)
   * And if its different to the input value
   * Then set value to input value as its the source of truth when it has changed (i.e. after an external value set event)
   */
  useEffect(() => {
    if (uid && inputValue !== value) {
      setValue(inputValue);
    }
  }, [useDebounce(uid, 0)]);
  const ctxDisabled = Boolean(disabled);

  const fieldInput = {
    countrySelectProps: {
      unicodeFlags: true
    },
    international: true,
    countryCallingCodeEditable: false,
    placeholder,
    value,
    disabled: ctxDisabled,
    defaultCountry,
    ...AUTO_ATTRS,
    onChange: (phoneNumber) => {
      setValue(phoneNumber);
    },
    ref: ctxRef,
    ...props
  };

  let isPossible = true;
  if (value && !isPossiblePhoneNumber(value) && isTouched) {
    isPossible = false;
  }

  let sublabelContent;
  if (showError) {
    sublabelContent = (
      <div className="field__sublabel color-error">{error}</div>
    );
  } else if (!isPossible) {
    sublabelContent = (
      <div className="field__sublabel">Please check number</div>
    );
  }

  return (
    <div className="w-100 flex flex-column">
      <LabelDescription
        nameRoot={nameRoot}
        edit={edit}
        label={label}
        description={description}
        showError={showError}
        required={required}
      />
      <div className="dib relative">
        {ctxDisabled && (
          <div className="absolute brtl-6 brbl-6 phoneCountrySelect--disabled" />
        )}
        <PhoneInput {...fieldInput} />
      </div>
      {sublabelContent}
    </div>
  );
};
export default DebouncePhone;
