import VideoModal from "components/VideoModal";
import React from "react";
import AddFieldGroupFieldCTA from "./AddFieldGroupFieldCTA";

const EmptyPrompt = ({ focus, commandId, header, subheader, actions }) => {
  const ctxExtraActions = Array.isArray(actions) ? actions : [];
  if (commandId) {
    ctxExtraActions.push(
      <div key={commandId} className="ml2 flex">
        <VideoModal copy="Learn" id={commandId} />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-100 h-auto bg-white">
      <div className="mw6 w-100">
        <AddFieldGroupFieldCTA
          header={header}
          customClasses={{
            header: "f3 black-70 fw6",
            subheader: "f5 black-70 lh-copy"
          }}
          subheader={subheader}
          focus={focus}
          extraActions={ctxExtraActions}
        />
      </div>
    </div>
  );
};

export default EmptyPrompt;
