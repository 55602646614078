import { useRouter } from "next/router";
import QS from "qs";
const MIN_NOTICE_KEY = "notice[type]";
export const useFormattedNotice = () => {
  let result;
  const router = useRouter();

  if (router.query && router.query[MIN_NOTICE_KEY]) {
    try {
      /**
       * Need to increase the parsing depth so that content is properly formatted for entry into editor
       * - https://github.com/ljharb/qs#parsing-objects
       */
      const parsed = QS.parse(router.query, { depth: 10 });
      result = parsed.notice;
    } catch (error) {
      console.error("Parse notice error");
    }
  }

  return result;
};
