import { reorder, LIST_DESTINATIONS } from "utils/drag";
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";
import { PRICING_TABLE_PRODUCT_LIMIT_MAX } from "components/FormerEditor/common/constants";
import ENTITIES from "components/FormerEditor/common/constants/entity";
import {
  recomputePricingTableContent,
  recomputeFeatureComparisonContent
} from "components/FormerEditor/common";
import get from "lodash/get";
import set from "lodash/set";
import { prepareInitialValuesFromPrice } from "utils/price";
import Analytics from "utils/analytics";

const PRODUCT_FEATURES = {
  [process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID_VIEWER]: [
    { active: true, label: "View team dashboards" },
    { active: false, label: "Create team dashboards" },
    { active: false, label: "Create projects for teams" },
    { active: true, label: "Share in Slack" },
    { active: false, label: "Project to TV" }
  ],
  [process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID_CREATOR]: [
    { active: true, label: "View team dashboards" },
    { active: true, label: "Create team dashboards" },
    { active: true, label: "Create projects for teams" },
    { active: true, label: "Share in Slack" },
    { active: true, label: "Project to TV" }
  ]
};

export const buildPricingTableConfig = (products) => {
  return products.map(({ id, name, description, prices }, index) => {
    return {
      columnKey: index,
      name,
      description: description,
      elementClasses: "br hairline-1 ph3",
      prices: prices,
      features: PRODUCT_FEATURES[id]
    };
  });
};

const ensureLayoutInProducts = (allProducts, layout) => {
  return compact(layout).reduce((memo, data) => {
    if (allProducts.indexOf(data.uid) > -1) {
      memo.push(data);
    }
    return memo;
  }, []);
};

export const reorderPricingLayout = ({
  layout,
  tracking,
  layoutPath,
  allProductUIDs,
  remainingProducts,
  setFieldValue,
  setFieldTouched,
  layoutMax = PRICING_TABLE_PRODUCT_LIMIT_MAX
}) => ({ source, destination }) => {
  const droppedOffList = !destination;
  if (droppedOffList) {
    return;
  }
  tracking && Analytics.trackEventWithData(tracking);
  const {
    droppableId: destinationDropId,
    index: destinationIndex
  } = destination;
  const { droppableId: sourceDropId, index: sourceIndex } = source;

  const updatedLayout = cloneDeep(layout);

  /**
   * Drop has happened between two lists so an object is being moved
   */
  const changedList = sourceDropId !== destinationDropId;
  if (changedList) {
    const FROM_PRICING_TABLE =
      destinationDropId === LIST_DESTINATIONS.PRODUCT_STORE;
    const TO_PRICING_TABLE =
      destinationDropId === LIST_DESTINATIONS.PRICING_TABLE;

    const canAddToTable = layout.length < layoutMax;

    if (FROM_PRICING_TABLE) {
      updatedLayout.splice(sourceIndex, 1);
      setFieldValue(
        layoutPath,
        ensureLayoutInProducts(allProductUIDs, updatedLayout)
      );
      setFieldTouched(layoutPath, true);
    } else if (TO_PRICING_TABLE && canAddToTable) {
      const productInMotion = remainingProducts[sourceIndex];
      const newProdLayout = {
        uid: productInMotion.id
      };
      updatedLayout.splice(destinationIndex, 0, newProdLayout);
      setFieldValue(
        layoutPath,
        ensureLayoutInProducts(allProductUIDs, updatedLayout)
      );
      setFieldTouched(layoutPath, true);
    } else if (!TO_PRICING_TABLE && !FROM_PRICING_TABLE) {
      throw new Error(`Invalid destination: ${destinationDropId}`);
    }
  } else {
    const reorderedLayout = reorder(
      updatedLayout,
      source.index,
      destination.index
    );
    setFieldValue(
      layoutPath,
      ensureLayoutInProducts(allProductUIDs, reorderedLayout)
    );
    setFieldTouched(layoutPath, true);
  }
};

/**
 * Toggling the interval will require a recomputation of the values on the page so that the click actions will use the right product uid on checkout click
 */
export const recomputePricing = ({ values, setValues }) => {
  const pricingTableEntity = values.entities.find(
    ({ uid }) => uid === ENTITIES.PRICING_TABLE
  );
  let hasUpdates = false;

  if (pricingTableEntity && pricingTableEntity.uuid) {
    hasUpdates = true;
    const pricingTablePath = `content[${pricingTableEntity.uuid}].data`;
    const pricingTableData = get(values, pricingTablePath);

    const input = {
      entity: pricingTableEntity,
      data: pricingTableData,
      config: values.config,
      admin: values.admin,
      checkout: values.checkout
    };

    const recomputedPricingTable = recomputePricingTableContent(input);

    set(values, pricingTablePath, recomputedPricingTable);
  }

  const featureCompEntity = values.entities.find(
    ({ uid }) => uid === ENTITIES.FEATURE_COMPARISON
  );

  if (featureCompEntity && featureCompEntity.uuid) {
    hasUpdates = true;
    const featureCompPath = `content[${featureCompEntity.uuid}].data`;
    const featureCompContent = get(values, featureCompPath);

    const input = {
      entity: featureCompEntity,
      data: featureCompContent,
      config: values.config,
      entities: values.entities,
      products: values.data.products,
      prices: values.data.prices,
      content: values.content,
      admin: values.admin,
      checkout: values.checkout
    };

    const recomputedFeatureComp = recomputeFeatureComparisonContent(input);
    set(values, featureCompPath, recomputedFeatureComp);
  }

  if (hasUpdates) {
    setValues(values);
  }
};

export const getPrimaryForProductIntervalCurrency = ({
  primaries,
  interval,
  currency,
  product
}) => {
  return primaries.find(
    (primary) =>
      primary.interval === interval &&
      primary.product === product &&
      primary.currency === currency
  );
};

export const setPrimaryPriceForInterval = ({
  primaries,
  choice,
  setFieldValue,
  setFieldTouched,
  stateKey,
  tracking
}) => () => {
  tracking && Analytics.trackEventWithData(tracking);
  const primaryReplacementIndex = primaries.findIndex(
    (primary) =>
      primary.product === choice.product &&
      primary.interval === choice.interval &&
      primary.currency === choice.currency
  );

  const choiceIsTheReplacement =
    primaries[primaryReplacementIndex] &&
    primaries[primaryReplacementIndex].price === choice.price;

  if (primaryReplacementIndex > -1) {
    primaries.splice(primaryReplacementIndex, 1);
  }

  if (!choiceIsTheReplacement) {
    primaries.push(choice);
  }

  setFieldValue(stateKey, primaries);
  setFieldTouched(stateKey, true);
};

export const separateNewAndExistingPrices = (prices) => {
  const result = {
    newPrices: [],
    existingPrices: []
  };
  if (prices) {
    const reducedPrices = prices.reduce(
      (memo, price) => {
        if (price.recurring) {
          if (price.id) {
            if (price.active) {
              memo.existingPrices.push(price);
            }
          } else {
            memo.newPrices.push(price);
          }
        }

        return memo;
      },
      {
        newPrices: [],
        existingPrices: []
      }
    );
    result.newPrices = reducedPrices.newPrices;
    result.existingPrices = reducedPrices.existingPrices;
  }

  return result;
};

export const toggleProductSelect = ({
  products,
  prices,
  selectedProducts,
  maxProductsReached,
  setFieldValue
}) => (productId) => {
  const productMatch = products.find((product) => product.id === productId);
  const productIndex = selectedProducts.findIndex(({ id }) => id === productId);
  const updatedProducts = [...selectedProducts];

  if (productIndex > -1) {
    updatedProducts.splice(productIndex, 1);
  } else if (!maxProductsReached && productMatch) {
    const selectedProduct = {
      ...productMatch,
      prices: prices
        .filter((price) => price.product === productMatch.id)
        .map((price) => prepareInitialValuesFromPrice(price))
    };

    updatedProducts.push(selectedProduct);
  }

  setFieldValue("products", updatedProducts);
};
