import React, { useRef } from "react";
import { usePageBuilderContext } from "utils/context";

/**
 * Create and store a reference to an entity so we can access it in other parts of the app
 * @param {Node} children
 * @param {String} uuid - entity uuid to register
 */
const RegisterBlock = ({ children, uuid }) => {
  const entityRef = useRef(null);
  const builderContext = usePageBuilderContext();

  if (
    builderContext &&
    builderContext.blockRegistry &&
    builderContext.setBlockRegistry
  ) {
    const { blockRegistry, setBlockRegistry } = builderContext;

    blockRegistry[uuid] = entityRef;
    setBlockRegistry(blockRegistry);
    return children({ entityRef });
  } else {
    return children({});
  }
};

export default RegisterBlock;
