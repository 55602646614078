import React from "react";
import upperFirst from "lodash/upperFirst";
import HoverIconButton from "components/HoverIconButton";
import {
  EDITABLE_CONTENT_ACTION,
  EDITABLE_CONTENT_MENU_CONFIG
} from "utils/constants/editor";

const EditableContentMenuBar = (
  { editor, menu, actions } = {
    editor: null,
    menu: EDITABLE_CONTENT_MENU_CONFIG
  }
) => {
  if (!editor) {
    return null;
  }
  const menuConfig = menu || EDITABLE_CONTENT_MENU_CONFIG;

  const allNodes = [];
  for (let index = 0; index < menuConfig.length; index++) {
    const { command, label, action, props, icon, getDisabled } = menuConfig[
      index
    ];
    const commandName = upperFirst(command);
    const actionName = action ? `${action}${commandName}` : command;

    const defaultOnClick = () =>
      editor.chain().focus()[actionName](props).run();
    let onClick = defaultOnClick;
    if (actions && actions[actionName]) {
      onClick = actions[actionName];
    }

    const nodeProps = {
      icon: icon || command,
      size: 16,
      tooltip: label || commandName,
      onClick
    };
    if (getDisabled) {
      nodeProps.disabled = getDisabled(editor);
    }
    if (action === EDITABLE_CONTENT_ACTION.SET) {
      nodeProps.className = editor.isActive(command) ? "is-active" : "";
    }

    allNodes.push(
      <div key={actionName} className="dib">
        <HoverIconButton {...nodeProps} />
      </div>
    );
  }

  return <div className="flex flex-wrap">{allNodes}</div>;
};

export default EditableContentMenuBar;
