import React from "react";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { getFieldPath } from "utils/form";
import { getPricesCurrencyOptions } from "utils/price";
import set from "lodash/set";
import {
  CONFIG_PRICING_DEFAULT_CURRENCY,
  PRICE_DATA
} from "utils/constants/state";
import { usePageFactoryContext } from "utils/context";
import Select from "components/Form/fields/Select";
import { usePricingCurrencyOptions } from "utils/hooks/pricing";

const DEFAULT_CLASSES = {
  container: "flex flex-column center items-center"
};

const CurrencyToggle = ({ afterChange, customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const { values, setValues, setFieldTouched } = useFormikContext();
  const { stateKey } = usePageFactoryContext();
  const rootPath = getFieldPath(stateKey, CONFIG_PRICING_DEFAULT_CURRENCY);
  const pricesDataPath = getFieldPath(stateKey, PRICE_DATA);

  const activeCurrency = get(values, rootPath);
  const prices = get(values, pricesDataPath, []);

  const options = usePricingCurrencyOptions();
  const hasMultiple = options.length > 1;
  if (hasMultiple) {
    const selectProps = {
      options: getPricesCurrencyOptions(prices),
      value: activeCurrency,
      labelTransform: (label) => label,
      customClasses: {
        container: "currency-select-min-width"
      },
      onChange: (key) => (evt) => {
        const selection = evt.currentTarget.value;

        const updatedValues = { ...values };
        set(updatedValues, rootPath, selection);

        if (afterChange) {
          afterChange({
            values: updatedValues,
            setValues
          });
        } else {
          setValues(updatedValues);
          setFieldTouched(rootPath);
        }
      }
    };

    return (
      <div className={classes.container}>
        <Select {...selectProps} />
      </div>
    );
  } else {
    return null;
  }
};

export default CurrencyToggle;
