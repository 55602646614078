import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import EditableContent from "components/EditableContent";
import { useFieldNameRoot } from "utils/hooks/form";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";
import { getFieldPath } from "utils/form";
import Pill from "components/Pill";
import { STATE_KEYS } from "utils/constants/state";
import LabelDescription from "components/Form/fields/LabelDescription";

const IdentityCard = ({
  name,
  required,
  edit,
  icon,
  header,
  title,
  polling,
  description,
  pill,
  footer,
  children
}) => {
  const nameRoot = useFieldNameRoot(name);
  const Icon = icon && getIconForUID(icon);
  return (
    <div className="w-100 flex flex-column">
      <LabelDescription
        loading={polling}
        nameRoot={nameRoot}
        edit={edit}
        label={header}
        required={required}
      />
      <div className="flex flex-column w-100 input shadow--common br-6 bg-white">
        <div className="flex flex-row justify-between items-start ph2 pt2">
          <div className="flex flex-column w-100">
            <div className="flex flex-row w-100">
              <div className="flex flex-row w-100 items-start">
                {Icon && <Icon size={18} />}
                <div
                  className={classnames("f5 fw5 lh-title", {
                    pl1: Icon,
                    dn: !title && !edit
                  })}
                >
                  <EditableContent
                    editable={edit}
                    sync={{
                      name: getFieldPath(nameRoot, STATE_KEYS.IDENTITY.TITLE)
                    }}
                  >
                    {title}
                  </EditableContent>
                </div>
              </div>
              <div className="dib pl1 flex flex-row items-center">
                {!isEmpty(pill) && (
                  <Pill
                    {...pill}
                    customClasses={{
                      container: "flex w-100 items-end justify-end",
                      copy: "f7 pa1 br2 fw6 flex-shrink-0 items-center"
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={classnames("f6 lh-copy pt2", {
                dn: !description && !edit,
                pb2: !children
              })}
            >
              <EditableContent
                editable={edit}
                sync={{
                  name: getFieldPath(nameRoot, STATE_KEYS.IDENTITY.PROMPT)
                }}
              >
                {description}
              </EditableContent>
            </div>
            {children}
          </div>
        </div>
        {footer && (
          <div className="flex flex-row justify-end items-center bt hairline-1 mt2 pt2 pb2 ph2">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default IdentityCard;
