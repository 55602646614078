import React from "react";
import IntervalToggle from "components/FormerPricingTable/IntervalToggle";
import Select from "components/Form/fields/Select";
import { deconstructFormattedPrice } from "utils/price";
import FormattedPriceHeader from "components/FormerPricingTable/FormattedPriceHeader";
import ProductActions from "components/FormerPricingTable/ProductActions";
import { getEmphasisBGColor } from "utils/styles";
import { getFeatureComparisonHeader, UI_THEME } from "utils/constants/ui";
import { usePageFactoryContext } from "utils/context";

const ProductFeaturesTableHeader = ({
  product,
  options,
  emphasisIndex,
  activeIndex,
  setActiveIndex,
  entity,
  afterChange
}) => {
  const { canActivateSettings, tracking } = usePageFactoryContext();
  const { formattedPrice, actions } = product;

  const { symbol, formatted } = deconstructFormattedPrice(formattedPrice);

  const selectProps = {
    options,
    value: activeIndex,
    disabled: options.length <= 1,
    onChange: () => (evt) => {
      const selection = evt.currentTarget.value;
      setActiveIndex(parseInt(selection));
    },
    customClasses: {
      input: "border-box input-reset ba b--black-20 pa2 mb0 db w-100 pv2 f5 br2"
    }
  };
  return (
    <div
      className="product-picker-header w-100 sticky pv2 pv3-ns bg-white bb bt hairline-1"
      style={
        canActivateSettings
          ? { top: 0 }
          : getFeatureComparisonHeader(tracking.subdomain)
      }
    >
      <div className="f5 w-100 ph3 pb2 pb3-ns">
        <div className="f3 fw6 pb3">Product</div>
        <Select {...selectProps} />
      </div>
      <div className="flex flex-row w-100 items-center justify-center pv1">
        {formatted && (
          <div className="flex flex-column pr2">
            <FormattedPriceHeader
              formatted={formatted}
              symbol={symbol}
              customClasses={{
                container: "fw4 f4 pb1 tc flex flex-row justify-center",
                symbol: "f4 pr1",
                price: "fw5 f2 lh-solid",
                interval: "f4 pl1 mt2"
              }}
            />
          </div>
        )}
        <div className="flex items-start pl3">
          <ProductActions
            customClasses={{
              container: "relative center lh-copy w-100 pv0"
            }}
            actions={actions}
            theme={UI_THEME.SLIM}
            productIndex={activeIndex}
            isEmphasized={true}
          />
        </div>
      </div>
      <div className="pt2 pt3-ns">
        <IntervalToggle
          entity={entity}
          afterChange={afterChange}
          customClasses={{
            copy: "f7 fw5",
            subtext: "dn"
          }}
        />
      </div>
      <style jsx>{`
        .product-picker-header {
          background-color: ${getEmphasisBGColor({
            emphasisIndex,
            index: activeIndex
          })};
        }
      `}</style>
    </div>
  );
};

export default ProductFeaturesTableHeader;
