import React from "react";
import get from "lodash/get";
import { useFormikContext } from "formik";
import DebouncePhone from "components/DebouncePhone";
import { DEFAULT_DEBOUNCE, SET_TOUCH_TIMEOUT } from "utils/constants/form";
import { useIsMounted } from "utils/hooks";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import { useFieldNameRoot } from "utils/hooks/form";

const Phone = (props) => {
  const {
    name,
    placeholder,
    label,
    description,
    validation,
    focus = [],
    index,
    disabled
  } = props;
  const nameRoot = useFieldNameRoot(name);
  const required = validation && validation.required;
  const isMounted = useIsMounted();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const value = get(values, name);

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => (
        <div
          id={nameRoot}
          className={`w-100 ${reflectClasses}`}
          onClick={onClick}
        >
          <DebouncePhone
            edit={edit}
            label={label}
            description={description}
            disabled={Boolean(disabled)}
            inputValue={value}
            placeholder={placeholder}
            debounce={DEFAULT_DEBOUNCE}
            required={required}
            name={name}
            valueSet={(val) => {
              if (isMounted.current) {
                setFieldValue(name, val);
                setTimeout(() => {
                  setFieldTouched(name, true);
                }, SET_TOUCH_TIMEOUT);
              }
            }}
          />
        </div>
      )}
    </ReflectFocus>
  );
};

export default Phone;
