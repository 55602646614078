import React from "react";
import Button from "components/Form/fields/Button";
import ReflectFocus from "components/ReflectFocus";
import InfoNotice from "components/InfoNotice";
import UnauthCheckoutButton from "components/UnauthCheckoutButton";
import { DEFAULT_ACTIONS_CLASSES } from "utils/constants/ui";
import { useCheckoutActionProps } from "utils/hooks/checkout";
import { checkoutForm } from "utils/checkout/actions";
import { useRouteConfigParams } from "utils/hooks/route";

/**
 *
 * @param {Array} actions
 * @param {String} actions.uid
 * @param {String} actions.copy
 * @param {String} actions.uid
 * @param {String} actions.href
 * @param {String} actions.tag
 * @param {String} productIndex
 */
const FormerFormCheckoutAction = ({
  action,
  disabled,
  focus,
  name,
  isLive,
  isEmphasized,
  customClasses,
  shortLink,
  formData,
  customer
}) => {
  const classes = { ...DEFAULT_ACTIONS_CLASSES, ...customClasses };
  const params = useRouteConfigParams();

  const contextProps = {
    checkoutAction: checkoutForm,
    checkoutParams: {
      cancelUrl: params && params.cancelUrl,
      formData
    }
  };
  const { notice, props, isUnauthedBuilder } = useCheckoutActionProps(
    {
      name,
      action,
      disabled,
      isLive,
      isEmphasized
    },
    contextProps
  );

  const actionButton = isUnauthedBuilder ? (
    <UnauthCheckoutButton
      props={props}
      shortLink={shortLink}
      customer={customer}
    />
  ) : (
    <Button key="checkout" {...props} />
  );

  return (
    <ReflectFocus focus={focus}>
      {({ onClick, classes: focusClasses } = {}) => (
        <div
          onClick={onClick}
          className={`${classes.container} ${focusClasses}`}
        >
          <div className={classes.nodes}>{actionButton}</div>
          {notice && (
            <div className="pt3">
              <InfoNotice
                customClasses={{ copy: "f7 lh-copy pl2" }}
                copy={notice}
              />
            </div>
          )}
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormerFormCheckoutAction;
