import React from "react";
import DebounceTextarea from "components/DebounceTextarea";
import { useFieldNameRoot } from "utils/hooks/form";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { DEFAULT_DEBOUNCE, SET_TOUCH_TIMEOUT } from "utils/constants/form";
import { useIsMounted } from "utils/hooks";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";

const Textarea = (props) => {
  const {
    name,
    label,
    description,
    validation,
    focus = [],
    index,
    disabled
  } = props;
  const required = validation && validation.required;
  const nameRoot = useFieldNameRoot(name);
  const isMounted = useIsMounted();
  const {
    values,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const placeholder = get(values, `${nameRoot}.placeholder`, "");

  const value = get(values, name);
  const isTouched = get(touched, name);

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => (
        <div
          id={nameRoot}
          className={`w-100 ${reflectClasses}`}
          onClick={onClick}
        >
          <DebounceTextarea
            label={label}
            description={description}
            edit={edit}
            disabled={Boolean(disabled)}
            inputValue={value}
            placeholder={placeholder}
            debounce={DEFAULT_DEBOUNCE}
            required={required}
            minRows={3}
            name={name}
            key={name}
            valueSet={(val) => {
              if (isMounted.current) {
                setFieldValue(name, val);
                if (!isTouched) {
                  setTimeout(() => {
                    setFieldTouched(name, true);
                  }, SET_TOUCH_TIMEOUT);
                }
              }
            }}
          />
        </div>
      )}
    </ReflectFocus>
  );
};

export default Textarea;
