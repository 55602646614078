import React from "react";
import classnames from "classnames";
import Pill from "components/Pill";
import { PILL_THEME } from "components/Pill";
import Image from "components/Image";
import { PRIORITY_FIT_CONTAIN } from "utils/constants/ui";
import { PAGE_CHECKOUT_PREVIEW_CLASSES } from "./";
import SubscriptionScheduleItems from "components/CheckoutPreview/SubscriptionScheduleItems";

const LARGE_IMAGE_DIMENSIONS = {
  width: 284,
  height: 284
};

const DesktopHeader = (props) => {
  const {
    banner,
    badge,
    image,
    firstLineItem,
    subscriptionScheduleItems
  } = props;
  const hasSubscriptionScheduleItems =
    subscriptionScheduleItems && subscriptionScheduleItems.length > 0;

  const hasBannerLabel = banner && banner.label;
  const hasBadgeLabel = badge && badge.label;
  const pillBadge = hasBadgeLabel && (
    <Pill
      theme={PILL_THEME.SLIM}
      status={badge.status}
      copy={badge.label}
      customClasses={{
        container: "flex-shrink-0",
        copy: "f7 pa2 br2 fw6 flex-shrink-0 items-center"
      }}
    />
  );

  const imageContent = image ? (
    <div
      style={LARGE_IMAGE_DIMENSIONS}
      className="bg-transparent br2 overflow-hidden"
    >
      <Image
        src={image}
        {...LARGE_IMAGE_DIMENSIONS}
        alt={firstLineItem.label}
        {...PRIORITY_FIT_CONTAIN}
      />
    </div>
  ) : null;

  return (
    <div className="w-100">
      <div
        className={classnames("w-100 flex flex-row", {
          "justify-end": !hasBannerLabel && hasBadgeLabel
        })}
      >
        {hasBannerLabel && (
          <div className="w-100 pb4">
            {banner.title && (
              <div className={PAGE_CHECKOUT_PREVIEW_CLASSES.title}>
                {banner.title}
              </div>
            )}
            {banner.label && (
              <div className={PAGE_CHECKOUT_PREVIEW_CLASSES.label}>
                {banner.label}
              </div>
            )}
            {banner.sublabel && (
              <div className={PAGE_CHECKOUT_PREVIEW_CLASSES.sublabel}>
                {banner.sublabel}
              </div>
            )}
            {banner.body && (
              <div className={PAGE_CHECKOUT_PREVIEW_CLASSES.body}>
                {banner.body}
              </div>
            )}
          </div>
        )}
        <div className="dn dib-ns flex-shrink-0">{pillBadge}</div>
      </div>
      {hasSubscriptionScheduleItems && (
        <SubscriptionScheduleItems
          subscriptionScheduleItems={subscriptionScheduleItems}
          collapseImages
        />
      )}
      {imageContent}
    </div>
  );
};

export default DesktopHeader;
