import React, { useState, useCallback } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import Heading from "@tiptap/extension-heading";
import HardBreak from "@tiptap/extension-hard-break";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Link from "@tiptap/extension-link";
import History from "@tiptap/extension-history";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import EditableContentMenuBar from "components/EditableContentMenuBar";
import {
  EDITABLE_COMMAND,
  EDITABLE_CONTENT_ACTION,
  LEGAL_CONTENT_MENU_CONFIG
} from "utils/constants/editor";
import upperFirst from "lodash/upperFirst";

const DEFAULT_CLASSES = {
  container: "relative f6",
  controls: "flex flex-row ba br2 hairline-1 mb2 justify-between"
};

const EXTENSION_CONFIG = {
  paragraph: {
    HTMLAttributes: {
      class: "mv0"
    }
  },
  placeholder: {
    placeholder: "Enter your content here"
  }
};

const LegalEditorField = ({
  content,
  onUpdate,
  name,
  editable,
  customClasses,
  config,
  ...props
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const [showMenu, setShowMenu] = useState(false);
  const [openOnClick, setOpenOnClick] = useState(
    Boolean(props.openOnClick) || false
  );

  let headingConfig = EXTENSION_CONFIG.heading;
  let paragraphConfig = EXTENSION_CONFIG.paragraph;
  let placeholderConfig = EXTENSION_CONFIG.placeholder;
  if (config) {
    if (config.heading) {
      headingConfig = config.heading;
    }
    if (config.paragraph) {
      paragraphConfig = config.paragraph;
    }
    if (config.placeholder) {
      placeholderConfig = config.placeholder;
    }
  }

  const editor = useEditor(
    {
      editable: Boolean(editable),
      onFocus({ editor, event }) {
        setShowMenu(true);
      },
      onBlur({ editor, event }) {
        // setShowMenu(false);
      },
      onUpdate,
      extensions: [
        History,
        Superscript,
        Subscript,
        Bold,
        Heading.configure(headingConfig),
        Italic,
        Document,
        HardBreak,
        Paragraph.configure(paragraphConfig),
        Text,
        Placeholder.configure(placeholderConfig),
        Link.configure({
          openOnClick,
          validate: (href) => /^https?:\/\//.test(href)
        })
      ],
      content
    },
    [openOnClick]
  );

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className={classes.container}>
      {editable && (
        <div className={classes.controls}>
          <EditableContentMenuBar
            editor={editor}
            menu={LEGAL_CONTENT_MENU_CONFIG}
            actions={{
              [`${EDITABLE_CONTENT_ACTION.SET}${upperFirst(
                EDITABLE_COMMAND.LINK
              )}`]: setLink
            }}
          />
        </div>
      )}
      <EditorContent editor={editor} />
    </div>
  );
};
export default LegalEditorField;
