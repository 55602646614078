import React, { Fragment } from "react";
import classnames from "classnames";
import Button from "components/Form/fields/Button";

const EmptyFeatureComparisonPrompt = ({ groupCount }) => {
  const COL_COUNT = 3;
  const GROUP_COUNT = 2;
  const thWidth = 30;
  const tdWidth = (100 - thWidth) / COL_COUNT;
  const header = groupCount
    ? "No feature groups added"
    : "No feature to compare";

  return (
    <div className="w-100 pb5 ph3 relative">
      <div className="absolute left-0 right-0 z-1">
        <div className="pt4 tc">
          <div className="dib pa3 bg-white br2 ba hairline-1">
            <div className="f3 fw5">{header}</div>
            <div className="pt3 tc w-100 flex justify-center">
              <Button
                theme="slim"
                copy="Add feature groups"
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
      <table
        className="w-100 f5"
        cellSpacing="0"
        style={{
          borderCollapse: "separate",
          borderSpacing: 0,
          position: "relative"
        }}
      >
        <thead>
          <tr className="ph3 bg-white">
            <td className="fw6 ph3 pv3 hairline-1 br bb bg-white">
              <div className="bg-black-05 h2"></div>
            </td>
            {new Array(COL_COUNT).fill("").map((_, headerIx) => (
              <th
                className="tableHeader hairline-1 bg-white bb bt br ph4"
                key={headerIx}
              >
                <div className="bg-black-05 h2"></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          className="lh-copy"
          style={{
            display: "table-row-group",
            verticalAlign: "middle"
          }}
        >
          {new Array(GROUP_COUNT).fill("").map((_, headerIx) => {
            const headProps = {
              className: classnames("tl", {
                "bl bb hairline-1": !headerIx
              })
            };
            const headContentProps = {
              style: headerIx
                ? {
                    boxShadow: "0 0 0 1px rgb(233, 234, 236)"
                  }
                : {},
              className: classnames("pa3 fw5 f3", {
                mt5: headerIx
              })
            };

            return (
              <Fragment key={headerIx}>
                <tr className="bn">
                  <th {...headProps} colSpan={COL_COUNT + 1}>
                    <div {...headContentProps}></div>
                  </th>
                </tr>
                {new Array(COL_COUNT).fill("").map((_, groupIx) => {
                  return (
                    <tr key={groupIx}>
                      <th
                        className="tl fw4 bb bl br hairline-1 relative bg-white"
                        style={{ width: `${thWidth}%` }}
                      >
                        <div className="flex flex-row items-center w-100 justify-between pa3">
                          <div className="f5 h2 dib bg-black-05 w-50 user-select-n bg-black-05"></div>
                        </div>
                      </th>
                      {new Array(COL_COUNT).fill("").map((_, cellIx) => {
                        return (
                          <td
                            key={cellIx}
                            className="tc bb br hairline-1 relative bg-white"
                            style={{ width: `${tdWidth}%` }}
                          >
                            <div className="flex flex-row items-center w-100 justify-between pa3">
                              <div className="f5 h2 dib bg-black-05 w-70 user-select-n bg-black-05"></div>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default EmptyFeatureComparisonPrompt;
