import React, { useState } from "react";
import SlimCTA from "components/SlimCTA";
import { UI_THEME } from "utils/constants/ui";
import { formFactorAction } from "utils/view";

const StripeRefreshNotice = () => {
  const [loading, setLoading] = useState(false);

  return (
    <SlimCTA
      header="Error"
      subheader={`Checkout settings for this page failed to load. ${formFactorAction()} refresh to try again.`}
      customClasses={{
        container:
          "w-100 flex flex-column justify-between ba br2 hairline-1 pa3 items-start bg-white",
        actionsWrapper: "pt2"
      }}
      actions={[
        {
          rel: "noopener noreferrer",
          loading,
          onClick: () => {
            setLoading(true);

            const { href } = window.location;
            window.location.href = href;
          },
          theme: UI_THEME.SLIM,
          copy: "Refresh",
          tag: "a"
        }
      ]}
    />
  );
};

export default StripeRefreshNotice;
