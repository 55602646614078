import React from "react";
import { FORMER_MAX_WIDTH } from "utils/constants/ui";
import ProductActions from "components/FormerPricingTable/ProductActions";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import BrandLogo from "components/BrandLogo";
import classnames from "classnames";
import { usePageFactoryContext } from "utils/context";
import { getFieldPath } from "utils/form";
import get from "lodash/get";
import { useFormikContext } from "formik";
import { usePageElementNameRoot } from "utils/hooks/manifest";

const FormerNavigation = ({
  uid,
  name,
  entity,
  data: { brand, actions },
  ui,
  entityRef
}) => {
  const focus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    }
  ];

  const nameRoot = usePageElementNameRoot(name);
  const { stateKey } = usePageFactoryContext();
  const { values } = useFormikContext();
  const contentName = `content.${name}`;

  const configPath = getFieldPath(stateKey, `${contentName}.config`);

  const showBrandPath = `${configPath}.${STATE_KEYS.ENTITY_CONFIG.BRAND_SHOW}`;
  const showBrand = Boolean(get(values, showBrandPath));
  const hasContent = showBrand || (actions && actions.length);
  if (!hasContent) {
    return null;
  }

  return (
    <ReflectFocus name={entity.schema.title} focus={focus} withHeader>
      {({ hover } = {}) => (
        <div
          id={nameRoot}
          ref={entityRef}
          className={classnames(
            "w-100 flex flex-row items-center justify-between sticky top-0 pv3 ph3 ph0-ns z-2",
            {
              "bb hairline-1 bg-white": !hover
            }
          )}
        >
          <div
            className={classnames(
              "w-100 center w-100 center flex flex-row justify-end ph3-ns",
              {
                "justify-between": showBrand,
                "justify-end": !showBrand
              }
            )}
            style={{ maxWidth: FORMER_MAX_WIDTH }}
          >
            {showBrand && <BrandLogo brand={brand} />}
            <div className="flex flex-row">
              <ProductActions
                customClasses={{
                  container: "relative center lh-copy w-100"
                }}
                productIndex={0}
                actions={actions}
                uid={uid}
                name={name}
                focus={focus}
              />
            </div>
          </div>
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormerNavigation;
