import React from "react";
import { useTaxIds } from "utils/selectors";
import MultiSelect from "components/Form/fields/MultiSelect";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import TaxIdOption from "./TaxIdOption";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { SET_TOUCH_TIMEOUT } from "utils/constants/form";
import { useViewport } from "utils/context";

const TinSelect = ({ name, uid, label, required, disabled }) => {
  const { isSmall } = useViewport();
  const typePath = getFieldPath(name, "type");
  const codePath = getFieldPath(name, "code");

  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched
  } = useFormikContext();
  const taxIds = useTaxIds();

  const fieldValues = get(values, name, {});
  const isTouched = get(touched, name);
  const selectedTypeId = get(values, typePath);
  const selectedCode = get(values, codePath);
  const matchingTaxId = taxIds.find(
    ({ id, code }) => selectedTypeId === id && selectedCode === code
  );

  let selections = matchingTaxId
    ? [
        {
          id: selectedTypeId,
          name: `${matchingTaxId.codeId} - ${matchingTaxId.country}`
        }
      ]
    : [];
  const options = taxIds.map((taxId) => ({
    ...taxId,
    name: taxId.country
  }));

  const selectProps = {
    value: selections,
    placeholder: "Select country tax id type",
    options,
    customStylesClasses: {
      control: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    disabled: Boolean(disabled),
    onChange: () => ({ value, code }) => {
      const updatedField = { ...cloneDeep(fieldValues) };
      updatedField.type = value;
      /**
       * Country code e.g. IE
       * - Code allows us to differentiate between types across countries
       * e.g. EU countries use eu_vat so we differentiate on country code (IE)
       */
      updatedField.code = code;
      setFieldValue(name, updatedField);

      if (!isTouched) {
        setTimeout(() => {
          setFieldTouched(name, true);
        }, SET_TOUCH_TIMEOUT);
      }
    },
    isMulti: false,
    labelTransform: (value) => value,
    components: {
      Option: TaxIdOption
    }
  };

  return <MultiSelect {...selectProps} />;
};

export default TinSelect;
