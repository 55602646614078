import React from "react";
import { getGridClass } from "../utils";
import ReflectFocus from "components/ReflectFocus";
import { PRICING_INTENT_BILLING_FOCUS } from "utils/constants/focus";
import { ENTITY_HOVER_UNDERLINE } from "utils/constants/ui";

/**
 * @param {Array} subtext
 * @param {Object|String} subtext.copy
 * @param {String} productIndex
 */
const SubtextSection = ({ subtext, productIndex, focus, isEmphasized }) => {
  const subtextNodes =
    subtext &&
    subtext.length > 0 &&
    subtext.map((subtext, subtextIx) => {
      const value = typeof subtext === "string" ? subtext : subtext.copy.value;
      return (
        <div key={subtextIx} className="pt2 lh-copy w-100 w-60-ns center">
          {value}
        </div>
      );
    });

  return (
    <div
      className={getGridClass({
        suffix: `price_details--${productIndex}`,
        isEmphasized
      })}
    >
      {subtextNodes && (
        <ReflectFocus
          focus={[PRICING_INTENT_BILLING_FOCUS]}
          classes={ENTITY_HOVER_UNDERLINE}
        >
          {({ onClick, classes } = {}) => (
            <div
              onClick={onClick}
              className={`f6 tc BLOCKS__color-text--secondary ${classes}`}
            >
              {subtextNodes}
            </div>
          )}
        </ReflectFocus>
      )}
    </div>
  );
};

export default SubtextSection;
