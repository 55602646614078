import React from "react";
import { FORM_FIELD_COMPONENT_MAP } from "components/Form/Components";
import classnames from "classnames";
import compact from "lodash/compact";
import get from "lodash/get";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import ReflectFocus from "components/ReflectFocus";
import { STATE_KEYS } from "utils/constants/state";
import EditableContent from "components/EditableContent";
import AddFieldGroupCTA from "./AddFieldGroupCTA";
import { usePageFactoryContext } from "utils/context";
import { useEditorViewport } from "utils/hooks";
import { processViewportClasses } from "utils/editor";
import { useFormModeDisabled } from "utils/hooks/editor";

const FIELD_SPACE_CLASSES = "pt3 mt1";

const FieldGroupFactory = ({ name, focus, entity, loading }) => {
  const { values } = useFormikContext();
  const { canActivateSettings } = usePageFactoryContext();

  const disabled = useFormModeDisabled();

  const fieldGroupsPath = getFieldPath(name, "fieldGroups");
  const fieldGroups = get(values, fieldGroupsPath, []);

  const editorViewport = useEditorViewport();
  const compClasses = processViewportClasses(
    {
      title: "f4 f3-ns fw6",
      subtitle: "f5 f4-ns pt1 pb3 lh-title"
    },
    editorViewport
  );

  const nodes = compact(fieldGroups).map(
    ({ title, subtitle, fields, uuid }, fieldGroupIx) => {
      const fieldGroupPath = `${fieldGroupsPath}[${fieldGroupIx}]`;
      const fieldGroupFieldsPath = `${fieldGroupPath}.fields`;
      const sharedFocus = [
        ...focus,
        {
          key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
          value: fieldGroupIx
        },
        /**
         * Set sub intent to null
         * e.g. will unset the add field menu
         */
        {
          key: STATE_KEYS.EDITOR.MENU_SUB_INTENT,
          value: null
        }
      ];

      const fieldNodes =
        fields &&
        fields.reduce((memo, field, fieldIx) => {
          const fieldKey = `${fieldIx}-${field.uid}`;

          const FieldComponent = FORM_FIELD_COMPONENT_MAP[field.uid];
          const fieldNameRoot = `${fieldGroupFieldsPath}[${fieldIx}]`;
          const fieldName = `${fieldNameRoot}.value`;
          const hidden = Boolean(
            get(values, `${fieldNameRoot}.${STATE_KEYS.FIELD.HIDDEN}`)
          );

          if (FieldComponent && !hidden) {
            const fieldProps = {
              key: fieldKey,
              ...field,
              name: fieldName,
              focus: sharedFocus,
              index: fieldIx,
              disabled,
              loading
            };

            memo.push(<FieldComponent {...fieldProps} />);
          }

          return memo;
        }, []);
      const hasFields = fieldNodes && fieldNodes.length > 0;

      const targetedFocus = [
        ...sharedFocus,
        {
          key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
          value: null
        }
      ];

      return (
        <div key={uuid} className="w-100">
          <ReflectFocus focus={targetedFocus}>
            {({ onClick, hover, classes, edit } = {}) => (
              <div
                key={`${fieldGroupPath}.title`}
                onClick={onClick}
                className={classnames(`${compClasses.title} ${classes}`, {
                  "pb3 mb2": !subtitle && !edit,
                  "dn": !title && !edit
                })}
              >
                <EditableContent
                  editable={edit}
                  sync={{
                    name: `${fieldGroupPath}.title`
                  }}
                >
                  {title}
                </EditableContent>
              </div>
            )}
          </ReflectFocus>
          <ReflectFocus focus={targetedFocus}>
            {({ onClick, hover, classes, edit } = {}) => (
              <div
                key={`${fieldGroupPath}.subtitle`}
                onClick={onClick}
                className={classnames(`${compClasses.subtitle} ${classes}`, {
                  dn: !subtitle && !edit
                })}
              >
                <EditableContent
                  editable={edit}
                  sync={{
                    name: `${fieldGroupPath}.subtitle`
                  }}
                >
                  {subtitle}
                </EditableContent>
              </div>
            )}
          </ReflectFocus>
          {hasFields
            ? fieldNodes.map((node, nodeIx) => (
                <div
                  key={nodeIx}
                  className={`w-100 ${nodeIx ? FIELD_SPACE_CLASSES : ""}`}
                >
                  {node}
                </div>
              ))
            : null}
        </div>
      );
    }
  );

  return nodes.length ? (
    nodes.map((node, nodeIx) => (
      <div key={nodeIx} className={`w-100 ${nodeIx ? "pt4" : ""}`}>
        {node}
      </div>
    ))
  ) : canActivateSettings ? (
    <AddFieldGroupCTA entity={entity} focus={focus} />
  ) : null;
};

export default FieldGroupFactory;
