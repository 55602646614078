import React from "react";
import Label from "components/Form/fields/Label";
import Description from "components/Form/fields/Description";
import MultiSelect from "components/Form/fields/MultiSelect";
import { useFormikContext } from "formik";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { getCheckboxProps, getUpdatedMultiselectValues } from "utils/form";
import { SET_TOUCH_TIMEOUT } from "utils/constants/form";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import classnames from "classnames";
import Checkbox from "rc-checkbox";
import EditableContent from "components/EditableContent";
import MultiselectLoader from "components/MultiselectLoader";
import { useFieldNameRoot, useSelectOptions } from "utils/hooks/form";
import { MULTISELECT_PRESENTATION_TYPE } from "utils/constants/multiselect";
import MultiselectOption from "components/MultiselectOption";
import ActionRowLoader from "components/ActionRowLoader";

const MultiselectSelect = ({
  options,
  name,
  valueMap,
  placeholder,
  isTouched,
  setFieldTouched,
  setFieldValue
}) => {
  const { selections, options: selectOptions } = options.reduce(
    (memo, option) => {
      const preparedOption = {
        ...option,
        /**
         * Two required fields for select presentation
         */
        value: option.uuid,
        name: option.label
      };
      if (valueMap[option.uuid]) {
        memo.selections.push(preparedOption);
      }
      memo.options.push(preparedOption);

      return memo;
    },
    {
      selections: [],
      options: []
    }
  );

  const selectProps = {
    value: selections,
    placeholder: placeholder || "",
    options: selectOptions,
    closeMenuOnSelect: false,
    onChange: () => (change) => {
      const updatedSelections = Array.isArray(change)
        ? change.map(({ uuid }) => uuid)
        : [];
      setFieldValue(name, updatedSelections);
      if (!isTouched) {
        setTimeout(() => {
          setFieldTouched(name, true);
        }, SET_TOUCH_TIMEOUT);
      }
    },
    isMulti: true,
    labelTransform: (value) => value,
    components: {
      Option: MultiselectOption
    }
  };

  return <MultiSelect {...selectProps} />;
};

const MultiselectList = ({
  edit,
  options,
  name,
  disabled,
  valueMap,
  isTouched,
  nameRoot,
  setFieldTouched,
  setFieldValue
}) => {
  return options.map((option, optionIx) => {
    const optionLabel = option.label;
    const optionNameRoot = `${nameRoot}.options[${optionIx}]`;
    const optionLabelName = `${optionNameRoot}.label`;
    const activeOption = valueMap[option.uuid];

    const toggleValue = (_, active) => {
      if (!disabled) {
        const updatedValues = getUpdatedMultiselectValues({
          active,
          valueMap,
          uuid: option.uuid
        });
        setFieldValue(name, updatedValues);

        if (!isTouched) {
          setTimeout(() => {
            setFieldTouched(name, true);
          }, SET_TOUCH_TIMEOUT);
        }
      }
    };

    const canEdit = !option.immutable && edit;

    return (
      <div
        key={option.uuid}
        className={classnames("", {
          "flex flex-row justify-between items-center": optionLabel,
          "pt2": optionIx
        })}
      >
        <div
          className="w-100 pointer"
          onClick={() => toggleValue(null, !Boolean(activeOption))}
        >
          {(optionLabel || canEdit) && (
            <div className="f6 tl pr2 lh-copy">
              <EditableContent
                editable={canEdit}
                sync={{
                  name: optionLabelName
                }}
              >
                {optionLabel}
              </EditableContent>
            </div>
          )}
        </div>
        <Checkbox
          {...getCheckboxProps({
            key: name,
            active: activeOption,
            disabled,
            setFieldValue: toggleValue
          })}
        />
      </div>
    );
  });
};

const COMPONENT_MAP = {
  [MULTISELECT_PRESENTATION_TYPE.LIST]: MultiselectList,
  [MULTISELECT_PRESENTATION_TYPE.SELECT]: MultiselectSelect
};

const LOADER_MAP = {
  [MULTISELECT_PRESENTATION_TYPE.LIST]: MultiselectLoader,
  [MULTISELECT_PRESENTATION_TYPE.SELECT]: ActionRowLoader
};
/**
 *
 * @param {String} name
 * @param {String} label
 * @param {String} props.uid
 * @param {String} props.uuid
 * @param {String} props.validation
 * @param {String} props.value
 * @param {String} props.options
 */
const FormMultiselect = (props) => {
  const {
    name,
    label,
    description,
    options,
    validation,
    focus = [],
    index,
    loading
  } = props;
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const value = get(values, name) || [];
  const valueMap = Array.isArray(value)
    ? value.reduce((memo, valueUUID) => {
        if (typeof valueUUID === "string") {
          memo[valueUUID] = true;
        }
        return memo;
      }, {})
    : {};
  const nameRoot = useFieldNameRoot(name);

  const type = get(
    values,
    `${nameRoot}.${STATE_KEYS.MULTISELECT.PRESENTATION_TYPE}`
  );
  const placeholder = get(values, `${nameRoot}.placeholder`);

  const selectOptions = useSelectOptions({
    name: nameRoot,
    options
  });

  const isTouched = get(touched, name);
  const required = validation && validation.required;
  const error = get(errors, name);
  const showError = Boolean(isTouched && error);
  const disabled = Boolean(props.disabled);

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes, edit } = {}) => {
        let content = null;
        if (loading) {
          const Loader = LOADER_MAP[type] || MultiselectLoader;
          content = <Loader />;
        } else if (COMPONENT_MAP[type]) {
          content = COMPONENT_MAP[type]({
            edit,
            options: selectOptions,
            isTouched,
            name,
            placeholder,
            disabled,
            nameRoot,
            valueMap,
            setFieldTouched,
            setFieldValue
          });
        }

        const showLabel = label || edit;
        const showDescription = description || edit;

        return (
          <div
            id={nameRoot}
            className={classnames(`w-100 ${classes}`, {
              hover: hover
            })}
            onClick={onClick}
          >
            {showLabel && (
              <Label
                edit={edit}
                name={`${nameRoot}.label`}
                label={label}
                error={showError}
                required={required}
              />
            )}
            {showDescription && (
              <Description
                edit={edit}
                name={`${nameRoot}.description`}
                copy={description}
              />
            )}
            {content}
            {showError && <div className="f7 pt1 color-error">{error}</div>}
          </div>
        );
      }}
    </ReflectFocus>
  );
};

export default FormMultiselect;
