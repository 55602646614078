import React from "react";
import { BLOCKS_PREFIX, FORMER_MAX_WIDTH } from "utils/constants/ui";
import { EntityPropTypes } from "utils/propTypes";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import classnames from "classnames";
import {
  RiTwitterLine,
  RiInstagramLine,
  RiDiscordLine,
  RiVideoChatLine,
  RiWhatsappLine,
  RiWechat2Line,
  RiMessengerLine,
  RiSnapchatLine,
  RiFacebookCircleLine,
  RiTelegramLine
} from "@hacknug/react-icons/ri";
import ProfileAvatar from "components/ProfileAvatar";
import { getSocialHandleExternalLink } from "utils/social";
import { SOCIAL_SERVICE } from "utils/constants/useCases";
import { usePageElementNameRoot } from "utils/hooks/manifest";

const SOCIAL_ICON_MAP = {
  [SOCIAL_SERVICE.WHATSAPP]: RiWhatsappLine,
  [SOCIAL_SERVICE.WECHAT]: RiWechat2Line,
  [SOCIAL_SERVICE.MESSENGER]: RiMessengerLine,
  [SOCIAL_SERVICE.INSTAGRAM]: RiInstagramLine,
  [SOCIAL_SERVICE.SNAPCHAT]: RiSnapchatLine,
  [SOCIAL_SERVICE.TWITTER]: RiTwitterLine,
  [SOCIAL_SERVICE.FACEBOOK]: RiFacebookCircleLine,
  [SOCIAL_SERVICE.TIKTOK]: RiVideoChatLine,
  [SOCIAL_SERVICE.TELEGRAM]: RiTelegramLine,
  [SOCIAL_SERVICE.DISCORD]: RiDiscordLine
};

const FormerSocialProfile = ({
  name,
  entity,
  data: { title, subtitle, handles, image },
  entityRef,
  ui
}) => {
  const focus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    }
  ];
  const nameRoot = usePageElementNameRoot(name);

  return (
    <ReflectFocus name={entity.schema.title} focus={focus} withHeader>
      {({ onClick, hover, classes } = {}) => {
        return (
          <div
            id={nameRoot}
            ref={entityRef}
            className={classnames(`w-100 ph3 ph0-ns`, {
              "bg-white": !hover
            })}
            style={{ maxWidth: FORMER_MAX_WIDTH }}
          >
            <div className="tc pb2 pt4 pt5-ns">
              {image && image.src && (
                <div className={classnames(classes)} onClick={onClick}>
                  <ProfileAvatar {...image} height={120} width={120} />
                </div>
              )}
              <div
                onClick={onClick}
                className={classnames(
                  `f2 f1-ns ${classes} ${BLOCKS_PREFIX}color-text--primary`,
                  {
                    pt2: image
                  }
                )}
              >
                {title}
              </div>
              {subtitle && (
                <div
                  onClick={onClick}
                  className={`f4 f3-ns mt2 mt3-ns lh-copy w-100 w-two-thirds-ns center ${classes} ${BLOCKS_PREFIX}color-text--primary`}
                >
                  {subtitle}
                </div>
              )}
              {handles && (
                <div className="w-100 tc pt2 pt3-ns">
                  {handles.map(({ handle, service }, handleIx) => {
                    const SocialIcon = SOCIAL_ICON_MAP[service];
                    const Icon = SocialIcon ? <SocialIcon size={22} /> : null;

                    return (
                      <a
                        className={classnames(
                          `link f6 dib pointer fw5 ${BLOCKS_PREFIX}link__decoration--hover`,
                          {
                            pl2: handleIx
                          }
                        )}
                        key={handleIx}
                        target="_blank"
                        href={getSocialHandleExternalLink(handle, service)}
                      >
                        <div
                          className={classnames(
                            "w-100 flex flex-row items-center justify-center",
                            {
                              pt1: handleIx
                            }
                          )}
                        >
                          {Icon}
                          <div className="pl1">{handle}</div>
                        </div>
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
      }}
    </ReflectFocus>
  );
};

FormerSocialProfile.propTypes = EntityPropTypes;

export default FormerSocialProfile;
