import React from "react";
import SlimCTA from "components/SlimCTA";
import { setEditorFocus } from "utils/builder";
import { useFormikContext } from "formik";
import { BUTTON_CLASS_CONTEXT } from "utils/constants/ui";

const DEFAULT_CLASSES = {
  header: "f4 black-70 fw6",
  subheader: "f6 black-70 lh-copy",
  container:
    "w-100 flex flex-column justify-between ba br2 hairline-1-dashed pa3 items-start bg-white",
  actionsWrapper: "pt2"
};

const AddFieldGroupFieldCTA = ({
  focus,
  extraActions,
  header,
  subheader,
  customClasses
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const { values, setValues } = useFormikContext();

  return (
    <SlimCTA
      header={header}
      customClasses={classes}
      subheader={subheader}
      extraActions={extraActions}
      actions={[
        {
          copy: "Add",
          classes: {
            contextClass: BUTTON_CLASS_CONTEXT.SECONDARY
          },
          onClick: () => {
            setEditorFocus({
              values,
              setValues,
              focus
            })();
          }
        }
      ]}
    />
  );
};

export default AddFieldGroupFieldCTA;
