import React, { Fragment, useState } from "react";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalActions from "components/ModalActions";
import { useIsMounted } from "utils/hooks";
import Button from "components/Form/fields/Button";
import { UI_THEME } from "utils/constants/ui";

const ConfirmButton = ({
  onConfirm,
  disabled,
  copy,
  slides,
  children,
  theme,
  classes
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMounted = useIsMounted();

  const buttonProps = {
    disabled,
    copy,
    classes,
    onClick: () => setShowModal(true),
    theme: theme || UI_THEME.SLIM
  };

  return (
    <Fragment>
      <Button {...buttonProps} />
      {showModal && (
        <Modal showModal={showModal} onClose={() => setShowModal(false)}>
          <ModalContent slides={slides}>
            {() => (
              <div className="w-100 pa3">
                {children}
                <ModalActions
                  actions={[
                    {
                      cta: "Cancel",
                      onClick: () => {
                        setShowModal(false);
                      }
                    },
                    {
                      cta: "Yes",
                      loading: isSubmitting,
                      disabled,
                      onClick: () => {
                        setIsSubmitting(true);

                        setTimeout(() => {
                          onConfirm((err) => {
                            if (isMounted.current) {
                              setIsSubmitting(false);
                              if (!err) {
                                setShowModal(false);
                              }
                            }
                          });
                        }, 0);
                      }
                    }
                  ]}
                />
              </div>
            )}
          </ModalContent>
        </Modal>
      )}
    </Fragment>
  );
};

export default ConfirmButton;
