import React from "react";
import PropTypes from "prop-types";
import { getGridClass } from "../utils";
import Button from "components/Form/fields/Button";
import { BUTTON_CLASS_CONTEXT } from "utils/constants/ui";
import { checkoutPrice } from "utils/checkout/actions";
import { PAGE_ACTION_UIDS } from "components/FormerEditor/common/constants";
import ReflectFocus from "components/ReflectFocus";
import { useCheckoutActionProps } from "utils/hooks/checkout";

// const buttonClasses = classnames(
//   "fw6 br2 ph3 pv2 border-box input-reset ba b--black bg-transparent pointer f6 dib link items-center flex justify-center tc",
//   {
//     ml1: index
//   }
// );

/**
 * Take offer action button trigger
 * @param {Object} params
 * @param {Number} params.index
 * @param {Boolean} params.isNotPriumary - whether the action is the primary one for the section or not
 * @param {String} params.theme - theme for the button style
 * @param {Boolean} params.isEmphasized
 * @param {Object} params.action
 */
const Action = ({
  name,
  index,
  isNotPrimary,
  isEmphasized,
  /**
   * {
   * "copy": "Buy now",
   * "uuid": "63403c42-b014-4137-b28a-e6e63198a21b",
   * "href": "" || "https://something.com",
   * "query": {
   *   "stripe_checkout": "price_1KIcMvAjcnPhf83W5A5Nu7co",
   *   "subdomain": null
   * },
   * "action_uid": "stripe_checkout" | "link",
   * "theme": "slim"
   * }
   */
  action
}) => {
  const query = action.query;

  const price = query && query[PAGE_ACTION_UIDS.STRIPE_CHECKOUT];
  const hasPrice = Boolean(price);

  const contextProps = {
    checkoutAction: checkoutPrice,
    checkoutParams: { price }
  };

  const customClasses = {
    button:
      "fw6 br-6 ph3 pv2 border-box input-reset ba bg-transparent pointer f5 dib link items-center flex"
  };
  let isSecondary;
  if (typeof isEmphasized === "boolean") {
    isSecondary = !isEmphasized;
  } else if (typeof isEmphasized === "boolean" && isNotPrimary) {
    isSecondary = true;
  }
  if (isSecondary) {
    customClasses.contextClass = BUTTON_CLASS_CONTEXT.BLOCKS_SECONDARY;
  }
  const isCheckoutAction =
    PAGE_ACTION_UIDS.STRIPE_CHECKOUT === action.action_uid;
  const invalidCheckoutAction = isCheckoutAction && !hasPrice;

  const ctxDisabled = Boolean(action.disabled || invalidCheckoutAction);
  const { props } = useCheckoutActionProps(
    {
      name,
      index,
      action,
      disabled: ctxDisabled,
      isLive: true,
      isSecondary,
      customClasses
    },
    contextProps
  );

  return <Button {...props} />;
};

const DEFAULT_CLASSES = {
  container: "relative center lh-copy w-100 pt3 pb4",
  nodes: "flex justify-center dib tc"
};
/**
 *
 * @param {Array} actions
 * @param {String} actions.uid
 * @param {String} actions.copy
 * @param {String} actions.uid
 * @param {String} actions.href
 * @param {String} actions.tag
 * @param {String} productIndex
 */
const ProductActions = ({
  uid,
  actions,
  focus,
  productIndex,
  theme,
  isEmphasized,
  customClasses,
  name
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const actionNodes =
    actions &&
    actions.length > 0 &&
    actions.map((action, actionIx) => {
      if (theme) {
        action.theme = theme;
      }
      return (
        <Action
          name={name}
          uid={uid}
          key={actionIx}
          index={actionIx}
          action={action}
          theme={theme}
          isNotPrimary={!actionIx && actions.length > 1}
          isEmphasized={isEmphasized}
        />
      );
    });

  return (
    <ReflectFocus focus={focus}>
      {({ onClick, classes: focusClasses } = {}) => (
        <div
          onClick={onClick}
          className={`${classes.container} ${focusClasses} ${getGridClass({
            suffix: `price_actions--${productIndex}`,
            isEmphasized
          })}`}
        >
          <div className={classes.nodes}>{actionNodes}</div>
        </div>
      )}
    </ReflectFocus>
  );
};

ProductActions.propTypes = {
  productIndex: PropTypes.number.isRequired
};

export default ProductActions;
