import React from "react";
import TableGroupRow from "./TableGroupRow";
import TableHeader from "./TableHeader";

const ProductsFeatureComparisonTable = ({
  entity,
  emphasisIndex,
  afterChange,
  thWidth,
  tdWidth,
  header,
  groups
}) => {
  return (
    <table
      className="w-100 f5"
      cellSpacing="0"
      style={{
        borderCollapse: "separate",
        borderSpacing: 0,
        position: "relative"
      }}
    >
      <TableHeader
        header={header}
        entity={entity}
        emphasisIndex={emphasisIndex}
        afterChange={afterChange}
      />
      <tbody
        className="lh-copy"
        style={{
          display: "table-row-group",
          verticalAlign: "middle"
        }}
      >
        {groups.map(({ columns, rows }, featureGroupIx) => (
          <TableGroupRow
            index={featureGroupIx}
            key={`groupHeader-${featureGroupIx}`}
            columns={columns}
            rows={rows}
            emphasisIndex={emphasisIndex}
            thWidth={thWidth}
            tdWidth={tdWidth}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ProductsFeatureComparisonTable;
