import React from "react";
import TinFields from "components/TinFields";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import { useFieldNameRoot } from "utils/hooks/form";

const Tin = (props) => {
  const { name, validation, focus = [], index } = props;
  const nameRoot = useFieldNameRoot(name);
  const required = validation && validation.required;

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => (
        <div
          id={nameRoot}
          className={`w-100 ${reflectClasses}`}
          onClick={onClick}
        >
          <TinFields noValidation edit={edit} required={required} {...props} />
        </div>
      )}
    </ReflectFocus>
  );
};

export default Tin;
