import React from "react";
import { useFormikContext } from "formik";
import get from "lodash/get";
import MobileDatePicker from "components/MobileDatePicker";
import DesktopDatePicker from "components/DesktopDatePicker";
import { useViewport } from "utils/context";
import { getFieldNameRoot } from "utils/form";
import { STATE_KEYS } from "utils/constants/state";
import { DATEPICKER_TIMEFRAME } from "utils/constants/datepicker";
import ReflectFocus from "components/ReflectFocus";
import { SET_TOUCH_TIMEOUT } from "utils/constants/form";
import LabelDescription from "components/Form/fields/LabelDescription";
import { getFieldDatepickerProps } from "utils/datepicker";
import { useErrorTouched } from "utils/hooks/form";

const Datepicker = (props) => {
  const {
    name,
    label,
    description,
    validation,
    focus = [],
    index,
    disabled
  } = props;

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { isSmall } = useViewport();
  const nameRoot = getFieldNameRoot(name);

  const required = validation && validation.required;
  const { isTouched, error, showError } = useErrorTouched(name);
  /**
   * @param {Date} date - instance of date from the picker
   */
  const onChange = (date) => {
    console.log("UTC:", date);

    setFieldValue(name, date);
    if (!isTouched) {
      setTimeout(() => {
        setFieldTouched(name, true);
      }, SET_TOUCH_TIMEOUT);
    }
  };

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];
  const timeframeType =
    get(props, STATE_KEYS.DATEPICKER.PRESENTATION_TIMEFRAME_TYPE) ||
    DATEPICKER_TIMEFRAME.ANY;
  const controlsTime = Boolean(
    get(props, STATE_KEYS.DATEPICKER.PRESENTATION_CONTROLS_TIME)
  );

  const inputProps = {
    name,
    onChange,
    disabled,
    pickerProps: getFieldDatepickerProps({ isSmall, ...props })
  };
  if (timeframeType) {
    inputProps.key = `${timeframeType}-${controlsTime}`;
  } else if (isSmall) {
    inputProps.key = "mobile";
  } else {
    inputProps.key = "desktop";
  }

  const Picker = isSmall ? MobileDatePicker : DesktopDatePicker;

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => (
        <div
          id={nameRoot}
          className={`w-100 ${reflectClasses}`}
          onClick={onClick}
        >
          <LabelDescription
            nameRoot={nameRoot}
            edit={edit}
            label={label}
            description={description}
            showError={showError}
            required={required}
          />
          <Picker {...inputProps} />
          {showError && <div className="f7 pt1 color-error">{error}</div>}
        </div>
      )}
    </ReflectFocus>
  );
};

export default Datepicker;
