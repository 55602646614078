import css from "styled-jsx/css";
import { PHONE } from "utils/constants/ui";
import { getTemplateAreas } from "utils/pricingTable";

export const getPricingTableStyle = (areaCount) => {
  return css.resolve`
    @media (max-width: ${PHONE}px) {
      :global(.pricingTable-s) {
        display: block;
      }
    }
    @media (min-width: ${PHONE + 1}px) {
      :global(.pricingTable-ns) {
        display: grid;
        grid-template-columns: repeat(${areaCount}, ${100 / areaCount}%);
        grid-template-rows: auto;
        grid-template-areas: ${getTemplateAreas(areaCount)};
      }
    }

    // ====== Title

    :global(.pricingTable__title--0) {
      grid-area: title-0;
    }

    :global(.pricingTable__title--1) {
      grid-area: title-1;
    }

    :global(.pricingTable__title--2) {
      grid-area: title-2;
    }

    :global(.pricingTable__title--3) {
      grid-area: title-3;
    }

    :global(.pricingTable__title--4) {
      grid-area: title-4;
    }

    // ====== Description

    :global(.pricingTable__desc--0) {
      grid-area: desc-0;
    }

    :global(.pricingTable__desc--1) {
      grid-area: desc-1;
    }

    :global(.pricingTable__desc--2) {
      grid-area: desc-2;
    }

    :global(.pricingTable__desc--3) {
      grid-area: desc-3;
    }

    :global(.pricingTable__desc--4) {
      grid-area: desc-4;
    }

    // ====== Price

    :global(.pricingTable__price--0) {
      grid-area: price-0;
    }

    :global(.pricingTable__price--1) {
      grid-area: price-1;
    }

    :global(.pricingTable__price--2) {
      grid-area: price-2;
    }

    :global(.pricingTable__price--3) {
      grid-area: price-3;
    }

    :global(.pricingTable__price--4) {
      grid-area: price-4;
    }

    // ====== Price Details

    :global(.pricingTable__price_details--0) {
      grid-area: price-details-0;
    }

    :global(.pricingTable__price_details--1) {
      grid-area: price-details-1;
    }

    :global(.pricingTable__price_details--2) {
      grid-area: price-details-2;
    }

    :global(.pricingTable__price_details--3) {
      grid-area: price-details-3;
    }

    :global(.pricingTable__price_details--4) {
      grid-area: price-details-4;
    }

    // ====== Price Actions

    :global(.pricingTable__price_actions--0) {
      grid-area: price-actions-0;
    }

    :global(.pricingTable__price_actions--1) {
      grid-area: price-actions-1;
    }

    :global(.pricingTable__price_actions--2) {
      grid-area: price-actions-2;
    }

    :global(.pricingTable__price_actions--3) {
      grid-area: price-actions-3;
    }

    :global(.pricingTable__price_actions--4) {
      grid-area: price-actions-4;
    }

    // ====== Top Features Title

    :global(.pricingTable__topFeatures_title--0) {
      grid-area: top-features-title-0;
    }

    :global(.pricingTable__topFeatures_title--1) {
      grid-area: top-features-title-1;
    }

    :global(.pricingTable__topFeatures_title--2) {
      grid-area: top-features-title-2;
    }

    :global(.pricingTable__topFeatures_title--3) {
      grid-area: top-features-title-3;
    }

    :global(.pricingTable__topFeatures_title--4) {
      grid-area: top-features-title-4;
    }

    // ====== Top Features

    :global(.pricingTable__topFeatures--0) {
      grid-area: top-features-0;
    }

    :global(.pricingTable__topFeatures--1) {
      grid-area: top-features-1;
    }

    :global(.pricingTable__topFeatures--2) {
      grid-area: top-features-2;
    }

    :global(.pricingTable__topFeatures--3) {
      grid-area: top-features-3;
    }

    :global(.pricingTable__topFeatures--4) {
      grid-area: top-features-4;
    }
  `;
};
