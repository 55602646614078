import { useMemo } from "react";
import get from "lodash/get";
import { useFormikContext } from "formik";
import { STATE_KEYS } from "utils/constants/state";
import { useAppCheckout } from "utils/selectors";
import { getFieldPath, getFieldNameRoot } from "utils/form";
import { useRouter } from "next/router";

export const useFieldNameRoot = (name) =>
  useMemo(() => getFieldNameRoot(name), [name]);

export const useSelectOptions = ({ name, options, template }) => {
  const appCheckout = useAppCheckout();
  const { values } = useFormikContext();

  const storeOptionSets = get(appCheckout, STATE_KEYS.APP_CHECKOUT.OPTION_SETS);
  const fieldPath = getFieldPath(name, STATE_KEYS.MULTISELECT.OPTIONS_SETS);
  const fieldOptionsSets = get(values, fieldPath);

  return useMemo(() => {
    let result = [];
    if (template) {
      result.push(template);
    }

    result = result.concat(options);

    if (fieldOptionsSets && storeOptionSets) {
      const matches = storeOptionSets.filter(
        (optionSet) => fieldOptionsSets[optionSet.uuid]
      );
      if (matches.length) {
        matches.forEach((optionSet) => {
          result = result.concat(optionSet.options);
        });
      }
    }

    return result;
  }, [fieldOptionsSets, options, storeOptionSets]);
};

export const useErrorTouched = (name) => {
  const { errors, touched } = useFormikContext();
  const { query } = useRouter();
  const isTouched = get(touched, name);
  const error = get(errors, name);
  const hasPrefill = Boolean(query.recovery) || Boolean(query.prefill);
  const touchedOrPrefilled = Boolean(isTouched || hasPrefill);
  const showError = Boolean(touchedOrPrefilled && error);

  return {
    error,
    touched,
    showError
  };
};
