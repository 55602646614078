import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ProductActions from "../ProductActions";
import SubtextSection from "../SubtextSection";
import { getGridClass } from "../utils";
import FormattedPriceHeader from "../FormattedPriceHeader";
import { EditorFocusType } from "utils/propTypes";
import { deconstructFormattedPrice } from "utils/price";

const PricingInfoSection = ({
  uid,
  name,
  focus,
  productIndex,
  subtext,
  formattedPrice,
  actions,
  isEmphasized
}) => {
  const base = {
    uid,
    name,
    focus,
    isEmphasized
  };
  const { symbol, formatted } = deconstructFormattedPrice(formattedPrice);

  return (
    <Fragment>
      <div
        className={classnames(
          getGridClass({
            suffix: `price--${productIndex}`,
            isEmphasized
          }),
          {
            pb3: !subtext
          }
        )}
      >
        {formatted && (
          <FormattedPriceHeader
            formatted={formatted}
            symbol={symbol}
            {...base}
          />
        )}
      </div>
      <SubtextSection subtext={subtext} productIndex={productIndex} {...base} />
      <ProductActions actions={actions} productIndex={productIndex} {...base} />
    </Fragment>
  );
};

PricingInfoSection.propTypes = {
  uid: PropTypes.string,
  name: PropTypes.string,
  focus: PropTypes.arrayOf(PropTypes.shape(EditorFocusType)),
  productIndex: PropTypes.number,
  subtext: PropTypes.array,
  formattedPrice: PropTypes.string,
  actions: PropTypes.array
};

export default PricingInfoSection;
