import React, { Fragment } from "react";
import { components as ReactSelectComponents } from "react-select";
import GoogleBrand from "./GoogleBrand";

const SelectMenu = (props) => {
  return (
    <ReactSelectComponents.Menu
      {...props}
      className="react-select-container"
      classNamePrefix="multiselect"
    >
      {props.options.length ? (
        <Fragment>
          {props.children}
          <div className="pa2">
            <GoogleBrand />
          </div>
        </Fragment>
      ) : (
        <div className="flex flex-column pa2">
          <div className="f7 black-70 pt2 pb3">Type to search by address.</div>
          <GoogleBrand />
        </div>
      )}
    </ReactSelectComponents.Menu>
  );
};

export default SelectMenu;
