import React, { useEffect, useState, Fragment } from "react";
import FileCard from "./FileCard";
import { useFormikContext } from "formik";
import { useEditorSubmitting } from "utils/hooks/editor";
import ReflectFocus from "components/ReflectFocus";
import ActionRowLoader from "components/ActionRowLoader";
import { anonSignAssetPick } from "utils/image";
import { X_API_KEY, X_ATTACHMENT_KEY } from "utils/constants/header";
import { usePageFactoryContext } from "utils/context";
import { getFieldNameRoot, getFieldPath } from "utils/form";
import { ADMIN, STATE_KEYS } from "utils/constants/state";
import { getUUID } from "utils/uuid";
import isError from "lodash/isError";
import get from "lodash/get";
import {
  clearStoreAttatchmentField,
  formatUploadedAttachment,
  getStoreAttatchmentField,
  prepareCreateAttachments,
  setStoreAttatchmentField
} from "utils/attachment";
import {
  createAnonAttachment,
  deleteAnonAttachment,
  fetchAnonAttachment
} from "actions/attachment";
import {
  ATTACHMENT_CONTEXT_TYPE,
  DEFAULT_UPLOAD_SOURCES,
  DEFAULT_UPLOAD_TYPES,
  TWO_MB_LIMIT
} from "utils/constants/attachment";
import { useFilePicker } from "utils/hooks/attachment";
import Button from "components/Form/fields/Button";
import {
  BUTTON_CLASS_CONTEXT,
  UI_THEME,
  ERROR_PILL,
  STATUS,
  DEFAULT_CHECKOUT_ACTION_CLASSES
} from "utils/constants/ui";
import ReactDOM from "react-dom";
import { PickerOverlay } from "filestack-react";
import { useIsScratch } from "utils/hooks/route";
import { useEditorMode } from "utils/hooks/manifest";
import { EDITOR_MODE } from "utils/constants/editor";
import InfoNotice from "components/InfoNotice";
import ConfirmButton from "components/ConfirmButton";
import classnames from "classnames";

const DEFAULT_ERROR_TITLE = "Error";
const DEFAULT_ERROR_prompt =
  "Theres an error with this file. Please reset and re-upload.";

const FileFields = (props) => {
  const {
    name,
    validation,
    value,
    focus = [],
    index,
    uuid,
    label,
    description
  } = props;
  const required = validation && validation.required;
  const {
    values,
    touched,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const {
    canActivateSettings,
    stateKey,
    isAuthedBuilder
  } = usePageFactoryContext();

  const adminRootPath = getFieldPath(stateKey, ADMIN);
  const nameRoot = getFieldNameRoot(name);

  const mode = useEditorMode();
  const editMode = mode === EDITOR_MODE.EDIT;

  const assetUploadKey = get(values, `${adminRootPath}.assetUploadKey`);
  const subdomain = get(values, `${adminRootPath}.subdomain`);
  const alias = get(values, `${adminRootPath}.alias`);
  let pickerTags;
  if (subdomain || alias) {
    pickerTags = {};
    if (subdomain) {
      pickerTags.subdomain = subdomain;
    }
    if (alias) {
      pickerTags.alias = alias;
    }
  }

  if (!assetUploadKey) {
    console.error("Missing assetUploadKey");
    return null;
  }
  const headers = {
    [X_API_KEY]: assetUploadKey
  };
  if (value) {
    headers[X_ATTACHMENT_KEY] = value;
  }

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attachment, setAttachment] = useState(null);

  const editorSubmitting = useEditorSubmitting();

  const fieldFocus = [
    ...focus,
    {
      key: STATE_KEYS.EDITOR.MENU_ACTIVE_SUB_INDEX,
      value: index
    }
  ];

  const initialSession = getStoreAttatchmentField(uuid);

  const fetchStatus = async (loadingHandler) => {
    setError(null);
    setLoading(true);

    const fetchedAttachment = await fetchAnonAttachment({
      setLoading: loadingHandler || setLoading,
      headers,
      onError: (err) => {
        if (err) {
          setError(err);
        }
      }
    });

    setLoading(false);

    if (isError(fetchedAttachment)) {
      setError(fetchedAttachment);
    } else if (fetchedAttachment) {
      setAttachment(fetchedAttachment);
    }
  };

  const resetField = () => {
    clearStoreAttatchmentField(uuid);
    setFilePickerError(null);
    setError(null);
    setAttachment(null);
    setFieldValue(name, null);
  };

  const removeAnonAttachment = async (loadingHandler) => {
    setError(null);
    setLoading(true);

    const deletedAttachment = await deleteAnonAttachment({
      setLoading: loadingHandler || setLoading,
      headers,
      onError: (err) => {
        if (err) {
          setError(err);
        }
      }
    });

    setLoading(false);

    if (isError(deletedAttachment)) {
      setError(deletedAttachment);
    } else if (deletedAttachment) {
      resetField();
    }
  };

  /**
   * If an initial attachment id exists then sync it to form value when
   * - no value exists
   * - is scratch page or is not in editor mode
   */
  const isScratch = useIsScratch();
  const shouldSync =
    initialSession && !value && (isScratch || !canActivateSettings);

  useEffect(() => {
    if (shouldSync) {
      setFieldValue(name, initialSession);
    }
  }, [shouldSync]);

  useEffect(() => {
    if (value) {
      fetchStatus();
    }
  }, [value]);

  /**
   * Automatically save the attachment after picker upload
   * - Then set the result value in the form
   * @param {Array} files
   */
  const pickerDoneHandler = async (files) => {
    const data = prepareCreateAttachments([
      formatUploadedAttachment({
        attachment: {
          uuid: getUUID()
        },
        context: ATTACHMENT_CONTEXT_TYPE.UPLOAD,
        file: files[0]
      })
    ]);
    setLoading(true);
    try {
      const result = await createAnonAttachment({
        data: data.attachments[0],
        headers,
        setLoading: setLoading,
        setError
      });

      /**
       * Local storage
       * - Set the encyrpted attachment reference in local storage so can be fetched on mount of parent component
       */
      setStoreAttatchmentField({
        key: uuid,
        value: result.storage.value
      });
      /**
       * Set the value that will be stored in form data
       */
      setFieldValue(name, result.storage.value);
      /**
       * Set the full local attachment object
       */
      setAttachment(result.attachment);

      const isTouched = get(touched, name);
      if (!isTouched) {
        setFieldTouched(name, true);
      }
    } catch (err) {
      setError(err);
    }
  };

  const allowedTypes = get(props, STATE_KEYS.FILE.ALLOWED_TYPES) || [];
  let allowedTypesUID = allowedTypes.map(({ uid }) => uid);
  const fromSources = get(props, STATE_KEYS.FILE.FROM_SOURCES) || [];
  let fromSourcesUID = fromSources.map(({ uid }) => uid);
  /**
   * When none are specified then all are allowed
   */
  if (allowedTypesUID.length === 0) {
    allowedTypesUID = DEFAULT_UPLOAD_TYPES;
  }
  if (fromSourcesUID.length === 0) {
    fromSourcesUID = DEFAULT_UPLOAD_SOURCES;
  }

  const pickerOptions = {
    fromSources: fromSourcesUID,
    accept: allowedTypesUID,
    maxSize: TWO_MB_LIMIT,
    maxFiles: 1
  };
  if (pickerTags) {
    pickerOptions.uploadConfig = {
      tags: pickerTags
    };
  }

  const {
    pickerProps,
    security,
    loading: filePickerLoading,
    error: filePickerError,
    setError: setFilePickerError,
    showPicker,
    setShowPicker,
    fetchImageSign
  } = useFilePicker({
    assetPick: anonSignAssetPick,
    headers,
    pickerOptions,
    onPickerDone: pickerDoneHandler
  });

  useEffect(() => {
    if (filePickerError && !error) {
      setError(filePickerError);
    }
  }, [filePickerError, error]);

  const showPickerOverlay = Boolean(showPicker && security);

  let icon = null;
  let header = null;
  let title = null;
  let prompt = null;
  let pill = null;
  let mimetype = null;
  let footer = null;
  let action = null;
  let children = null;
  let confirmAction = null;
  let initialState = false;
  header = get(props, STATE_KEYS.FILE.HEADER);
  if (!loading) {
    if (attachment) {
      confirmAction = {
        copy: "Remove",
        onConfirm: removeAnonAttachment,
        slides: [
          {
            title: "Remove file",
            header: "Are you sure you want to remove this file?"
          }
        ]
      };
      title = get(attachment, "data.filename");
      mimetype = get(attachment, "data.mimetype");
    } else {
      initialState = true;
      title = get(props, STATE_KEYS.FILE.TITLE);
      prompt = get(props, STATE_KEYS.FILE.PROMPT);
    }
  }

  const ctxError = !editorSubmitting && error;
  const canUpload = !value && !attachment && security;

  /**
   * Disable when canActivate
   */
  const buttonProps = {
    theme: UI_THEME.SLIM,
    /**
     * Disable autocomplete when disabled or in the context of the editor
     */
    disabled: Boolean(props.disabled || canActivateSettings),
    classes: {
      contextClass: BUTTON_CLASS_CONTEXT.BLOCKS_PRIMARY,
      button: DEFAULT_CHECKOUT_ACTION_CLASSES.button
    }
  };

  if (ctxError) {
    pill = ERROR_PILL;
    title = get(ctxError, "response.data.error") || DEFAULT_ERROR_TITLE;
    prompt = get(ctxError, "response.data.message") || DEFAULT_ERROR_prompt;

    action = (
      <Button {...buttonProps} copy="Reset" onClick={() => resetField()} />
    );
  } else if (!security && !filePickerLoading && !editMode) {
    pill = ERROR_PILL;
    title = "Uploader error";
    prompt = "Refresh to enable uploads.";

    action = (
      <Button
        {...buttonProps}
        onClick={() => fetchImageSign()}
        copy="Refresh"
      />
    );
  } else if (canUpload) {
    action = (
      <Button
        {...buttonProps}
        onClick={() => {
          if (showPicker) {
            setShowPicker(false);
          }
          setTimeout(() => setShowPicker(true), 0);
        }}
        copy="Upload"
      />
    );
  } else if (confirmAction) {
    action = (
      <ConfirmButton
        {...confirmAction}
        classes={{
          contextClass: BUTTON_CLASS_CONTEXT.BLOCKS_SECONDARY,
          button: DEFAULT_CHECKOUT_ACTION_CLASSES.button
        }}
      />
    );
  }
  if (attachment) {
    pill = {
      status: STATUS.SUCCESS,
      theme: UI_THEME.SLIM,
      copy: "Uploaded"
    };
  }

  if (action || mimetype) {
    const layoutBetween = mimetype && action;
    footer = (
      <div
        className={classnames("w-100 flex flex-row items-center", {
          "justify-between": layoutBetween,
          "justify-end": !layoutBetween
        })}
      >
        <div className="f7">{mimetype}</div>
        <div className="dib">{action}</div>
      </div>
    );
  }

  const fileCardProps = {
    name,
    label,
    description,
    icon,
    required,
    header,
    title,
    prompt,
    pill,
    footer,
    children
  };

  return (
    <ReflectFocus focus={fieldFocus}>
      {({ onClick, hover, classes: reflectClasses, edit } = {}) => (
        <div
          id={nameRoot}
          onClick={onClick}
          className={`w-100 ${reflectClasses}`}
        >
          {loading ? (
            <ActionRowLoader />
          ) : (
            <Fragment>
              <FileCard
                edit={Boolean(edit && initialState)}
                {...fileCardProps}
              />
              {isAuthedBuilder ? (
                <div className="pv1">
                  <InfoNotice
                    customClasses={{
                      copy: "pl2 f7 black-60"
                    }}
                    copy="File upload only enabled on live pages."
                  />
                </div>
              ) : null}
            </Fragment>
          )}
          {showPickerOverlay &&
            ReactDOM.createPortal(
              <PickerOverlay {...pickerProps} />,
              document.body
            )}
        </div>
      )}
    </ReflectFocus>
  );
};

export default FileFields;
