import { getToday } from "./date";
import { DATEPICKER_TIMEFRAME } from "utils/constants/datepicker";
import { STATE_KEYS } from "utils/constants/state";
import get from "lodash/get";

export const getFieldDatepickerProps = (props) => {
  const today = props.today || getToday();

  const result = {};
  const timeframeType =
    get(props, STATE_KEYS.DATEPICKER.PRESENTATION_TIMEFRAME_TYPE) ||
    DATEPICKER_TIMEFRAME.ANY;
  const controlsTime = Boolean(
    get(props, STATE_KEYS.DATEPICKER.PRESENTATION_CONTROLS_TIME)
  );

  /**
   * Desktop datepicker keys end with *Date
   */
  const minKey = props.isSmall ? "min" : "minDate";
  const maxKey = props.isSmall ? "max" : "maxDate";

  if (timeframeType === DATEPICKER_TIMEFRAME.ANY) {
    result[minKey] = null;
    result[maxKey] = null;
  } else if (timeframeType === DATEPICKER_TIMEFRAME.FUTURE) {
    result[minKey] = today;
  } else if (timeframeType === DATEPICKER_TIMEFRAME.PAST) {
    result[maxKey] = today;
  }
  if (controlsTime) {
    result.showTimeInput = true;
  }

  return result;
};
