import React from "react";
import PropTypes from "prop-types";
import { ENTITY_HOVER_UNDERLINE, FORMER_MAX_WIDTH } from "utils/constants/ui";
import { usePageFactoryContext } from "utils/context";
import { STATE_KEYS } from "utils/constants/state";
import EmptyProductCollectionPrompt from "./EmptyProductCollectionPrompt";
import EmptySplitLayout from "./EmptySplitLayout";
import ReflectFocus from "components/ReflectFocus";
import ListLayout from "./ListLayout";
import GridLayout from "./GridLayout";
import classnames from "classnames";
import get from "lodash/get";

import { EDITOR_PRESENTATION } from "utils/constants/editor";
import { PRODUCT_COLLECTION_INTENT } from "utils/constants/pricing";
import SplitLayout from "./SplitLayout";
import { usePageElementNameRoot } from "utils/hooks/manifest";

const EDITOR_PRESENTATION_COMPONENT_MAP = {
  [EDITOR_PRESENTATION.SPLIT]: SplitLayout,
  [EDITOR_PRESENTATION.GRID]: GridLayout,
  [EDITOR_PRESENTATION.LIST]: ListLayout
};

const EMPTY_PRESENTATION_COMPONENT_MAP = {
  [EDITOR_PRESENTATION.SPLIT]: EmptySplitLayout,
  [EDITOR_PRESENTATION.GRID]: EmptyProductCollectionPrompt,
  [EDITOR_PRESENTATION.LIST]: EmptyProductCollectionPrompt
};

const FormerProductCollection = (props) => {
  const { name, entity, afterChange, data, config, ui, ref, entityRef } = props;
  const nameRoot = usePageElementNameRoot(name);
  const { canActivateSettings } = usePageFactoryContext();

  const hasProducts = Boolean(
    data && data.products && data.products.length > 0
  );

  const containerProps = {
    style: { maxWidth: FORMER_MAX_WIDTH },
    id: nameRoot,
    ref: entityRef
  };

  const presentationValue = get(config, "presentation");
  const PresentationComponent =
    EDITOR_PRESENTATION_COMPONENT_MAP[presentationValue];
  const EmptyComponent = EMPTY_PRESENTATION_COMPONENT_MAP[presentationValue];

  const sharedFocus = [
    {
      key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
      value: name
    },
    {
      key: STATE_KEYS.EDITOR.MENU_INTENT,
      value: PRODUCT_COLLECTION_INTENT.PRODUCT_ORDER
    }
  ];

  if (hasProducts && PresentationComponent) {
    if (presentationValue === EDITOR_PRESENTATION.SPLIT) {
      return <SplitLayout {...props} />;
    } else {
      const collectionInput = {
        name,
        entity,
        focus: sharedFocus,
        afterChange
      };

      return (
        <ReflectFocus focus={sharedFocus} name={entity.schema.title} withHeader>
          {({ hover } = {}) => {
            return (
              <div
                {...containerProps}
                className={classnames("w-100", {
                  "bg-white": !hover
                })}
              >
                <div className="f2 pt5 fw6 ph3 ph0-ns lh-title">
                  {data.title}
                </div>
                {data.subtitle && (
                  <div className="f3 pt2 fw5 ph3 ph0-ns lh-title">
                    {data.subtitle}
                  </div>
                )}
                <div className="w-100 ph3 pt3">
                  <PresentationComponent {...collectionInput} />
                </div>
              </div>
            );
          }}
        </ReflectFocus>
      );
    }
  } else if (canActivateSettings && EmptyComponent) {
    return (
      <ReflectFocus
        focus={sharedFocus}
        classes={ENTITY_HOVER_UNDERLINE}
        withHeader
      >
        {({ onClick, classes, hover } = {}) => (
          <div
            onClick={onClick}
            {...containerProps}
            className={classnames(`w-100 ${classes}`, {
              "bg-white": !hover
            })}
          >
            <EmptyComponent {...props} count={6} />
          </div>
        )}
      </ReflectFocus>
    );
  } else {
    return <span />;
  }
};

FormerProductCollection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    header: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      })
    ),
    paymentLinks: PropTypes.arrayOf(
      PropTypes.shape({
        shortId: PropTypes.string
      })
    )
  })
};

export default FormerProductCollection;
