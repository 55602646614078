import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import Overlay from "components/Overlay";
import {
  FORMER_MAX_WIDTH,
  getLeftSplitStyles,
  OPERATOR_ICON,
  SPLIT_CONTAINER_SECTION_NS_CLASS,
  SPLIT_CONTENT_MAX_WIDTH
} from "utils/constants/ui";
import { STATE_KEYS } from "utils/constants/state";
import ReflectFocus from "components/ReflectFocus";
import get from "lodash/get";
import { shortLinkCheckoutPreview } from "components/FormerEditor/common/preview";
import FormerCartCheckoutAction from "components/FormerCartCheckoutAction";
import { processViewportClasses } from "utils/editor";
import BrandingHeader from "components/BrandingHeader";
import classnames from "classnames";
import { PRODUCT_COLLECTION_INTENT } from "utils/constants/pricing";
import PageCheckoutPreview, {
  MAX_CHECKOUT_PREVIEW_ITEMS
} from "components/PageCheckoutPreview";
import EditableContent from "components/EditableContent";
import InitialPreview from "components/FormerInitialPreview";
import ListLayout from "./ListLayout";
import {
  getCheckoutCartShortLinkProduct,
  checkoutPreviewLineCount
} from "utils/checkout";
import { PAGE_ACTION_UIDS } from "components/FormerEditor/common/constants";
import { validateShortLink } from "utils/shortLink";
import PageBarCheckoutPreview from "components/PageBarCheckoutPreview";
import ReactVisibilitySensor from "react-visibility-sensor";
import { useCommonEditorEntityParams } from "utils/hooks/editor";
import HoverIconButton from "components/HoverIconButton";
import StripeRefreshNotice from "components/StripeRefreshNotice";
import { EMBED_LAYOUT } from "utils/constants/embed";
import { getEmbedState } from "utils/manifest";

const SplitLayout = ({
  name,
  entity,
  afterChange,
  data: { title, subtitle, products, actions },
  config,
  ui,
  ref,
  entityRef
}) => {
  const [showCheckoutBar, setShowCheckoutBar] = useState(false);
  const [showTruncatedDetails, setShowTruncatedDetails] = useState(false);
  const [showCheckoutBarOverlay, setShowCheckoutBarOverlay] = useState(false);
  const menuIntent = PRODUCT_COLLECTION_INTENT.PRODUCT_ORDER;

  const {
    sharedFocus,
    fieldGroupIndexFocus,
    canActivateSettings,
    values,
    editorViewport,
    isLive,
    hasNavPrev,
    formIsFullScreen,
    brandAssets,
    hasBrandingAssets,
    manifestData,
    dataPath,
    contentPath,
    showInitialPreview,
    setFieldValue,
    embed,
    stripeRefreshRequired
  } = useCommonEditorEntityParams({
    entity,
    name,
    menuIntent
  });
  // ================== COMMON ABOVE

  const entityContent = get(values, contentPath);
  const cartProductsPath = `${contentPath}.${STATE_KEYS.PRODUCT_COLLECTION.CONFIG_CART_PRODUCTS}`;
  const cartProducts = get(values, cartProductsPath) || [];

  const { disabledOrSplitLayout, enabledSingleLayout } = getEmbedState(embed);

  const compClasses = processViewportClasses(
    {
      background: `dn dn-m flex-ns flex-row ${
        formIsFullScreen ? "fixed" : "absolute"
      } top-0 left-0 right-0 bottom-0 overflow-hidden`,
      container: classnames(
        "flex flex-column flex-column-m flex-row-ns w-100 relative",
        {
          "bg-white bg-transparent-ns": disabledOrSplitLayout,
          "bg-transparent": enabledSingleLayout
        }
      ),
      content: classnames(
        "flex flex-column flex-column-m flex-row-ns w-100 ph3 ph0-ns center z-1 items-center items-center-m items-start-ns justify-between-ns form__content--paddingTop-ns",
        {
          "pv5": !hasBrandingAssets,
          "pt2 pb5": hasBrandingAssets
        }
      ),
      // Alternate between fixed and absolute due to page and editor environments
      checkoutBar: classnames(
        "left-0 right-0 top-0 bg-white shadow-0 dib w-100 dn-ns z-2",
        {
          fixed: !canActivateSettings,
          absolute: canActivateSettings
        }
      ),
      left: classnames(
        `${SPLIT_CONTAINER_SECTION_NS_CLASS} flex flex-column w-100 w-50-ns ph3`,
        {
          "sticky-l": disabledOrSplitLayout,
          "top-2-l": !hasNavPrev,
          "top-3-l": hasNavPrev
        }
      ),
      right: `${SPLIT_CONTAINER_SECTION_NS_CLASS} w-100 w-50-ns flex flex-column ph3`,
      brandingWrapper: "w4 pt3 pt0-ns",
      resultContainer: hasBrandingAssets ? "pt4 pt3-ns" : "pt0",
      title: "f3 f2-ns fw6 tl mb2",
      subtitle: "f4 f3-ns tl lh-copy w-100 center pb3 pb4-ns",
      initialPreview: "w-100",
      desktopCheckoutButton:
        "pb4 pb0-ns pt2-ns dn flex-column flex-ns center justify-end-ns w-100-ns",
      portalTrigger:
        "absolute left-0 right-0 bottom-0 h3 color__gradient dn dib-ns"
    },
    editorViewport
  );

  let previewContent = null;
  let checkoutBar = null;
  const cartCount = cartProducts.length;
  const hasCartItems = cartCount > 0;
  if (hasCartItems) {
    const hasMultipleCartItems = cartCount > 1;
    const shortLink = {
      data: {
        product: getCheckoutCartShortLinkProduct({
          content: entityContent,
          cart: { products: cartProducts }
        })
      }
    };
    const linkValidations = validateShortLink({
      shortLink,
      ...manifestData
    });
    const checkoutPreview = shortLinkCheckoutPreview({
      shortLink,
      ...manifestData
    });

    const checkoutProps = {
      action: {
        action_uid: PAGE_ACTION_UIDS.STRIPE_CHECKOUT,
        copy: hasMultipleCartItems ? "Buy all" : "Buy"
      },
      isLive,
      name,
      cart: { products: cartProducts },
      hideControls: true
    };

    const checkoutAction = <FormerCartCheckoutAction {...checkoutProps} />;

    checkoutBar = !embed.enabled ? (
      <PageBarCheckoutPreview
        showOverlay={showCheckoutBarOverlay}
        setShowOverlay={setShowCheckoutBarOverlay}
        collapseImages={checkoutPreview.collapseImages}
        {...checkoutPreview.checkout}
      >
        <FormerCartCheckoutAction {...checkoutProps} />
      </PageBarCheckoutPreview>
    ) : null;

    /**
     * If there are too many line items we need to truncate the list
     * The checkout can be previewed within an overlay
     */
    const lineCount = checkoutPreviewLineCount(checkoutPreview.checkout);
    const truncatePreview =
      lineCount > MAX_CHECKOUT_PREVIEW_ITEMS && !embed.enabled;
    const previewClasses = processViewportClasses(
      {
        checkoutAction: classnames("dn dib-ns", {
          "pl5-ns": !checkoutPreview.collapseImages && hasMultipleCartItems
        })
      },
      editorViewport
    );

    const portalContent = (
      <Fragment>
        <PageCheckoutPreview
          collapseImages={checkoutPreview.collapseImages}
          {...checkoutPreview.checkout}
        >
          {checkoutAction}
        </PageCheckoutPreview>
        <div
          className={classnames(compClasses.desktopCheckoutButton, {
            [previewClasses.checkoutAction]: !checkoutPreview.collapseImages
          })}
        >
          {checkoutAction}
        </div>
      </Fragment>
    );

    const pageCheckoutPreviewProps = {
      truncatePreview,
      collapseImages: checkoutPreview.collapseImages,
      ...checkoutPreview.checkout
    };

    previewContent = (
      <Fragment>
        <div className="w-100 relative">
          <PageCheckoutPreview {...pageCheckoutPreviewProps}>
            {checkoutAction}
          </PageCheckoutPreview>
          {truncatePreview ? (
            <div className={compClasses.portalTrigger}>
              <div
                className="w-100 justify-center items-end flex h-100"
                onClick={() => {
                  setShowTruncatedDetails(true);
                }}
              >
                <div
                  className={classnames("w-100", {
                    [previewClasses.checkoutAction]: !checkoutPreview.collapseImages
                  })}
                >
                  <div
                    style={{ width: 104 }}
                    className="w4 shadow-0 ba hairline-1 bg-white pa1 br2 center pointer bg-white--hover"
                  >
                    <div className="f6 fw6 tc black-70">{`${
                      showTruncatedDetails ? "Hide" : "Show"
                    } details`}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {hasCartItems ? (
          <div
            className={classnames(compClasses.desktopCheckoutButton, {
              [previewClasses.checkoutAction]: !checkoutPreview.collapseImages
            })}
          >
            <div className="flex flex-row">
              <div className="pt3 pb4 pr1">
                <HoverIconButton
                  key="operator"
                  size={22}
                  type={OPERATOR_ICON.SUBTRACT}
                  onClick={() => {
                    setFieldValue(cartProductsPath, []);
                  }}
                />
              </div>
              {checkoutAction}
            </div>
          </div>
        ) : null}
        {showTruncatedDetails &&
          ReactDOM.createPortal(
            <Overlay hideOverlay={() => setShowTruncatedDetails(false)}>
              <div className="pa3 pa4-ns">{portalContent}</div>
            </Overlay>,
            document.body
          )}
      </Fragment>
    );
  } else {
    previewContent = (
      <ReflectFocus
        focus={[
          {
            key: STATE_KEYS.EDITOR.ACTIVE_BLOCK,
            value: name
          },
          {
            key: STATE_KEYS.EDITOR.MENU_INTENT,
            value: PRODUCT_COLLECTION_INTENT.PRESENTATION_SETTINGS
          }
        ]}
      >
        {({ onClick, classes: targetedClasses, edit } = {}) => (
          <div
            onClick={onClick}
            className={classnames(
              `${compClasses.initialPreview} ${targetedClasses}`,
              {
                dn: !showInitialPreview && !edit
              }
            )}
          >
            <InitialPreview name={dataPath} edit={edit} />
          </div>
        )}
      </ReflectFocus>
    );
  }
  const checkboutBarVisible =
    showCheckoutBar && hasCartItems && checkoutBar && !embed;
  /**
   * When the top section scrolls off the screen we want to show the sticky header for checkout, if we can
   */
  const stickyHeaderSensor = (
    <ReactVisibilitySensor>
      {({ isVisible }) => {
        if (!showCheckoutBarOverlay) {
          setShowCheckoutBar(!isVisible);
        }

        return (
          <div style={{ height: 1 }} className="w-100 bg-transparent"></div>
        );
      }}
    </ReactVisibilitySensor>
  );

  return (
    <ReflectFocus name={entity.schema.title} focus={sharedFocus} withHeader>
      {({ hover, classes } = {}) => {
        return (
          <div
            ref={entityRef}
            className={compClasses.container}
            style={canActivateSettings ? { maxWidth: FORMER_MAX_WIDTH } : {}}
          >
            {checkboutBarVisible && (
              <div id="checkoutBar" className={compClasses.checkoutBar}>
                {checkoutBar}
              </div>
            )}
            <div
              style={canActivateSettings ? {} : { zIndex: -1 }}
              className={compClasses.background}
            >
              <div className="w-50 bg-off-white-ns"></div>
              <div className="w-50 split__container-ns bg-white"></div>
            </div>
            <div
              style={{ maxWidth: SPLIT_CONTENT_MAX_WIDTH }}
              className={compClasses.content}
            >
              <div
                style={getLeftSplitStyles({
                  hasBrandingAssets,
                  showInitialPreview
                })}
                className={compClasses.left}
              >
                {hasBrandingAssets && (
                  <div className={compClasses.brandingWrapper}>
                    <BrandingHeader
                      {...brandAssets}
                      viewport={editorViewport}
                    />
                  </div>
                )}
                <div className={compClasses.resultContainer}>
                  {previewContent}
                </div>
                {stickyHeaderSensor}
              </div>
              <div className={compClasses.right}>
                {stripeRefreshRequired ? (
                  <StripeRefreshNotice />
                ) : (
                  <Fragment>
                    <ReflectFocus focus={fieldGroupIndexFocus}>
                      {({ onClick, classes: targetedClasses, edit } = {}) => (
                        <div
                          onClick={onClick}
                          className={classnames(
                            `${compClasses.title} ${targetedClasses}`,
                            {
                              dn: !title && !edit,
                              pb3: !subtitle && !edit
                            }
                          )}
                        >
                          <EditableContent
                            editable={edit}
                            sync={{
                              name: `${dataPath}.title`
                            }}
                          >
                            {title}
                          </EditableContent>
                        </div>
                      )}
                    </ReflectFocus>
                    <ReflectFocus focus={fieldGroupIndexFocus}>
                      {({ onClick, classes: targetedClasses, edit } = {}) => (
                        <div
                          onClick={onClick}
                          className={classnames(
                            `${compClasses.subtitle} ${targetedClasses}`,
                            {
                              dn: !subtitle && !edit
                            }
                          )}
                        >
                          <EditableContent
                            editable={edit}
                            sync={{
                              name: `${dataPath}.subtitle`
                            }}
                          >
                            {subtitle}
                          </EditableContent>
                        </div>
                      )}
                    </ReflectFocus>
                    <ListLayout name={name} focus={sharedFocus} />
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </ReflectFocus>
  );
};

export default SplitLayout;
