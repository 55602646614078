import React from "react";
import { usePageFactoryContext } from "utils/context";
import { useFormikContext } from "formik";
import { getFieldPath } from "utils/form";
import { PAYMENT_ACTION } from "utils/constants/payment";
import { gridContainer } from "constants/styles";
import ContactlessPaymentLink from "components/ContactlessPaymentLink";
import get from "lodash/get";
import Padder from "components/Padder";

const GridLayout = ({ name }) => {
  const { stateKey } = usePageFactoryContext();
  const { values } = useFormikContext();

  const paymentActionPath = getFieldPath(
    stateKey,
    `content.${name}.config.paymentAction`
  );
  const paymentAction = get(values, paymentActionPath);

  const productsPath = getFieldPath(stateKey, `content.${name}.data.products`);
  const products = get(values, productsPath, []);

  const contactless = paymentAction === PAYMENT_ACTION.SCAN;

  return (
    <div className="grid-container center pt4">
      {products.map((paymentLink, linkIx) => {
        return (
          <div key={linkIx} className="pt4">
            <ContactlessPaymentLink
              contactless={contactless}
              {...paymentLink}
            />
          </div>
        );
      })}
      <Padder theme="visible" />
      <style jsx>{gridContainer}</style>
    </div>
  );
};

export default GridLayout;
