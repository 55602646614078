import React from "react";
import { STATE_KEYS } from "utils/constants/state";
import get from "lodash/get";
import { getProductCollectionCheckoutSettingsFocus } from "utils/hooks/focus";
import SlimCTA from "components/SlimCTA";
import { addProductToCollection } from "utils/product";
import { setEditorFocus } from "utils/builder";
import { getSchemaValuesForEditor } from "utils/schema";
import { useFormikContext } from "formik";

const AddProductCTA = ({ entity }) => {
  const { values, setValues, setFieldTouched } = useFormikContext();
  const name = `${STATE_KEYS.MANIFEST.CONTENT}.${entity.uuid}`;
  const productsPath = `${name}.${STATE_KEYS.PRODUCT_COLLECTION.PRODUCTS}`;
  const products = get(values, productsPath) || [];
  const focus = getProductCollectionCheckoutSettingsFocus({
    uuid: entity.uuid
  });
  const { template } = getSchemaValuesForEditor({
    name,
    propertyKey: "products",
    schema: entity.schema
  });

  return (
    <SlimCTA
      header="No products"
      subheader="Add or create a product for your page."
      actions={[
        {
          copy: "Add",
          onClick: () => {
            addProductToCollection({
              values,
              setValues,
              setFieldTouched,
              template,
              productsPath: `${name}.${STATE_KEYS.PRODUCT_COLLECTION.PRODUCTS}`
            })();
            setEditorFocus({
              values,
              setValues,
              focus: [
                ...focus,
                {
                  key: STATE_KEYS.EDITOR.MENU_ACTIVE_INDEX,
                  value: products.length
                }
              ]
            })();
          }
        }
      ]}
    />
  );
};

export default AddProductCTA;
