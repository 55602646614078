import { useState, useEffect } from "react";
import { getConfig } from "utils/env";
import { signAssetPick } from "utils/image";
import isError from "lodash/isError";

export const useFilePicker = ({
  assetPick,
  headers,
  onPickerDone,
  pickerOptions
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [security, setSecurity] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  /**
   * Note: assetRead is not passed
   * - need documentation on pick and read logic
   */
  const ctxSignAssetPick = assetPick || signAssetPick;

  const fetchImageSign = async () => {
    setLoading(true);
    const pickCreds = await ctxSignAssetPick(headers);

    setLoading(false);
    if (isError(pickCreds)) {
      setError(pickCreds);
      setSecurity(null);
    } else {
      setSecurity(pickCreds);
      // Clear error
      if (error) {
        setError(null);
      }
    }
  };

  const resetPicker = async () => {
    showPicker && setShowPicker(false);
    if (!loading) {
      await fetchImageSign();
    }
  };

  useEffect(() => {
    !security && fetchImageSign();
  }, []);

  const pickerProps = {
    apikey: getConfig("FILEPICKER_KEY"),
    /**
     * ClientOptions: https://filestack.github.io/filestack-js/interfaces/clientoptions.html
     */
    clientOptions: {
      security
    },
    /**
     * PickerOptions: https://filestack.github.io/filestack-js/interfaces/pickeroptions.html
     */
    pickerOptions: {
      ...pickerOptions,
      onClose: resetPicker,
      onCancel: resetPicker,
      onUploadDone: async ({ filesUploaded }) => {
        onPickerDone(filesUploaded);

        resetPicker();
      }
    }
  };

  return {
    pickerProps,
    security,
    error,
    loading,
    setError,
    showPicker,
    setShowPicker,
    fetchImageSign
  };
};
