import React from "react";
import Button from "components/Form/fields/Button";
import { checkoutFlexPrices } from "utils/checkout/actions";
import ReflectFocus from "components/ReflectFocus";
import InfoNotice from "components/InfoNotice";
import { DEFAULT_ACTIONS_CLASSES } from "utils/constants/ui";
import isEmpty from "lodash/isEmpty";

/**
 * @param {Array} actions
 * @param {String} actions.uid
 * @param {String} actions.copy
 * @param {String} actions.uid
 * @param {String} actions.href
 * @param {String} actions.tag
 * @param {String} productIndex
 */
const FormerFlexCheckoutAction = ({
  action,
  disabled,
  focus,
  name,
  isLive,
  customClasses,
  isEmphasized,
  prices
}) => {
  const classes = { ...DEFAULT_ACTIONS_CLASSES, ...customClasses };

  const contextProps = {
    checkoutAction: checkoutFlexPrices,
    checkoutParams: {
      prices
    }
  };
  const ctxDisabled = disabled || isEmpty(prices);

  const { notice, props } = useCheckoutActionProps(
    {
      name,
      action,
      disabled: ctxDisabled,
      isLive
    },
    contextProps
  );

  const actionButton = <Button key="checkout" {...props} />;

  return (
    <ReflectFocus focus={focus}>
      {({ onClick, classes: focusClasses } = {}) => (
        <div
          onClick={onClick}
          className={`${classes.container} ${focusClasses}`}
        >
          <div className={classes.nodes}>{actionButton}</div>
          {notice && (
            <div className="pt3">
              <InfoNotice
                customClasses={{ copy: "f7 lh-copy pl2" }}
                copy={notice}
              />
            </div>
          )}
        </div>
      )}
    </ReflectFocus>
  );
};

export default FormerFlexCheckoutAction;
