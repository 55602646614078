import React from "react";
import classnames from "classnames";
import Pill from "components/Pill";
import { UI_THEME } from "utils/constants/ui";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { COLORS } from "utils/styles";

const TaxIdOption = ({ innerProps, isDisabled, data, getStyles, ...props }) => {
  const optionStyles = getStyles("option", props);

  return (
    <div
      {...innerProps}
      style={{
        ...innerProps.style,
        backgroundColor: optionStyles.backgroundColor
      }}
      className={classnames(
        "taxId--option flex flex-column flex-row-ns items-center-ns pa2 bg-white--hover bb hairline-1",
        {
          "cursor-not-allowed": isDisabled
        }
      )}
    >
      <div className="flex flex-row w-100">
        <div
          className="flex items-center justify-center"
          style={{ width: 32, height: 32 }}
        >
          <div className="f5 lh-title truncate fw5">
            {data.code && getUnicodeFlagIcon(data.code)}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-100">
          <div
            className={classnames("f5 f6-ns pl2 w-100", {
              "color-text--secondary": isDisabled
            })}
          >
            {data.label}
          </div>
          <div className="flex-shrink-0">
            <Pill theme={UI_THEME.SLIM} copy={data.codeId} />
          </div>
        </div>
      </div>
      <style jsx>{`
        .taxId--option:hover {
          cursor: pointer;
          background-color: ${COLORS.whitesmoke};
        }
        .taxId--option:focus {
          cursor: pointer;
          background-color: ${COLORS.whitesmoke};
        }
      `}</style>
    </div>
  );
};

export default TaxIdOption;
